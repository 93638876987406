<template>
    <div>
        <div class="tw-mt-10">
            <div class="container-max-1600">
                <div class="tw-pb-5 tw-text-center">
                    <div class="tw-text-3xl tw-font-bold tw-mb-2">
                        <h1>Collections</h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-max-1600">
            <div class="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-4 tw-gap-4">
                <div
                    v-for="collection in collections"
                    :key="collection.id"
                    class="">
                        <a 
                            v-bind="props" 
                            class="tw-text-center"
                            :href="'/collections/' + collection.slug">
                            <v-img 
                                class=""
                                v-if="collection.image"
                                aspect-ratio="1"
                                cover
                                :src="urlImage + collection.image">
                            </v-img>
                            <div class="tw-text-xl tw-font-semibold tw-tracking-wider tw-mb-2 mt-4">{{ collection.title }}</div>
                            <div class="tw-text-sm tw-mb-1" v-html="collection.description"></div>
                        </a>
                </div>
            </div>
        </div>

        <v-container>
            <v-row 
                v-if="loading"
                class="my-10">
                <v-col 
                    md="4"
                    cols="6"
                    v-for="(item, p) in 9"
                    :key="'skeleton' + p">
                    <v-skeleton-loader
                        class="mx-auto border"
                        type="image, article">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref, watch, onMounted } from 'vue'
    import api from '@/api'

    const collections = ref([])

    const urlImage = window.urlImage
    const loading = ref(false)

    getCollection()
    async function getCollection(){
        const resCollection = await api.get('collections/product')
        collections.value = resCollection.data.data
    }

    onMounted(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
</script>