<template>
    <div>
        <v-card variant="outlined" class="">
            <v-layout>
                <v-app-bar density="compact">
                    <v-app-bar-title>ADDRESS</v-app-bar-title>
                    <template v-slot:append>
                        <AddAddress />
                    </template>
                </v-app-bar>
                <v-main class="px-4 pb-2">
                    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                        <v-card 
                            variant="outlined"
                            v-for="address in user.addresses"
                            :key="address.id"
                            class="d-flex">
                            <div class="px-4 py-2 w-100">
                                <div 
                                    v-if="address.default"
                                    class="mb-2">
                                    <b>Default</b>
                                </div>
                                <div>
                                    <div v-if="address.name">
                                        {{ address.name }}
                                    </div>
                                    <div v-if="address.address1">
                                        {{ address.address1 }}
                                    </div>
                                    <div v-if="address.address2">
                                        {{ address.address2 }}
                                    </div>
                                    <div v-if="address.district">
                                        {{ address.district }}
                                    </div>
                                    <div v-if="address.city">
                                        {{ address.city }}
                                    </div>
                                    <div v-if="address.province">
                                        {{ address.province }}  {{ address.zip }}
                                    </div>
                                    <div v-if="address.country">
                                        {{ address.country }}
                                    </div>
                                    <!-- <div>
                                        <div v-if="address.lat">Lat: {{ address.lat }}</div>
                                        <div v-if="address.lon">Lon: {{ address.lon }}</div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="pl-4 py-2">
                                <EditAddress 
                                    :class="address.default ? 'pr-3':''"
                                    :address="address"/>
                                
                                <v-icon 
                                    v-if="!address.default"
                                    size="small"
                                    class="mt-2"
                                    color="red"
                                    @click="deleteAddress(address)">
                                    mdi-delete
                                </v-icon>
                                <v-icon 
                                    v-if="!address.default"
                                    size="small"
                                    class="mt-2"
                                    color="green"
                                    @click="updateDefaultAddress(address)">
                                    mdi-check
                                </v-icon>
                            </div>
                        </v-card>
                        
                    </div>
                </v-main>
            </v-layout>
        </v-card>
    </div>
</template>


<script setup>
    import { ref } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/store/user'
    import { useAlertStore } from "@/store/alert"
    import api from '@/api'
    import AddAddress from './AddAddress'
    import EditAddress from './EditAddress'

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const alertStore = useAlertStore()

    async function deleteAddress(data){
        if(window.confirm('Are you sure you want to delete this item?')){
            await api.post('user/delete-address', {
                id: data.id,
            })
            .then((resp) => {
                useUserStore().getUser()
                alertStore.action(true, 'success', 'Success')
            })
            .catch((err) => {               
                alertStore.action(true, 'error', err.response.statusText)
            })
        }
    }
    
    async function updateDefaultAddress(address){
        await api.post('user/update-default-address', {
            id: address.id,
        })
        .then((resp) => {
            useUserStore().getUser()
            alertStore.action(true, 'success', 'Success')
        })
        .catch((err) => {      
            // if(err.response.status == 422){
            //     msgValidate.value = err.response.data.errors
            // }

            alertStore.action(true, 'error', err.response.statusText)
        })
    }

</script>