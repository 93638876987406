<template>
    <div class="bg-grey-lighten-4 py-15">
        <v-container>
            <v-row class="justify-center">
                <v-col
                    md="5"
                    cols="12">
                    <v-card 
                        flat
                        rounded="0"
                        :loading="loading">
                        <div class="pa-10">
                            <h5 
                                class="z-subtitle text-primary"
                                v-if="msgSuccess">
                                {{ msgSuccess }}
                            </h5>
                            <h5 
                                class="z-subtitle text-red"
                                v-if="msgError">
                                {{ msgError }}
                            </h5>

                            <div class="text-center mt-7">
                                <router-link 
                                    to="/login"
                                    class="text-primary">
                                    Login
                                </router-link>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useRoute } from 'vue-router'

    document.title = "Verify"

    const route = useRoute()
    
    const loading = ref(false)

    const msgError = ref('')
    const msgSuccess = ref('')

    const id = ref(route.params.id)
    const token = ref(route.params.token)
    const expires = ref(route.query.expires)

    verifyEmail()
    async function verifyEmail(){
        msgError.value = ''
        loading.value = true
        await api.get('verify-email/' + id.value + '/' + token.value + '?expires=' + expires.value)
        .then((resp) => {
            loading.value = false
            msgSuccess.value = resp.data.message
        })
        .catch((err) => {
            loading.value = false
            if(err.response.status == 401){
                window.location = '/'
            } else if(err.response.status == 403){
                window.location = '/'
            } else {
                msgError.value = err.response.data.message
            }
        })
    }

</script>