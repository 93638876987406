<template>
    <div>
        <div
            v-for="promotion in promotions"
            :key="promotion.id">
            <div class="mb-10">
                <zCountdown 
                    class="tw-bg-red tw-text-white tw-text-5xl tw-w-96 tw-px-7 tw-py-3 tw-text-center tw-font-bold tw-mx-auto"
                    :data="{
                    start_date: promotion.start_date,
                    end_date: promotion.end_date,
                }" />
            </div>
            <div class="px-4">
                <div class="tw-relative">
                    <swiper
                        :navigation="{
                            prevEl: '.swiper-flash-button-prev',
                            nextEl: '.swiper-flash-button-next',
                        }"
                        :slidesPerView="slidesPerView"
                        :spaceBetween="30"
                        :modules="[Navigation]"
                        :pagination="{
                            clickable: true,
                        }">
                        <swiper-slide 
                            v-for="product in promotion.products"
                            :key="product.id">
                            <zProduct :data="product">
                            </zProduct>
                        </swiper-slide>
                    </swiper>
                    <div 
                        class="swiper-button-prev swiper-flash-button-prev !tw-left-[-23px]" 
                        slot="button-prev">
                    </div>
                    <div 
                        class="swiper-button-next swiper-flash-button-next !tw-right-[-23px]" 
                        slot="button-next">
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import zProduct from './zProduct'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation } from 'swiper/modules'
    import api from '../api'
    import zCountdown from './zCountdown'

    const display = useDisplay()

    const slidesPerView = ref(4)

    const { data } = defineProps(['data'])

    const loading = ref(false)
    const promotions = ref([])

    function setSlidesPerView() {
        if (display.xs.value) {
            return slidesPerView.value = 1
        }
        if (display.sm.value) {
            return slidesPerView.value = 2
        }
        return slidesPerView.value = 4
    }

    onMounted(() => {
        setSlidesPerView()
        window.addEventListener('resize', () => {
            setSlidesPerView()
        })
    })

    getPromotion()
    async function getPromotion(){
        const dataSection = data[0]
        loading.value = true
        await api.get('products/promotion?limit=' + dataSection.limit + '&promotion=' + dataSection.promotion)
            .then((resp) => {
                loading.value = false
                promotions.value = resp.data.data
            })
            .catch((err) => {
                loading.value = false
            })
    }
</script>