<template>
    <div>
        <div
            v-for="(content, c) in data"
            :key="'content' + c">

            <div class="px-4">
                <div class="tw-relative">
                    <swiper
                        :navigation="{
                            prevEl: '.swiper-post-button-prev',
                            nextEl: '.swiper-post-button-next',
                        }"
                        :slidesPerView="slidesPerView"
                        :spaceBetween="30"
                        :modules="[Navigation]"
                        :pagination="{
                            clickable: true,
                        }">
                        <swiper-slide 
                            v-for="post in content.contents"
                            :key="post.id">
                            <zPost :data="post">
                            </zPost>
                        </swiper-slide>
                    </swiper>
                    <div 
                        class="swiper-button-prev swiper-post-button-prev !tw-left-[-23px]" 
                        slot="button-prev">
                    </div>
                    <div 
                        class="swiper-button-next swiper-post-button-next !tw-right-[-23px]" 
                        slot="button-next">
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import zPost from './zPost.vue'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation } from 'swiper/modules'

    const display = useDisplay()

    const slidesPerView = ref(4)

    const { data } = defineProps(['data'])

    function setSlidesPerView() {
        if (display.xs.value) {
            return slidesPerView.value = 1
        }
        if (display.sm.value) {
            return slidesPerView.value = 2
        }
        return slidesPerView.value = 4
    }

    onMounted(() => {
        setSlidesPerView()
        window.addEventListener('resize', () => {
            setSlidesPerView()
        })
    })
</script>