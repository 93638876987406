<template>
    <div class="bg-grey-lighten-4 py-15">
        <v-container>
            <v-row class="justify-center">
                <v-col
                    md="5"
                    cols="12">
                    <v-card 
                        flat
                        rounded="0">
                        <div class="pa-10">
                            <h5 
                                class="z-subtitle text-primary">
                                Password Reset
                            </h5>

                            <div class="mt-7">
                                <v-text-field
                                    v-model="email"
                                    prepend-inner-icon="mdi-email"
                                    label="Email"
                                    variant="outlined"
                                    :error-messages="getValidateError('email')"
                                    :class="getValidateError('email') ? 'mb-3':''"
                                    disable>
                                </v-text-field>
                                <v-text-field
                                    v-model="password"
                                    prepend-inner-icon="mdi-lock"
                                    label="Password"
                                    variant="outlined"
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'"
                                    @click:append-inner="visible = !visible"
                                    :error-messages="getValidateError('password')"
                                    :class="getValidateError('password') ? 'mb-3':''">
                                </v-text-field>

                                <div 
                                    v-if="msgError"
                                    class="text-red mb-4">
                                    {{ msgError }}
                                </div>

                                <div 
                                    v-if="getValidateError1('email')"
                                    class="text-red mb-4">
                                    {{ getValidateError1('email') }}
                                </div>
                                
                                <div 
                                    v-if="msgSuccess"
                                    class="text-green mb-4">
                                    {{ msgSuccess }}
                                </div>

                                <v-btn 
                                    class="mt-5"
                                    size="x-large"
                                    block
                                    @click="passwordReset"
                                    :loading="loading">
                                    Password Reset
                                </v-btn>

                                <div class="text-center mt-7">
                                    <router-link 
                                        to="/login"
                                        class="text-primary">
                                        Login
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useRoute } from 'vue-router'

    document.title = "Password Reset"

    const route = useRoute()

    const visible = ref(false)

    const password = ref('')
    const token = ref(route.params.token)
    const email = ref(route.query.email)
    
    const loading = ref(false)

    const msgValidate = ref('')
    const msgValidate1 = ref('')
    const msgError = ref('')
    const msgSuccess = ref('')

    async function passwordReset(){
        loading.value = true
        msgValidate.value = ''
        msgError.value = ''
        msgSuccess.value = ''
        await api.post('password-reset', {
            token: token.value,
            email: email.value,
            password: password.value
        })
        .then((resp) => {
            loading.value = false
            msgSuccess.value = resp.data.message
        })
        .catch((err) => {
            loading.value = false
            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            }
            if(err.response.status == 400){
                msgValidate1.value = err.response.data.errors
            } 
            if(err.response.status == 429){
                msgError.value = 'Please try again in 30 minutes'
            }
            if(err.response.status == 500){
                msgError.value = 'Internal server error'
            } 
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }
    function getValidateError1(field) {
        if (msgValidate1.value && msgValidate1.value[field] && msgValidate1.value[field][0]) {
            return msgValidate1.value[field][0];
        } else {
            return '';
        }
    }

</script>