<template>
    <v-hover v-slot="{ isHovering, props }">
        <v-card
            :loading="false"
            :hover="true"
            :ripple="false"
            flat
            rounded="0"
            class="no-hover-overlay bg-transparent"
            v-bind="props">
            <div 
                v-if="special_price"
                class="label-discount tw-bg-yellow tw-leading-3 tw-flex tw-flex-col tw-size-12 tw-justify-center tw-items-center tw-absolute tw- tw-top-0 tw-right-0 tw-z-10 tw-font-bold">
                <div class="tw-text-lg tw-flex">
                    <span>{{ price_percent }}</span><span class="tw-text-sm">%</span>
                </div>
                <div class="tw-text-[12px] -tw-mt-1">OFF</div>
            </div>
            <v-img
                v-if="image"
                :src="image"
                aspect-ratio="9/16"
                cover
                :alt="title"
                class="tw-relative">
                <a :href="'/products/' + slug">
                    <div 
                        v-if="isHovering"
                        class="tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-absolute tw-z-3 tw-h-full tw-left-0 tw-right-0 tw-bg-blue-100/60">
                    </div>
                </a>
                <v-btn
                    v-if="isHovering"
                    variant="flat"
                    color="blue-grey-darken-3"
                    size="x-large"
                    @click="addToCart"
                    rounded
                    class="btn-add2cart-product-card !tw-absolute !tw-top-1/2 !tw-left-1/2 !-tw-translate-x-1/2 !-tw-translate-y-1/2">
                    <v-icon class="tw-mr-2">mdi-cart-plus</v-icon>
                    <span class="tw-font-normal">Add to Cart</span>
                </v-btn>
            </v-img>
            <a :href="'/products/' + slug">
                <v-card-item 
                    class="text-center">
                    <v-card-title 
                        class="py-1 !tw-text-wrap"
                        v-if="title">
                        <div class="tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-text-sm ">
                            {{ title }}
                        </div>
                    </v-card-title>
                    <v-card-text 
                        class="px-0 pt-1"
                        v-if="price">
                        <div
                            v-if="special_price" 
                            class="tw-flex tw-justify-center tw-flex-col md:tw-flex-row tw-items-center tw-text-sm">
                            <div class="text-red tw-flex tw-mr-2">
                                <span class="tw-text-xs">Rp</span> <span class="text-red tw-font-heading tw-font-bold">{{ formatCurrency(special_price) }}</span>
                            </div>
                            <div class="tw-flex">
                                <span class="tw-text-xs">Rp</span> <span class="tw-font-heading tw-font-bold tw-line-through">{{ formatCurrency(price) }}</span>
                            </div>
                        </div>
                        <div
                            v-else 
                            class="tw-flex tw-justify-center tw-text-sm">
                            <div class="tw-flex">
                                <span class="tw-text-xs">Rp</span> <span class="tw-font-heading tw-font-bold">{{ formatCurrency(price) }}</span>
                            </div>
                        </div>
                    </v-card-text>
                </v-card-item>
            </a>
        </v-card>
    </v-hover>
</template>

<script setup>
    import { ref } from 'vue'
    import { useCartStore } from '@/store/cart'
    
    const { data } = defineProps(['data'])

    const id = ref('')
    const variantId = ref('')
    const variantTitle = ref('')
    const title = ref('')
    const slug = ref('')
    const image = ref('')
    const description = ref('')
    const price = ref('')
    const special_price = ref('')
    const price_percent = ref('')
    const quantity = ref('')
    const collection = ref('')

    getProduct()
    function getProduct(){
        id.value = data.id
        title.value = data.title
        slug.value = data.slug
        description.value = data.description
        collection.value = data.collection

        let dataVariants = []
        if(data.variants){
            if(data.variants.length > 0){
                // dataVariants = data.variants.filter(variant => variant.quantity > 0)
                // dataVariants = data.variants.sort((a, b) => b.quantity - a.quantity)
                dataVariants = data.variants.sort((a, b) => {
                    if (a.quantity === 0 && b.quantity !== 0) {
                        return 1;
                    }
                    if (a.quantity !== 0 && b.quantity === 0) {
                        return -1;
                    }
                    return b.quantity - a.quantity;
                })
            }
        }
        if(dataVariants.length > 0){
            const variant = dataVariants[0]
            let dataImage = ''
            // if(variant.image){
            //     dataImage = variant.image.image
            // } else if (data.gallery) {
            //     if(data.gallery.length > 0) {
            //         dataImage = data.gallery[0].image
            //     } else {
            //         dataImage = ''
            //     }
            // } else {
            //     dataImage = ''
            // }

            if (data.gallery) {
                if(data.gallery.length > 0) {
                    dataImage = data.gallery[0].image
                } else {
                    dataImage = ''
                }
            }

            variantId.value = variant.id
            image.value = dataImage
            price.value = variant.price
            variantTitle.value = variant.title
            special_price.value = variant.special_price
            price_percent.value = variant.price_percent
            quantity.value = variant.quantity   
        }
    }

    function addToCart(){
        useCartStore().addToCart({
            productId: variantId.value,
            qty: 1,
            image: image.value,
            title: title.value,
            variant: variantTitle.value
        })
    }
</script>
