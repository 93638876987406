<template>
    <div class="tw-flex tw-w-full tw-items-center lg:tw-px-[7em] xl:tw-px-[15em] 2xl:tw-px-[20em]">
        <div class="tw-w-full">
            <v-text-field
                placeholder="Enter your email address"
                variant="solo"
                v-model="email"
                hide-details
                type="email">
            </v-text-field>
            <small
                v-if="getValidateError('email')" 
                class="tw-text-red">
                {{ getValidateError('email') }}
            </small>
        </div>
        <v-spacer/>
        <div>
            <v-btn
                variant="outlined"
                class="text-secondary ml-7"
                size="x-large"
                @click="send"
                :loading="loading">
                SEND ME
            </v-btn>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useAlertStore } from "@/store/alert"

    const alertStore = useAlertStore()
    const { data } = defineProps(['data'])

    const email = ref('')
    const loading = ref(false)
    const msgValidate = ref('')

    async function send(){
        msgValidate.value = ''
        loading.value = true

        await api.post(window.baseUrl + 'subscribes/subscribe', {
            email: email.value
        })
        .then((resp) => {
            loading.value = false
            alertStore.action(true, "success", "Success")
        })
        .catch((err) => {
            loading.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 409){
                alertStore.action(true, "error", err.response.data.message)
            } else if(err.response.status == 423){
                alertStore.action(true, "error", "Error")
            } else {
                alertStore.action(true, "error", "Internal server error")
            }
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>