export function useRecaptcha(siteKey) {
    let recaptchaWidgetId = null;

    function loadRecaptcha() {
        return new Promise((resolve, reject) => {
            if (window.grecaptcha && recaptchaWidgetId === null) {
                recaptchaWidgetId = window.grecaptcha.render('recaptchaContainer', {
                    sitekey: siteKey,
                    callback: onRecaptchaVerify,
                });
                resolve();
            } else {
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoad';
                script.async = true;
                script.onload = () => {
                    window.onRecaptchaLoad = function () {
                        recaptchaWidgetId = window.grecaptcha.render('recaptchaContainer', {
                            sitekey: siteKey,
                            callback: onRecaptchaVerify,
                        });
                        resolve();
                    };
                };
                script.onerror = reject;
                document.head.appendChild(script);
            }
        });
    }

    function onRecaptchaVerify(responseToken) {
        return responseToken;
    }

    return {
        loadRecaptcha,
    };
}