import { defineStore } from 'pinia'
import api from '../api'
import { useAlertStore } from './alert'

export const useCartStore = defineStore('cart', {
    state: () => ({
        carts: [],
        message: '',
        active: false,
        dialog: false,
        messageError: '',
        loading: false,
        data: {},
        productRecomendation: {},
        loadingProductRecomendation: false
    }),

    actions: {
        async getCart(){
            await api.get('carts')
                .then((resp) => {
                    this.carts = resp.data.data
                })
                .catch((err) => {
                    //
                })
        },
        async addToCart(data){
            await api.post('carts', {
                id: data.productId,
                quantity: data.qty,
                idb: localStorage.getItem('idb')
            })
                .then((resp) => {
                    if (resp.data.status == false) {
                        useAlertStore().action(true, 'error', resp.data.data)
                    } else {
                        this.dialog = true

                        this.data = data

                        this.getCart()
                    }
                })
                .catch((err) => {
                    // this.messageError = err.response.data.message
                })
        },
        
        async update(data){
            await api.post('carts/update', data)
                .then((resp) => {
                    //
                    this.getCart()
                })
                .catch((err) => {
                    // this.messageError = err.response.data.message
                })
        },

        async delete(id){
            await api.delete('carts/' + id.id)
                .then((resp) => {
                    //
                    this.getCart()
                })
                .catch((err) => {
                    // this.messageError = err.response.data.message
                })
        },

        async getProductRecomendation(productId){
            this.loadingProductRecomendation = true
            await api.get('product-relations/cart/' + productId)
                .then((resp) => {
                    this.productRecomendation = resp.data.data

                    this.loadingProductRecomendation = false
                })
                .catch((err) => {
                    this.loadingProductRecomendation = false
                })
        },

        openCart(){
            this.active = true
            this.dialog = false
        },
        closeCart(){
            this.active = false
        },

        openDialogCart(){
            this.active = false
            this.dialog = true
        },
        closeDialogCart(){
            this.dialog = false
        }
    },
})