<script setup>
    import { ref, defineProps, watch } from 'vue'
    import api from '@/api'
    import { useAlertStore } from '@/store/alert'
    import { useDistrictStore } from '@/store/district'
    import { useUserStore } from '@/store/user'
    import MapPicker from '@/components/MapPicker.vue'

    const alertStore = useAlertStore()
    const districtStore = useDistrictStore()
    districtStore.getProvince('')

    const { address } = defineProps(['address'])
    
    const dialog = ref(false)
    const loading = ref(false)

    const msgValidate = ref('')

    const selectedCoordinates = ref({ lat: address.lat, lng: address.lon });

    watch(() => address.province, (newVal) => {
        districtStore.getCity(newVal)
    })
    watch(() => address.city, (newVal) => {
        districtStore.getDistrict(newVal)
    })

    watch(selectedCoordinates, (val) => {
        console.log(val)
        address.lat = val.lat
        address.lon = val.lng
    })

    async function save(){
        msgValidate.value = ''
        loading.value = true

        await api.post('user/update-address', {
            id: address.id,
            name: address.name,
            country: address.country,
            address1: address.address1,
            address2: address.address2,
            district: address.district,
            city: address.city,
            province: address.province,
            zip: address.zip,
            phone: address.phone,
            lat: address.lat,
            lon: address.lon,
        })
        .then((resp) => {
            useUserStore().getUser()
            alertStore.action(true, 'success', 'Success')
            dialog.value = false
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            }

            alertStore.action(true, 'error', err.response.statusText)
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }
</script>

<template>
    <div>
        <v-icon 
            @click="dialog = true"
            size="small">
            mdi-pencil
        </v-icon>
        
        <v-dialog
            v-model="dialog"
            persistent
            width="500">
            <v-card>
                <v-form ref="form">
                    <v-layout>
                        <v-app-bar
                            class="mt-2"
                            density="compact">
                            <v-app-bar-title>Edit address</v-app-bar-title>
                            <template v-slot:append>
                                <v-btn 
                                    color="red"
                                    icon="mdi-close"
                                    @click="dialog = false">
                                </v-btn>
                            </template>
                        </v-app-bar>

                        <v-main class="px-4 pb-2 mt-4">
                            <v-text-field 
                                density="compact"
                                variant="outlined"
                                label="Name"
                                placeholder="Name"
                                v-model="address.name"
                                :class="getValidateError('name') ? 'mb-2':''"
                                :error-messages="getValidateError('name')">
                            </v-text-field>
                            <v-select
                                density="compact"
                                variant="outlined"
                                label="Country"
                                placeholder="Country"
                                v-model="address.country"
                                :items="['Indonesia']"
                                :class="getValidateError('country') ? 'mb-2':''"
                                :error-messages="getValidateError('country')">
                            </v-select>
                            <v-row>
                                <v-col 
                                    cols="12"
                                    md="7">
                                    <v-select
                                        density="compact"
                                        variant="outlined"
                                        label="Province"
                                        placeholder="Province"
                                        v-model="address.province"
                                        :items="districtStore.province"
                                        item-value="province"
                                        item-title="province"
                                        :class="getValidateError('province') ? 'mb-2':''"
                                        :error-messages="getValidateError('province')">
                                    </v-select>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    md="5">
                                    <v-text-field 
                                        density="compact"
                                        variant="outlined"
                                        label="Postal code"
                                        placeholder="Postal code"
                                        v-model="address.zip"
                                        :class="getValidateError('zip') ? 'mb-2':''"
                                        :error-messages="getValidateError('zip')">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-select
                                density="compact"
                                variant="outlined"
                                label="City"
                                placeholder="City"
                                v-model="address.city"
                                :items="districtStore.city"
                                item-value="city"
                                item-title="city"
                                :class="getValidateError('city') ? 'mb-2':''"
                                :error-messages="getValidateError('city')">
                            </v-select>
                            <v-select
                                density="compact"
                                variant="outlined"
                                label="District"
                                placeholder="District"
                                v-model="address.district"
                                :items="districtStore.district"
                                item-value="district"
                                item-title="district"
                                :class="getValidateError('district') ? 'mb-2':''"
                                :error-messages="getValidateError('district')">
                            </v-select>

                            <v-text-field 
                                density="compact"
                                variant="outlined"
                                label="Address"
                                placeholder="Address"
                                v-model="address.address1"
                                :class="getValidateError('address1') ? 'mb-2':''"
                                :error-messages="getValidateError('address1')">
                            </v-text-field>
                            <!-- <v-text-field 
                                density="compact"
                                variant="outlined"
                                label="Apartment, suite, etc."
                                placeholder="Apartment, suite, etc."
                                v-model="address.address2"
                                :class="getValidateError('address2') ? 'mb-2':''"
                                :error-messages="getValidateError('address2')">
                            </v-text-field> -->

                            <!-- <v-row>
                                <v-col 
                                    cols="12"
                                    md="6">
                                    <v-text-field 
                                        density="compact"
                                        variant="outlined"
                                        label="Latitude"
                                        placeholder="Latitude"
                                        v-model="address.lat">
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    md="6">
                                    <v-text-field 
                                        density="compact"
                                        variant="outlined"
                                        label="Longitude"
                                        placeholder="Longitude"
                                        v-model="address.lon">
                                    </v-text-field>
                                </v-col>
                            </v-row> -->
                            
                            <v-text-field 
                                density="compact"
                                variant="outlined"
                                label="Phone"
                                placeholder="Phone"
                                v-model="address.phone"
                                :class="getValidateError('phone') ? 'mb-2':''"
                                :error-messages="getValidateError('phone')">
                            </v-text-field>

                            <MapPicker v-model="selectedCoordinates" />
                        </v-main>
                    </v-layout>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="loading"
                            @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>