<template>
    <div v-if="props.data">
        <v-dialog
            v-model="props.show"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition">
            <v-card color="black">
                <v-app-bar
                    class="v-toolbal--default toolbar-show-image" 
                    style="top: 0" 
                    color="black">
                    <v-icon
                        @click="closeDialogShowGallery">
                        mdi-chevron-left
                    </v-icon>
                    <v-spacer></v-spacer>
                        {{ onboarding + 1 }} / {{ props.data.length }}
                    <v-spacer></v-spacer>
                    <v-icon
                        @click="closeDialogShowGallery">
                        mdi-close
                    </v-icon>
                </v-app-bar>
                <v-window
                    v-model="onboarding"
                    show-arrows="hover">
                    <v-window-item
                        v-for="(image, i) in props.data"
                        :key="'image' + i">
                        <v-img
                            v-if="image.type == 'image'"
                            :src="urlImage + image.file"
                            height="93vh">
                        </v-img>
                        <iframe 
                            v-if="image.type == 'video' && getYoutubeId(image.file)"
                            style="height: 93vh; width: 100%"
                            :src="'https://www.youtube.com/embed/' + getYoutubeId(image.file) + '?controls=0'"
                            frameborder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </v-window-item>
                </v-window>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
    import { ref } from 'vue'

    const props = defineProps(['data', 'show', 'index'])

    const urlImage = window.urlImage

    const onboarding = ref(props.index)

    const emit = defineEmits(['closeShow'])

    function closeDialogShowGallery(){
        emit('closeShow')
    }   

    function getYoutubeId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            return null;
        }
    }
</script>