<template>
    <div class="pt-md-5 pt-lg-10 pb-md-5 pb-lg-10">
        <div
            v-if="!loading" 
            class="container-max-1600">
            <div  
                class="title text-center mb-5">
                <h1 class="tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-text-3xl tw-my-3">
                    ORDER HISTORY
                </h1>
            </div>

            <div v-if="isOrder">
                <v-card 
                    variant="text" 
                    class="mt-5">
                    <v-tabs
                        v-model="tab"
                        align-tabs="center">
                        <v-tab :value="0">Unpaid</v-tab>
                        <v-tab :value="1">On Going</v-tab>
                        <v-tab :value="2">History</v-tab>
                    </v-tabs>

                    <v-tabs-window v-model="tab">
                        <v-tabs-window-item>
                            <v-container fluid>
                                <Unpaid />
                            </v-container>
                        </v-tabs-window-item>
                        <v-tabs-window-item>
                            <v-container fluid>
                                <Ongoing/>
                            </v-container>
                        </v-tabs-window-item>
                        <v-tabs-window-item>
                            <v-container fluid>
                                <History/>
                            </v-container>
                        </v-tabs-window-item>
                    </v-tabs-window>
                </v-card>
            </div>
            <div 
                v-if="!isOrder"
                class="text-center">
                <div>
                    There are not item in your order history
                </div>
                <v-btn
                    class="text-secondary mt-5" 
                    href="/">
                    Shop Now
                </v-btn>
            </div>
        </div>

        <v-container
            v-if="loading"
            class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate>
            </v-progress-circular>
        </v-container>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import api from '@/api'
    import Unpaid from './Unpaid.vue'
    import Ongoing from './Ongoing.vue'
    import History from './History.vue'

    const tab = ref(0)

    const isOrder = ref(false)
    const loading = ref(false)

    getOrders()
    async function getOrders(){
        loading.value = true
        await api.get('user/orders?limit=1')
        .then((resp) => {
            if(resp.data.total > 0){
                isOrder.value = true
            } else {
                isOrder.value = false
            }
            loading.value = false
        })
        .catch((err) => {
            isOrder.value = false
            loading.value = false
        })
    }

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>