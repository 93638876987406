import { createWebHistory, createRouter } from "vue-router"
import { getToken } from '@/auth'

import Index from "../pages/index"
import Show from "../pages/show"
import Search from "../pages/search"
// import Blog from "../pages/blog"
import BlogShow from "../pages/blogShow"
// import Product from "../pages/products"
import ProductShow from "../pages/productShow"
// import Contact from "../pages/contact"

import Login from "../pages/auth/Login"
import Register from "../pages/auth/Register"
import Verify from "../pages/auth/Verify"
import ForgotPassword from "../pages/auth/ForgotPassword"
import PasswordReset from "../pages/auth/PasswordReset"

import NotFound from "../pages/404"

import Collection from "../pages/collection/Index"
import ShowCollection from "../pages/collection/Show"
import Checkout from "../pages/checkout/Index"

import Account from "../pages/user/account/Index"
import UserOrder from "../pages/user/order/Index"
import UserOrderShow from "../pages/user/order/Show"
import UserOrderInvoice from "../pages/user/order/Invoice"

import Review from "../pages/user/order/Review"

import Wishlist from "../pages/user/wishlist/Index"


const routes = [
	{
		path: "/",
		name: "Home",
		component: Index,
	},

	{
		path: "/search",
		name: "Search",
		component: Search,
	},

	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/verify-email/:id/:token",
		name: "Verify",
		component: Verify,
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: ForgotPassword,
	},
	{
		path: "/password-reset/:token",
		name: "PasswordReset",
		component: PasswordReset,
	},
	
	{
		path: "/checkout",
		name: "Checkout",
		component: Checkout,
	},


	{
		path: "/user/account",
		name: "Account",
		component: Account,
		meta: { requiresAuth: true },
	},
	{
		path: "/user/wishlists",
		name: "Wishlist",
		component: Wishlist,
		meta: { requiresAuth: true },
	},
	{
		path: "/user/order",
		name: "UserOrder",
		component: UserOrder,
		meta: { requiresAuth: true },
	},
	{
		path: "/user/order/review/:id",
		name: "Review",
		component: Review,
		meta: { requiresAuth: true },
	},
	{
		path: "/user/order/:id",
		name: "UserOrderShow",
		component: UserOrderShow,
		meta: { requiresAuth: true },
	},
	{
		path: "/user/order/invoice/:id",
		name: "UserOrderInvoice",
		component: UserOrderInvoice,
		meta: { requiresAuth: true },
	},


	{
		path: "/news/:slug",
		name: "blogShow",
		component: BlogShow,
	},
	// {
	// 	path: "/products",
	// 	name: "Product",
	// 	component: Product,
	// },
	{
		path: "/products/:slug",
		name: "productShow",
		component: ProductShow,
	},
	{
		path: "/collections/",
		name: "Collection",
		component: Collection,
	},
	{
		path: "/collections/:slug",
		name: "ShowCollection",
		component: ShowCollection,
	},
	{
		path: "/:slug",
		name: "Show",
		component: Show,
	},
	{
		path: "/404",
		name: "NotFound",
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

function isLoggedIn() {
	return getToken('auth');
}

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn()) {
		// Redirect to login page if the route requires auth and the user isn't logged in.
		next({ path: '/login' });
	} else {
		next();
	}
});

export default router;