<template>
    <div>
        <v-row
            class="justify-center">
            <v-col 
                v-for="(component, c) in data"
                :key="'components' + c"
                cols="12" 
                :md="getColumn(data.length)">
                <v-card
                    :hover="true"
                    :ripple="false" 
                    class="no-hover-overlay bg-transparent"
                    flat
                    rounded="0"
                    :class="component.class_css"
                    :href="component.link">
                    <div 
                        v-for="(content, c) in component.contents"
                        :key="'contentc' + c">
                        <div 
                            v-if="content.type == 'image'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'image' + c">
                                <div v-if="item.value">
                                    <img
                                        :src="urlImage + item.value"
                                        :alt="item.heading"
                                        class="w-100">
                                    </img>
                                </div>
                            </div>
                        </div>
                        <v-card-title
                            class="mt-2" 
                            v-if="content.type == 'singleLineText'">
                            <div
                                class="tw-font-heading tw-font-bold tw-text-xl tw-text-wrap"   
                                v-for="(item, c) in content.items"
                                :key="'itemmsingleLineText' + c"
                                v-html="item.value">
                            </div>
                        </v-card-title>
                        <v-card-subtitle
                            v-if="content.type == 'multiLineText'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmmultiLineText' + c">
                                <div
                                    class="tw-font-heading tw-font-bold" 
                                    v-html="item.value">
                                </div>
                            </div>
                        </v-card-subtitle>
                        <v-card-text 
                            class="py-1"
                            v-if="content.type == 'richText'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmrichText' + c">
                                <div
                                    class="tw-font-body" 
                                    v-html="item.value">
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script setup>
    const { data } = defineProps(['data'])
    const urlImage = window.urlImage

    function getColumn(count){
        if(count == 1){
            return 3
        } else if(count == 2){
            return 3
        } else if(count == 3){
            return 4
        } else {
            return 3
        }
    }
</script>

<style lang="scss" scoped>
    a.v-card:hover .v-card-title{
        color: #FF0000;
        text-decoration: underline;
    }
</style>