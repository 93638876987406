<template>
    <div class="px-4">
        <div class="tw-text-3xl tw-my-9 tw-text-center">
            Users Images & Video
        </div>
        
        <div class="tw-relative">
            <swiper
                :slidesPerView="slidesPerView"
                :spaceBetween="0"
                :modules="[Navigation]"
                :pagination="{
                    clickable: true,
                }"
                :navigation="{
                    prevEl: '.swiper-file-review-button-prev',
                    nextEl: '.swiper-file-review-button-next',
                }">
                <swiper-slide 
                    v-for="(item, i) in data"
                    :key="'item' + i">
                    <ShowReview 
                        :data="item"
                        />
                </swiper-slide>
            </swiper>
            <div 
                class="swiper-button-prev swiper-file-review-button-prev !tw-left-[-23px]" 
                slot="button-prev">
            </div>
            <div 
                class="swiper-button-next swiper-file-review-button-next !tw-right-[-23px]" 
                slot="button-next">
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation } from 'swiper/modules'
    import ShowReview from '@/components/review/ShowReview'

    const display = useDisplay()
    const slidesPerView = ref(7)

    const { data } = defineProps(['data'])

    function setSlidesPerView() {
        if (display.xs.value) {
            return slidesPerView.value = 2
        }
        if (display.sm.value) {
            return slidesPerView.value = 5
        }
        return slidesPerView.value = 7
    }

    onMounted(() => {
        setSlidesPerView()
        window.addEventListener('resize', () => {
            setSlidesPerView()
        })
    })
</script>