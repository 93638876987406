<template>
    <div class="px-4">
        <div class="tw-relative">
            <swiper
                :navigation="{
                    prevEl: '.swiper-product-button-prev',
                    nextEl: '.swiper-product-button-next',
                }"
                :slidesPerView="slidesPerView"
                :spaceBetween="15"
                :modules="[Navigation]"
                :pagination="{
                    clickable: true,
                }">
                <swiper-slide 
                    v-for="product in data"
                    :key="product.id">
                    <zProductSm2 :data="product">
                    </zProductSm2>
                </swiper-slide>
            </swiper>
            <div 
                class="swiper-button-prev swiper-product-button-prev !tw-left-[-23px]" 
                slot="button-prev">
            </div>
            <div 
                class="swiper-button-next swiper-product-button-next !tw-right-[-23px]" 
                slot="button-next">
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import zProductSm2 from './zProductSm2.vue'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation } from 'swiper/modules'

    const display = useDisplay()

    const slidesPerView = ref(4)

    const { data } = defineProps(['data'])

    function setSlidesPerView() {
        if (display.xs.value) {
            return slidesPerView.value = 1
        }
        if (display.sm.value) {
            return slidesPerView.value = 3
        }
        return slidesPerView.value = 4
    }

    onMounted(() => {
        setSlidesPerView()
        window.addEventListener('resize', () => {
            setSlidesPerView()
        })
    })
</script>