<template>
    <div 
        v-for="(item, i) in data" 
        :key="'sidebar' + i"
        class="tw-px-4 tw-py-1">
        <div v-if="item.children.length > 0">
            <div 
                class="tw-flex tw-justify-between tw-items-center tw-bg-gray-100 tw-font-semibold">
                <div>
                    <a 
                        :href="item.url" 
                        class="">
                        {{ item.name }}
                    </a>
                </div>
                <div>
                    <v-icon @click="toggle(i)">
                        mdi-{{ isActive(i) ? 'chevron-up' : 'chevron-down' }}
                    </v-icon>
                </div>
            </div>
            <div 
                v-show="isActive(i)" 
                class="tw-pb-1 tw-bg-white">
                <template
                    v-for="(child, c) in item.children"
                    :key="'childm' + c">
                    <div>
                        <a 
                            :href="child.url"
                            class="tw-block">
                            {{ child.name }}
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <div v-else>
            <div 
                class="tw-cursor-pointer tw-bg-gray-100 tw-font-semibold">
                <div>
                    <a 
                        :href="item.url" 
                        class="tw-block">
                        {{ item.name }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    const { data } = defineProps(['data'])

    const activeIndex = ref(null)

    const toggle = (index) => {
        activeIndex.value = activeIndex.value === index ? null : index
    }

    const isActive = (index) => {
        return activeIndex.value === index
    }
</script>