/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

import 'leaflet/dist/leaflet.css';

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

app.config.globalProperties.formatCurrency = function (value) {
    return Number(value).toLocaleString('id-ID', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
};

registerPlugins(app)

app.mount('#app')
