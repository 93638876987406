<template>
    <div>
        <v-img
            v-if="image"
            :src="image"
            aspect-ratio="9/16"
            cover
            :alt="title"
            class="tw-relative">
        </v-img>

        <div 
            class="pt-2 pb-1 !tw-text-wrap"
            v-if="title">
            <div class="tw-font-heading tw-font-bold tw-text-sm">
                {{ title }}
            </div>
        </div>

        <div 
            class="px-0 pt-1"
            v-if="price">
            <div
                v-if="special_price" 
                class="tw-text-sm">
                <div class="text-red tw-flex tw-mr-2">
                    <span class="tw-text-xs">Rp</span> <span class="tw-font-heading tw-font-bold">{{ formatCurrency(special_price) }}</span>
                </div>
                <div class="tw-flex">
                    <span class="tw-text-xs">Rp</span> <span class="tw-font-heading tw-font-bold tw-line-through">{{ formatCurrency(price) }}</span>
                </div>
            </div>
            <div
                v-else 
                class="tw-flex tw-text-sm">
                <div class="tw-flex">
                    <span class="tw-text-xs">Rp</span> <span class="tw-font-heading tw-font-bold">{{ formatCurrency(price) }}</span>
                </div>
            </div>
        </div>

        <v-btn
            variant="flat"
            @click="addToCart"
            class="text-secondary font-weight-bold mt-3">
            <v-icon class="tw-mr-2">mdi-plus</v-icon>
            <span class="tw-font-normal">Add to Cart</span>
        </v-btn>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useCartStore } from '@/store/cart'
    
    const { data } = defineProps(['data'])

    const id = ref('')
    const variantId = ref('')
    const variantTitle = ref('')
    const title = ref('')
    const slug = ref('')
    const image = ref('')
    const description = ref('')
    const price = ref('')
    const special_price = ref('')
    const price_percent = ref('')
    const quantity = ref('')
    const collection = ref('')

    getProduct()
    function getProduct(){
        id.value = data.id
        title.value = data.title
        slug.value = data.slug
        description.value = data.description
        collection.value = data.collection

        let dataVariants = []
        if(data.variants){
            if(data.variants.length > 0){
                // dataVariants = data.variants.filter(variant => variant.quantity > 0)
                // dataVariants = data.variants.sort((a, b) => b.quantity - a.quantity)
                dataVariants = data.variants.sort((a, b) => {
                    if (a.quantity === 0 && b.quantity !== 0) {
                        return 1;
                    }
                    if (a.quantity !== 0 && b.quantity === 0) {
                        return -1;
                    }
                    return b.quantity - a.quantity;
                })
            }
        }
        if(dataVariants.length > 0){
            const variant = dataVariants[0]
            let dataImage = ''
            // if(variant.image){
            //     dataImage = variant.image.image
            // } else if (data.gallery) {
            //     if(data.gallery.length > 0) {
            //         dataImage = data.gallery[0].image
            //     } else {
            //         dataImage = ''
            //     }
            // } else {
            //     dataImage = ''
            // }

            if (data.gallery) {
                if(data.gallery.length > 0) {
                    dataImage = data.gallery[0].image
                } else {
                    dataImage = ''
                }
            }

            variantId.value = variant.id
            image.value = dataImage
            price.value = variant.price
            variantTitle.value = variant.title
            special_price.value = variant.special_price
            price_percent.value = variant.price_percent
            quantity.value = variant.quantity   
        }
    }

    function addToCart(){
        useCartStore().addToCart({
            productId: variantId.value,
            qty: 1,
            image: image.value,
            title: title.value,
            variant: variantTitle.value
        })
    }
</script>
