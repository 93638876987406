<template>
    <div v-if="!loading">
        <v-dialog
            v-if="config.popup.status"
            v-model="dialog"
            persistent
            width="700">
            <v-card>
                <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-h-[510px]">
                    <div class="tw-hidden sm:tw-block">
                        <img 
                            v-if="msgSuccess"
                            :src="msgSuccess.image"
                            alt="image popup"
                            class="tw-object-cover tw-h-full tw-w-full">
                        <img 
                            v-else
                            :src="urlImage + config.popup.image"
                            alt="image popup"
                            class="tw-object-cover tw-h-full tw-w-full">
                    </div>
                    <div class="tw-py-4 tw-px-5">
                        <div class="tw-h-full">
                            <div class="d-flex mb-2">
                                <v-spacer/>
                                <div>
                                    <v-icon @click="close">mdi-close</v-icon>
                                </div>
                            </div>

                            <div class="tw-h-[445px]">
                                <div 
                                    v-if="!config.popup.newsletter"
                                    class="tw-flex tw-flex-col tw-justify-center tw-h-full">
                                    <div class="tw-font-heading tw-font-bold tw-text-2xl tw-mb-2 tw-uppercase">
                                        {{ config.popup.title }}
                                    </div>
                                    <div
                                        v-html="config.popup.text" 
                                        class="tw-font-body">
                                    </div>
                                </div>
                                <div 
                                    v-else
                                    class="tw-h-full">
                                    <div
                                        v-if="msgSuccess"
                                        class="tw-flex tw-flex-col tw-justify-between tw-h-full">
                                        <div>
                                            <div class="tw-font-heading tw-font-bold tw-text-2xl tw-mb-2 tw-uppercase">
                                                {{ msgSuccess.title }}
                                            </div>
                                            <div
                                                v-html="msgSuccess.text" 
                                                class="tw-font-heading tw-font-bold tw-text-xl tw-uppercase">
                                            </div>   
                                        </div>
                                        <div>
                                            <div class="tw-border tw-border-grey-800 tw-py-2 tw-px-3">
                                                {{ msgSuccess.coupon }}
                                            </div>
                                            <div class="tw-text-center tw-mt-7">
                                                <v-btn
                                                    @click="copyContent(msgSuccess.coupon)"
                                                    class="text-secondary">
                                                    COPY
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="tw-font-body tw-text-xs">
                                            {{ msgSuccess.note }}
                                        </div>
                                    </div>
                                    <div 
                                        v-else
                                        class="tw-flex tw-flex-col tw-justify-between tw-h-full">
                                        <div class="tw-font-heading tw-font-bold tw-text-xl tw-mb-4">
                                            {{ config.popup.title }}
                                        </div>
                                        <div>
                                            <div>
                                                <v-text-field
                                                    placeholder="Email address"
                                                    label="Email address"
                                                    v-model="email"
                                                    type="email"
                                                    density="compact"
                                                    variant="outlined"
                                                    :class="getValidateError('email') ? 'mb-2':''"
                                                    :error-messages="getValidateError('email')">
                                                </v-text-field>
                                                <v-text-field
                                                    placeholder="Phone number"
                                                    label="Phone number"
                                                    v-model="phone"
                                                    type="phone"
                                                    density="compact"
                                                    variant="outlined"
                                                    :class="getValidateError('phone') ? 'mb-2':''"
                                                    :error-messages="getValidateError('phone')">
                                                </v-text-field>
                                            </div>
                                            <div>
                                                I agree to receive information about
                                                news, sales, products from IGA Tech Lifestyle
                                            </div>
                                            <v-checkbox
                                                density="compact"
                                                label="Email"
                                                v-model="acceptedEmail"
                                                :hide-details="getValidateError('accepted_email') ? false : true"
                                                :error-messages="getValidateError('accepted_email')"
                                                :class="getValidateError('accepted_email') ? 'mb-1':'mb-n2'">
                                            </v-checkbox>
                                            <v-checkbox 
                                                density="compact"
                                                label="Phone Number"
                                                v-model="acceptedPhone"
                                                :hide-details="getValidateError('accepted_phone') ? false : true"
                                                :error-messages="getValidateError('accepted_phone')"
                                                :class="getValidateError('accepted_phone') ? 'mb-2':''">
                                            </v-checkbox>
                                            <div>
                                                by continuing, I agree to 
                                                <a 
                                                    href="/privacy-policy" 
                                                    target="_blank"
                                                    class="tw-underline tw-underline-offset-1">
                                                    Privacy Policy
                                                </a>
                                            </div>
                                        </div>
                                        <div class="tw-text-center tw-mb-3">
                                            <v-btn
                                                class="text-secondary"
                                                @click="send"
                                                :loading="loadingSend">
                                                SIGN UP
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { storeToRefs } from 'pinia'
    import { useAlertStore } from "@/store/alert"
    import { useConfigStore } from '@/store/config'

    const useConfig = useConfigStore()
    const { config, loading } = storeToRefs(useConfig)

    const alertStore = useAlertStore()

    const urlImage = window.urlImage

    const dialog = ref(false)

    const email = ref('')
    const phone = ref('')
    const acceptedPhone = ref(true)
    const acceptedEmail = ref(true)

    const loadingSend = ref(false)
    const msgValidate = ref('')

    const msgSuccess = ref()

    checkPopup()

    async function send(){
        msgValidate.value = ''
        loadingSend.value = true
        msgSuccess.value = ''

        await api.post(window.baseUrl + 'subscribes/subscribe-popup', {
            email: email.value,
            phone: phone.value,
            accepted_phone: acceptedPhone.value,
            accepted_email: acceptedEmail.value,
        })
        .then((resp) => {
            loadingSend.value = false

            msgSuccess.value = resp.data.data

            alertStore.action(true, "success", "Success")
        })
        .catch((err) => {
            loadingSend.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 409){
                alertStore.action(true, "error", err.response.data.message)
            }  else if(err.response.status == 423){
                alertStore.action(true, "error", "Error")
            } else {
                alertStore.action(true, "error", "Internal server error")
            }
        })
    }

    function close() {
        msgSuccess.value = ''
        dialog.value = false
        disablePopup()
    }

    async function copyContent(text) {
        try {
            await navigator.clipboard.writeText(text)
        } catch (err) {
            //
        }
    }

    function disablePopup() {
        sessionStorage.setItem('popup', 'false')
        dialog.value = false
    }
    
    function checkPopup() {
        if(!sessionStorage.getItem('popup')){
            dialog.value = true
        } else {
            if(sessionStorage.getItem('popup') == 'false'){
                dialog.value = false
            } else {
                dialog.value = true
            }
        }
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>