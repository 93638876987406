<template>
    <div>
        <zBaseComponent
            v-if="!loading" 
            :data="page"/>

        <v-container
            v-if="loading" 
            class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate>
            </v-progress-circular>
            <!-- <v-row 
                class="my-10">
                <v-col 
                    md="4"
                    cols="6"
                    v-for="(item, p) in 9"
                    :key="'skeleton' + p">
                    <v-skeleton-loader
                        class="mx-auto border"
                        type="image, article">
                    </v-skeleton-loader>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script setup>
    import { ref, watch, onMounted } from 'vue'
    import { storeToRefs } from 'pinia'
    import zBaseComponent from '../components/zBaseComponent'
    import api from '../api'
    import { useRoute, useRouter } from 'vue-router'
    
    const route = useRoute()
    const router = useRouter()
    const page = ref({})

    const loading = ref(false)

    const slug = ref(route.params.slug)

    watch(() => route.params.slug, (val) => {
        slug.value = val
        getPage()
    })

    getPage()

    async function getPage(){
        try {
            loading.value = true
            const resPage = await api.get('page/' + slug.value)
            page.value = resPage.data.data
            
            document.title = resPage.data.title
            const metaTagTitle = document.querySelector('meta[name="title"]');
            if (metaTagTitle) {
                metaTagTitle.setAttribute('content', resPage.data.title);
            }
            loading.value = false

            scrollTo()
        } catch (err) {
            if(err.response.status == 404){
                router.push({ path: '/404' })
            }
            loading.value = false
        }
    }

    function scrollTo() {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 1000)
    }
</script>