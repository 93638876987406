<template>
    <div class="pt-md-5 pt-lg-10 pb-md-5 pb-lg-10">
        <div class="container-max-1600">
            <div  
                class="title text-center mb-5">
                <h1 class="z-title">
                    MY ACCOUNT
                </h1>
            </div>

            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5">
                <div>
                    <div>
                        <Profile />
                    </div>
                    <div>
                        <Password />
                    </div>
                    <div>
                        <Address />
                    </div>
                </div>
                <div>
                    <Wishlist />
                </div>
            </div>

        </div>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import Profile from './Profile'
    import Password from './Password'
    import Address from './Address/Index'
    import Wishlist from './Wishlist'

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>