<template>
    <div>
        <template v-if="isWithin24Hours">
            <span>{{ hours }} : </span>
            <span>{{ minutes }} : </span>
            <span>{{ seconds }}</span>
        </template>
        <template v-else>
            {{ monthDay }}
        </template>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, onUnmounted } from 'vue'

    const { data } = defineProps(['data'])

    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    const now = ref(new Date());

    function countdown() {
        now.value = new Date();
    }

    const remainingTime = computed(() => endDate - now.value);

    // Menghitung jam, menit, dan detik yang tersisa
    const hours = computed(() => Math.floor((remainingTime.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    const minutes = computed(() => Math.floor((remainingTime.value % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = computed(() => Math.floor((remainingTime.value % (1000 * 60)) / 1000));

    // Memeriksa apakah tanggal akhir (endDate) adalah hari ini
    const isToday = computed(() => {
        const today = new Date();
        return endDate.toDateString() === today.toDateString();
    });

    // Memeriksa apakah tanggal akhir berada dalam 24 jam ke depan
    const isWithin24Hours = computed(() => {
        const oneDay = 1000 * 60 * 60 * 24;
        return remainingTime.value <= oneDay;
    });

    // Memeriksa apakah tanggal akhir berada di bulan yang sama dengan tanggal sekarang
    const isSameMonth = computed(() => {
        return endDate.getMonth() === now.value.getMonth() && endDate.getFullYear() === now.value.getFullYear();
    });

    // Mendapatkan bulan dan hari
    const monthDay = computed(() => {
        const options = { month: 'long', day: 'numeric' };
        return endDate.toLocaleDateString('en-US', options);
    });

    // Mendapatkan hanya hari
    const day = computed(() => {
        const options = { day: 'numeric' };
        return endDate.toLocaleDateString('en-US', options);
    });

    let interval;
    onMounted(() => {
        interval = setInterval(countdown, 1000);
    });

    onUnmounted(() => {
        clearInterval(interval);
    });
</script>
