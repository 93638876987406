<template>
    <div>
        
        <zBaseComponent :data="page"/>
        <v-container
            v-if="loading"
            class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate>
            </v-progress-circular>
            <!-- <v-row 
                class="my-10">
                <v-col 
                    md="4"
                    cols="6"
                    v-for="(item, p) in 9"
                    :key="'skeleton' + p">
                    <v-skeleton-loader
                        class="mx-auto border"
                        type="image, article">
                    </v-skeleton-loader>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import zBaseComponent from '../components/zBaseComponent'
    import api from '../api'

    const page = ref({})

    const loading = ref(false)

    getPage()

    async function getPage(){
        loading.value = true
        const resPage = await api.get('page/home')
        page.value = resPage.data.data
        loading.value = false
    }

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>

<style>
    .theme--light.v-tabs-items{
        background: transparent;
    }
</style>