<template>
    <div class="pt-md-5 pt-lg-10 pb-md-5 pb-lg-10">
        <div 
            class="v-container"
            v-if="order">

            <div  
                class="title text-center mb-5">
                <h1 class="tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-text-3xl tw-my-3">
                    REVIEW YOUR ORDER
                </h1>
            </div>

            <div>
                <div
                    v-if="countSubmitReviewProduct == order.products.length"
                    class="text-center">
                    <div>
                        You have reviewed the product
                    </div>
                    <v-btn
                        class="text-secondary mt-5" 
                        href="/">
                        Shop Now
                    </v-btn>
                </div>

                <div v-else>
                    <div
                        v-for="product in order.products"
                        :key="product.id"
                        class="mb-5">
                        <ReviewItem 
                            :data="product"
                            @reload="reloadData">
                        </ReviewItem>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import api from '@/api'
    import { useRoute } from 'vue-router'
    import ReviewItem from './ReviewItem'

    const urlImage = window.urlImage

    const order = ref('')
    const loading = ref(false)
    
    const route = useRoute()
    const id = route.params.id

    const countSubmitReviewProduct = ref(0)

    function reloadData(data){
        getOrders()
    }

    getOrders()
    async function getOrders(){
        await api.get('user/orders/' + id)
        .then((resp) => {
            order.value = resp.data.data

            let countSubmitReview = 0
            resp.data.data.products.forEach(product => {
                if(product.submit_review){
                    countSubmitReview += 1
                }
            })

            countSubmitReviewProduct.value = countSubmitReview
        })
        .then(() => {
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }


    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>