<template>
    <div>
        <div v-if="!loading">
            <div v-if="reviews.rating.count_review > 0">

                <div>
                    <div class="tw-text-center tw-my-10">
                        <div class="tw-text-3xl tw-my-9 tw-text-center">
                            Users Rate
                        </div>
                        <div class="tw-font-heading tw-font-bold tw-text-5xl tw-my-5">
                            {{ reviews.rating.rating.avarage }}
                        </div>
                        <div>
                            <v-rating
                                readonly
                                :model-value="reviews.rating.rating.avarage"
                                color="yellow-darken-1"
                                half-increments>
                            </v-rating>
                        </div>
                        <div class="tw-font-body">
                            Based on {{ reviews.rating.count_review }} reviews
                        </div>
                    </div>

                    <div class="tw-grid tw-grid-cols-2 tw-gap-6 tw-w-4/5 tw-mx-auto">
                        <div>
                            <v-list
                                bg-color="transparent"
                                class="d-flex flex-column-reverse"
                                density="compact">
                                <v-list-item v-for="(rating, i) in 5" :key="i">
                                    <v-progress-linear
                                        :model-value="(reviews.rating.rating.rating[rating] / reviews.rating.count_review) * 100"
                                        color="primary"
                                        height="20">
                                    </v-progress-linear>

                                    <template v-slot:prepend>
                                        <div class="mr-3 tw-w-[57px]">
                                            <span class="mr-1">{{ rating }}</span>
                                            stars
                                        </div>
                                    </template>

                                    <template v-slot:append>
                                        <div class="ml-3">
                                            {{ reviews.rating.rating.rating[rating] }} 
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </div>
                        <div>
                            <v-list
                                bg-color="transparent"
                                class="d-flex flex-column"
                                density="compact">
                                <v-list-item>
                                    <div class="rating-bar">
                                        <div
                                            v-for="(bar, index) in 5"
                                            :key="index"
                                            :class="['bar', { active: index < Math.trunc(reviews.rating.rating_material.avarage) }]">
                                        </div>
                                    </div>

                                    <template v-slot:prepend>
                                        <div class="mr-3 tw-w-[67px]">
                                            Material
                                        </div>
                                    </template>
                                </v-list-item>
                                <v-list-item>
                                    <div class="rating-bar">
                                        <div
                                            v-for="(bar, index) in 5"
                                            :key="index"
                                            :class="['bar', { active: index < Math.trunc(reviews.rating.rating_quality.avarage) }]">
                                        </div>
                                    </div>

                                    <template v-slot:prepend>
                                        <div class="mr-3 tw-w-[67px]">
                                            Quality
                                        </div>
                                    </template>
                                </v-list-item>
                                <v-list-item>
                                    <div class="rating-bar">
                                        <div
                                            v-for="(bar, index) in 5"
                                            :key="index"
                                            :class="['bar', { active: index < Math.trunc(reviews.rating.rating_design.avarage) }]">
                                        </div>
                                    </div>

                                    <template v-slot:prepend>
                                        <div class="mr-3 tw-w-[67px]">
                                            Design
                                        </div>
                                    </template>
                                </v-list-item>
                                <v-list-item>
                                    <div class="rating-bar">
                                        <div
                                            v-for="(bar, index) in 5"
                                            :key="index"
                                            :class="['bar', { active: index < Math.trunc(reviews.rating.rating_value.avarage) }]">
                                        </div>
                                    </div>

                                    <template v-slot:prepend>
                                        <div class="mr-3 tw-w-[67px]">
                                            Value
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </div>
                        
                    </div>
                </div>

                <div
                    class="tw-my-5" 
                    v-if="reviews.files && reviews.files.length > 0">
                    <v-divider 
                        :thickness="1"
                        class="mb-5"/>

                    <File :data="reviews.files"/>

                    <v-divider 
                        :thickness="1"
                        class="mt-10"/>
                </div>
                <div>
                    <div class="tw-flex">
                        <div>
                            <v-menu
                                transition="slide-y-transition"
                                right="0">
                                <template v-slot:activator="{ props }">
                                    <div
                                        v-bind="props"
                                        class="tw-cursor-pointer tw-font-bold">
                                        Filter by 
                                        <v-icon>
                                            mdi-chevron-down
                                        </v-icon>
                                    </div>
                                </template>
                                <v-list>
                                    <v-list-item @click="chageRating(1)">
                                        <v-list-item-title >
                                            1 Start
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageRating(2)">
                                        <v-list-item-title>
                                            2 Starts
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageRating(3)">
                                        <v-list-item-title>
                                            3 Starts
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageRating(4)">
                                        <v-list-item-title>
                                            4 Starts
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageRating(5)">
                                        <v-list-item-title>
                                            5 Starts
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                        <v-spacer/>
                        <div>
                            <v-menu
                                transition="slide-y-transition"
                                right="0">
                                <template v-slot:activator="{ props }">
                                    <div
                                        v-bind="props"
                                        class="tw-cursor-pointer tw-font-bold">
                                        Sort By 
                                        <v-icon>
                                            mdi-chevron-down
                                        </v-icon>
                                    </div>
                                </template>
                                <v-list>
                                    <v-list-item @click="chageShort('created_at.desc')">
                                        <v-list-item-title>
                                            Most Recent
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageShort('helpful_up.desc')">
                                        <v-list-item-title>
                                            Most Helpful
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="chageShort('rating.desc')">
                                        <v-list-item-title>
                                            Highest to Lowest Rate
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                    <v-divider 
                    :thickness="1"
                    class="my-5"/>
                    <div
                        v-for="review in reviews.data"
                        :key="review.id">
                        <Item
                            class="my-5" 
                            :data="review"/>
                        <v-divider />
                    </div>

                    <div>
                        <v-pagination
                            v-if="reviews.meta"
                            v-model="page"
                            :length="reviews.meta.last_page"
                            :total-visible="5"
                            class="my-4">
                        </v-pagination>
                    </div>
                </div>

            </div>
            <div v-else>
                There is No Review Yet
            </div>
        </div>
        
        <div v-else>
            <div 
                class="text-center">
                <v-progress-circular
                    color="primary"
                    indeterminate>
                </v-progress-circular>
            </div>
        </div>
    </div>
    
</template>

<script setup>
    import { ref, watch, } from 'vue'
    import api from '@/api'
    import Item from '@/components/review/Item'
    import File from '@/components/review/File'
    // import CoverVideo from '@/components/zCoverVideo'

    const { productid } = defineProps(['productid'])

    const loading = ref(false)
    const page = ref(1)
    
    const rating = ref('')
    const sort = ref('')

    const reviews = ref([])

    // function reloadData(data){
    //     getReview()
    // }

    watch(page, (val) => {
        if(val){
            getReview()
        }
    })

    function getParam(){
        const params = {
            rating: rating.value,
            sort: sort.value,
            page: page.value,
        };

        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([key, value]) => value)
        );

        return new URLSearchParams(filteredParams).toString();
    }

    function chageShort(val){
        sort.value = val
    
        getReview()
    }

    function chageRating(val){
        rating.value = val
    
        getReview()
    }

    getReview()
    async function getReview(){
        loading.value = true
        
        await api.get('reviews/' + productid + '?' + getParam())
            .then((resp) => {
                reviews.value = resp.data
                
                loading.value = false
            })
            .catch((err) => {
                loading.value = false
            })
    }
</script>

<style lang="scss" scoped>
    .rating-bar{
        display: flex;
        width: 100%;
        .bar{
            background: #e4ebf4;
            height: 20px;
            width: 20%;
            margin-left: 3px;
            margin-right: 3px;
            &.active{
                background: #2559a5;
            }
        }
    }
</style>