<template>
  <div>
    <v-btn
        variant="outlined"
        block
        size="large"
        @click="signInWithGoogle"
        :loading="loading">
        <v-icon class="mr-3">mdi-google</v-icon> 
        Continue with Google
    </v-btn>
  </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import api from '@/api'
    import { setToken } from '@/auth'

    const loading = ref(false)

    const signInWithGoogle = async () => {
        try {
            loading.value = true

            const client = google.accounts.oauth2.initTokenClient({
                client_id: window.GoogleClientId, // Replace with your client ID
                scope: 'openid email profile',
                callback: async (response) => {
                    const googleAccessToken = response.access_token

                    await api.post('oauth/google', { token: googleAccessToken })
                        .then((resp) => {
                            loading.value = false
                            setToken(true)
                            window.location = '/'

                        })
                        .catch((err) => {
                            loading.value = false
                        })
                }
            });

            client.requestAccessToken();
        } catch (error) {
            loading.value = false
        }
    };

    const loadGoogleIdentityServices = () => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    };

    onMounted(() => {
        loadGoogleIdentityServices();
    });
</script>