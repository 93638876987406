<template>
    <div style="position: relative;">
        <div
            v-for="(component, c) in data"
            :key="'components' + c"
            :class="component.class_css">
            <div 
                v-for="(content, c) in component.contents"
                :key="'contentc' + c">
                <div   
                    v-for="(item, c) in content.items"
                    :key="'map' + c">
                    <iframe 
                        :src="'https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q='+ item.value + '&z=18&amp;output=embed'" 
                        width="100%" 
                        height="450" 
                        style="border:0;"
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        :class="item.class_css">
                    </iframe>
                </div>
            </div>
        </div>

        <div 
            v-if="component == 'map2'"
            class="z-btn-get-direction">
            <v-btn 
                color="bg-white text-primary"
                :href="'https://www.google.com/maps?saddr=My+Location&daddr=' + getCoordinate(data).value"
                target="_blank">
                GET DIRECTION
            </v-btn>
        </div>
    </div>
</template>

<script setup>
    const {data, component} = defineProps(['data', 'component'])

    function getCoordinate(data) {
        const dataItems = data[0].contents[0].items

        return dataItems[0]
    }
</script>