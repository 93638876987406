<template>
    <div class="mb-15">
        <div>
            <div class="container-max-1600 mb-5">
                <div class="tw-flex">
                    <div>
                        <span class="tw-font-bold">{{ countProduct }}</span> Result
                    </div>
                    <v-spacer/>
                    <div>
                        <v-menu
                            transition="slide-y-transition"
                            right="0">
                            <template v-slot:activator="{ props }">
                                <div
                                    v-bind="props"
                                    class="tw-cursor-pointer">
                                    <span 
                                        class="tw-font-bold">
                                        Sort By 
                                    </span> 

                                    {{ sortName }}
                                    <v-icon>
                                        mdi-chevron-down
                                    </v-icon>
                                </div>
                            </template>
                            <v-list>
                                <v-list-item @click="chageShort('selling.desc', 'Best Selling')">
                                    <v-list-item-title>
                                        Best Selling
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chageShort('created_at.desc', 'New Arrival')">
                                    <v-list-item-title>
                                        New Arrival
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chageShort('rating.desc', 'Highest Rated')">
                                    <v-list-item-title>
                                        Highest Rated
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chageShort('price.desc', 'Price High to Low')">
                                    <v-list-item-title>
                                        Price High to Low
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="chageShort('price.asc', 'Price Low to High')">
                                    <v-list-item-title>
                                        Price Low to High
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>

                <div v-if="$vuetify.display.smAndDown">
                    <v-btn 
                        variant="outlined"
                        class="mt-3"
                        @click="openFilerMobile = !openFilerMobile">
                        <v-icon v-if="!openFilerMobile">mdi-filter</v-icon> 
                        <v-icon v-if="openFilerMobile">mdi-close</v-icon> 
                        Fitler
                    </v-btn>

                    <div v-if="openFilerMobile">
                        <v-list
                            density="compact" 
                            v-model:opened="openFilter">
                            <template v-if="filters.attributes">
                                <v-list-group
                                    class="filter-list-group"
                                    v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Brand')"
                                    :key="'attr' + a"
                                    :value="attr.title">
                                    <template v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                {{ attr.title }}
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        class="filter-list py-0"
                                        v-for="(item, i) in attr.items.slice(0, limitFilterBrand)"
                                        :key="'item' + i"
                                        style="padding-left: 15px!important;">
                                        <v-checkbox
                                            :value="attr.title + ';' + item"
                                            v-model="selectedFilter"
                                            :label="item"
                                            hide-details
                                            density="compact">
                                        </v-checkbox>
                                    </v-list-item>
                                    <v-list-item
                                        class="filter-list pt-3 pb-5"
                                        style="padding-left: 15px!important;"
                                        v-if="attr.items.length > 5">
                                        <v-btn
                                            v-if="attr.items.length > limitFilterBrand" 
                                            variant="outlined"
                                            @click="limitFilterBrand = 1000">
                                            Show All
                                        </v-btn>
                                        <v-btn 
                                            v-else
                                            variant="outlined"
                                            @click="limitFilterBrand = 5">
                                            Less
                                        </v-btn>
                                    </v-list-item>
                                </v-list-group>
                                <v-list-group
                                    class="filter-list-group"
                                    v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Device')"
                                    :key="'attr' + a"
                                    :value="attr.title">
                                    <template v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                {{ attr.title }}
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        class="filter-list py-0"
                                        v-for="(item, i) in attr.items.slice(0, limitFilterDevice)"
                                        :key="'item' + i"
                                        style="padding-left: 15px!important;">
                                        <v-checkbox
                                            :value="attr.title + ';' + item"
                                            v-model="selectedFilter"
                                            :label="item"
                                            hide-details
                                            density="compact">
                                        </v-checkbox>
                                    </v-list-item>
                                    <v-list-item
                                        class="filter-list pt-3 pb-5"
                                        style="padding-left: 15px!important;"
                                        v-if="attr.items.length > 5">
                                        <v-btn
                                            v-if="attr.items.length > limitFilterDevice" 
                                            variant="outlined"
                                            @click="limitFilterDevice = 1000">
                                            Show All
                                        </v-btn>
                                        <v-btn 
                                            v-else
                                            variant="outlined"
                                            @click="limitFilterDevice = 5">
                                            Less
                                        </v-btn>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-if="filters.price">
                                <v-list-group
                                    class="filter-list-group"
                                    value="price">
                                    <template v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                Price
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        class="filter-list py-0"
                                        style="padding-left: 15px!important;">
                                        <div class="pb-2 pt-1 px-2">
                                            <v-range-slider
                                                v-model="filterRangePrice"
                                                :max="filterMaxPrice"
                                                :min="filterMinPrice"
                                                :step="10"
                                                class="align-center"
                                                hide-details>
                                            </v-range-slider>
                                        </div>
                                        <div class="mt-2 tw-flex">

                                            <VCurrencyField
                                                v-model="filterRangePrice[0]"
                                                class="w-100 mr-2"
                                                label="Minimum"/>

                                            <VCurrencyField
                                                v-model="filterRangePrice[1]"
                                                class="w-100 ml-2"
                                                label="Maximum"/>
                                        </div>
                                        <v-btn 
                                            class="mb-4"
                                            @click="applyPrice">
                                            Apply
                                        </v-btn>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-if="filters.variants">
                                <v-chip-group 
                                    filter 
                                    column
                                    class="px-0">
                                    <v-list-group
                                        class="filter-list-group w-100"
                                        v-for="(variant, v) in filters.variants"
                                        :key="'variant' + v"
                                        :value="v">
                                        <template 
                                            v-if="v"
                                            v-slot:activator="{ props }">
                                            <v-divider/>
                                            <v-list-item
                                                v-bind="props">
                                                <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                    {{ v }}
                                                </div>
                                            </v-list-item>
                                        </template>
                                        <v-list-item
                                            v-if="v"
                                            class="filter-list py-0"
                                            style="padding-left: 15px!important;">
                                            <div v-if="v == 'color'">
                                                <template
                                                    v-for="(vv, vi) in variant.slice(0, limitFilterColor)"
                                                    :key="'variantitem' + vi">
                                                    <v-chip
                                                        v-if="getColorCode(vv)"
                                                        class="mb-2 mr-2 chip-color"
                                                        rounded
                                                        @click="selectVariant(vv)"
                                                        :style="{
                                                            width: '25px',
                                                            height: '25px',
                                                            background: getColorCode(vv)
                                                        }">
                                                    </v-chip>
                                                    <v-chip
                                                        v-else
                                                        class="mb-2 mr-2"
                                                        rounded
                                                        @click="selectVariant(vv)">
                                                        {{ vv }}
                                                    </v-chip>
                                                </template>

                                                <div 
                                                    class="my-5"
                                                    v-if="variant.length > 20">
                                                    <v-btn
                                                        v-if="variant.length > limitFilterColor" 
                                                        variant="outlined"
                                                        @click="limitFilterColor = 1000">
                                                        Show All
                                                    </v-btn>
                                                    <v-btn 
                                                        v-else
                                                        variant="outlined"
                                                        @click="limitFilterColor = 20">
                                                        Less
                                                    </v-btn>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <v-chip
                                                    v-for="(vv, vi) in variant"
                                                    :key="'variantitem' + vi"
                                                    class="mb-2 mr-2"
                                                    @click="selectVariant(vv)">
                                                    {{ vv }}
                                                </v-chip>
                                            </div>
                                        </v-list-item>
                                    </v-list-group>
                                </v-chip-group>
                            </template>
                            <template v-if="filters.tags && filters.tags.length > 0">
                                <v-list-group
                                    class="filter-list-group"
                                    value="tag">
                                    <template v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                Tags
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        class="filter-list py-0"
                                        style="padding-left: 15px!important;">
                                        <v-chip-group 
                                            filter 
                                            column
                                            class="px-0">
                                            <v-chip
                                                v-for="(tag, t) in filters.tags"
                                                :key="'tag' + t"
                                                class="mb-2 mr-2"
                                                @click="selectTag(tag)">
                                                {{ tag }}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-if="filters.attributes">
                                <v-list-group
                                    class="filter-list-group"
                                    v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Series')"
                                    :key="'attr' + a"
                                    :value="attr.title">
                                    <template v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                {{ attr.title }}
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        class="filter-list py-0"
                                        v-for="(item, i) in attr.items.slice(0, limitFilterSeries)"
                                        :key="'item' + i"
                                        style="padding-left: 15px!important;">
                                        <v-checkbox
                                            :value="attr.title + ';' + item"
                                            v-model="selectedFilter"
                                            :label="item"
                                            hide-details
                                            density="compact">
                                        </v-checkbox>
                                    </v-list-item>
                                    <v-list-item
                                        class="filter-list pt-3 pb-5"
                                        style="padding-left: 15px!important;"
                                        v-if="attr.items.length > 5">
                                        <v-btn
                                            v-if="attr.items.length > limitFilterSeries" 
                                            variant="outlined"
                                            @click="limitFilterSeries = 1000">
                                            Show All
                                        </v-btn>
                                        <v-btn 
                                            v-else
                                            variant="outlined"
                                            @click="limitFilterSeries = 5">
                                            Less
                                        </v-btn>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                        </v-list>

                        <v-btn
                            block
                            class="mt-5"
                            @click="resetFilter">
                            Reset All
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-max-1600">
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 tw-gap-10">
                <div v-if="!$vuetify.display.smAndDown">
                    <v-list
                        density="compact" 
                        v-model:opened="openFilter">
                        <template v-if="filters.attributes">
                            <v-list-group
                                class="filter-list-group"
                                v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Brand')"
                                :key="'attr' + a"
                                :value="attr.title">
                                <template v-slot:activator="{ props }">
                                    <v-divider/>
                                    <v-list-item
                                        v-bind="props">
                                        <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                            {{ attr.title }}
                                        </div>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    class="filter-list py-0"
                                    v-for="(item, i) in attr.items.slice(0, limitFilterBrand)"
                                    :key="'item' + i"
                                    style="padding-left: 15px!important;">
                                    <v-checkbox
                                        :value="attr.title + ';' + item"
                                        v-model="selectedFilter"
                                        :label="item"
                                        hide-details
                                        density="compact">
                                    </v-checkbox>
                                </v-list-item>
                                <v-list-item
                                    class="filter-list pt-3 pb-5"
                                    style="padding-left: 15px!important;"
                                    v-if="attr.items.length > 5">
                                    <v-btn
                                        v-if="attr.items.length > limitFilterBrand" 
                                        variant="outlined"
                                        @click="limitFilterBrand = 1000">
                                        Show All
                                    </v-btn>
                                    <v-btn 
                                        v-else
                                        variant="outlined"
                                        @click="limitFilterBrand = 5">
                                        Less
                                    </v-btn>
                                </v-list-item>
                            </v-list-group>
                            <v-list-group
                                class="filter-list-group"
                                v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Device')"
                                :key="'attr' + a"
                                :value="attr.title">
                                <template v-slot:activator="{ props }">
                                    <v-divider/>
                                    <v-list-item
                                        v-bind="props">
                                        <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                            {{ attr.title }}
                                        </div>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    class="filter-list py-0"
                                    v-for="(item, i) in attr.items.slice(0, limitFilterDevice)"
                                    :key="'item' + i"
                                    style="padding-left: 15px!important;">
                                    <v-checkbox
                                        :value="attr.title + ';' + item"
                                        v-model="selectedFilter"
                                        :label="item"
                                        hide-details
                                        density="compact">
                                    </v-checkbox>
                                </v-list-item>
                                <v-list-item
                                    class="filter-list pt-3 pb-5"
                                    style="padding-left: 15px!important;"
                                    v-if="attr.items.length > 5">
                                    <v-btn
                                        v-if="attr.items.length > limitFilterDevice" 
                                        variant="outlined"
                                        @click="limitFilterDevice = 1000">
                                        Show All
                                    </v-btn>
                                    <v-btn 
                                        v-else
                                        variant="outlined"
                                        @click="limitFilterDevice = 5">
                                        Less
                                    </v-btn>
                                </v-list-item>
                            </v-list-group>
                        </template>
                        <template v-if="filters.price">
                            <v-list-group
                                class="filter-list-group"
                                value="price">
                                <template v-slot:activator="{ props }">
                                    <v-divider/>
                                    <v-list-item
                                        v-bind="props">
                                        <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                            Price
                                        </div>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    class="filter-list py-0"
                                    style="padding-left: 15px!important;">
                                    <div class="pb-2 pt-1 px-2">
                                        <v-range-slider
                                            v-model="filterRangePrice"
                                            :max="filterMaxPrice"
                                            :min="filterMinPrice"
                                            :step="10"
                                            class="align-center"
                                            hide-details>
                                        </v-range-slider>
                                    </div>
                                    <div class="mt-2 tw-flex">

                                        <VCurrencyField
                                            v-model="filterRangePrice[0]"
                                            class="w-100 mr-2"
                                            label="Minimum"/>

                                        <VCurrencyField
                                            v-model="filterRangePrice[1]"
                                            class="w-100 ml-2"
                                            label="Maximum"/>
                                    </div>
                                    <v-btn 
                                        class="mb-4"
                                        @click="applyPrice">
                                        Apply
                                    </v-btn>
                                </v-list-item>
                            </v-list-group>
                        </template>
                        <template v-if="filters.variants">
                            <v-chip-group 
                                filter 
                                column
                                class="px-0">
                                <v-list-group
                                    class="filter-list-group w-100"
                                    v-for="(variant, v) in filters.variants"
                                    :key="'variant' + v"
                                    :value="v">
                                    <template 
                                        v-if="v"
                                        v-slot:activator="{ props }">
                                        <v-divider/>
                                        <v-list-item
                                            v-bind="props">
                                            <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                {{ v }}
                                            </div>
                                        </v-list-item>
                                    </template>
                                    <v-list-item
                                        v-if="v"
                                        class="filter-list py-0"
                                        style="padding-left: 15px!important;">
                                        <div v-if="v == 'color'">
                                            <template
                                                v-for="(vv, vi) in variant.slice(0, limitFilterColor)"
                                                :key="'variantitem' + vi">
                                                <v-chip
                                                    v-if="getColorCode(vv)"
                                                    class="mb-2 mr-2 chip-color"
                                                    rounded
                                                    @click="selectVariant(vv)"
                                                    :style="{
                                                        width: '25px',
                                                        height: '25px',
                                                        background: getColorCode(vv)
                                                    }">
                                                </v-chip>
                                                <v-chip
                                                    v-else
                                                    class="mb-2 mr-2"
                                                    rounded
                                                    @click="selectVariant(vv)">
                                                    {{ vv }}
                                                </v-chip>
                                            </template>

                                            <div 
                                                class="my-5"
                                                v-if="variant.length > 20">
                                                <v-btn
                                                    v-if="variant.length > limitFilterColor" 
                                                    variant="outlined"
                                                    @click="limitFilterColor = 1000">
                                                    Show All
                                                </v-btn>
                                                <v-btn 
                                                    v-else
                                                    variant="outlined"
                                                    @click="limitFilterColor = 20">
                                                    Less
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <v-chip
                                                v-for="(vv, vi) in variant"
                                                :key="'variantitem' + vi"
                                                class="mb-2 mr-2"
                                                @click="selectVariant(vv)">
                                                {{ vv }}
                                            </v-chip>
                                        </div>
                                    </v-list-item>
                                </v-list-group>
                            </v-chip-group>
                        </template>
                        <template v-if="filters.tags && filters.tags.length > 0">
                            <v-list-group
                                class="filter-list-group"
                                value="tag">
                                <template v-slot:activator="{ props }">
                                    <v-divider/>
                                    <v-list-item
                                        v-bind="props">
                                        <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                            Tags
                                        </div>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    class="filter-list py-0"
                                    style="padding-left: 15px!important;">
                                    <v-chip-group 
                                        filter 
                                        column
                                        class="px-0">
                                        <v-chip
                                            v-for="(tag, t) in filters.tags"
                                            :key="'tag' + t"
                                            class="mb-2 mr-2"
                                            @click="selectTag(tag)">
                                            {{ tag }}
                                        </v-chip>
                                    </v-chip-group>
                                </v-list-item>
                            </v-list-group>
                        </template>
                        <template v-if="filters.attributes">
                            <v-list-group
                                class="filter-list-group"
                                v-for="(attr, a) in filters.attributes.filter(item => item.title == 'Series')"
                                :key="'attr' + a"
                                :value="attr.title">
                                <template v-slot:activator="{ props }">
                                    <v-divider/>
                                    <v-list-item
                                        v-bind="props">
                                        <div class="tw-uppercase tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                            {{ attr.title }}
                                        </div>
                                    </v-list-item>
                                </template>
                                <v-list-item
                                    class="filter-list py-0"
                                    v-for="(item, i) in attr.items.slice(0, limitFilterSeries)"
                                    :key="'item' + i"
                                    style="padding-left: 15px!important;">
                                    <v-checkbox
                                        :value="attr.title + ';' + item"
                                        v-model="selectedFilter"
                                        :label="item"
                                        hide-details
                                        density="compact">
                                    </v-checkbox>
                                </v-list-item>
                                <v-list-item
                                    class="filter-list pt-3 pb-5"
                                    style="padding-left: 15px!important;"
                                    v-if="attr.items.length > 5">
                                    <v-btn
                                        v-if="attr.items.length > limitFilterSeries" 
                                        variant="outlined"
                                        @click="limitFilterSeries = 1000">
                                        Show All
                                    </v-btn>
                                    <v-btn 
                                        v-else
                                        variant="outlined"
                                        @click="limitFilterSeries = 5">
                                        Less
                                    </v-btn>
                                </v-list-item>
                            </v-list-group>
                        </template>
                    </v-list>

                    <v-btn
                        block
                        class="mt-5"
                        @click="resetFilter">
                        Reset All
                    </v-btn>
                </div>
                <div class="md:tw-col-span-4 lg:tw-col-span-3">
                    <div 
                        v-if="loading"
                        class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate>
                        </v-progress-circular>
                    </div>
                    <div>
                        <div class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-3">
                            <div 
                                v-for="product in products.data"
                                :key="product.id">
                                <zProduct :data="product">
                                </zProduct>
                            </div>
                        </div>
                        
                        <v-pagination
                            v-if="products.meta"
                            v-model="page"
                            :length="products.meta.last_page"
                            :total-visible="5"
                            class="my-4">
                        </v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, watch, onMounted } from 'vue'
    import zProduct from '@/components/zProduct.vue'
    import api from '@/api'
    import { useRoute } from 'vue-router'
    import VCurrencyField from '@/components/VCurrencyField'
    import { storeToRefs } from 'pinia'
    import { useConfigStore } from '../store/config'

    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)

    const props = defineProps(['collection', 'search'])

    const route = useRoute()

    const products = ref([])

    const loading = ref(false)
    const page = ref(1)

    const openFilerMobile = ref(false)

    const countProduct = ref(0)
    const filters = ref([])

    const filterMinPrice = ref(1000)
    const filterMaxPrice = ref(10000000)
    const filterRangePrice = ref([1000, 10000000])

    const selectedFilter = ref([])
    const fbrand = ref('')
    const fdevice = ref('')
    const fseries = ref('')
    const minPrice = ref('')
    const maxPrice = ref('')
    const fprice = ref('')
    const ftag = ref('')
    const fvariant = ref('')
    const sort = ref('')
    const sortName = ref('')

    const search = ref(route.query.s)


    const openFilter = ref(['price', 'tag', 'color', 'style', 'size', 'Device', 'Series', 'Brand'])
    const limitFilterBrand = ref(5)
    const limitFilterDevice = ref(5)
    const limitFilterSeries = ref(5)
    const limitFilterColor = ref(20)


    if(route.query.brand){
        selectedFilter.value.push('Brand;' + route.query.brand)
        fbrand.value = route.query.brand
    }
    if(route.query.device){
        selectedFilter.value.push('Device;' + route.query.device)
        fdevice.value = route.query.device
    }
    if(route.query.series){
        selectedFilter.value.push('Series;' + route.query.series)
        fseries.value = route.query.series
    }
    if(route.query.s){
        selectedFilter.value.push('Series;' + route.query.s)
        search.value = route.query.s
    }

    getItem()
    getFilter()
    watch(page, (val) => {
        if(val){
            getItem()
        }
    })

    watch(selectedFilter, (val) => {
        if(val){
            let data = {
                brand: [],
                device: [],
                series: []
            }
            val.forEach((v) => {
                const sVal = v.split(';')
                if(sVal[0] == 'Brand'){
                    data.brand.push(sVal[1])
                }
                if(sVal[0] == 'Device'){
                    data.device.push(sVal[1])
                }
                if(sVal[0] == 'Series'){
                    data.series.push(sVal[1])
                }
            })
            fbrand.value = data.brand.toString()
            fdevice.value = data.device.toString()
            fseries.value = data.series.toString()

            getItem()
        }
    })

    function applyPrice(){
        fprice.value = filterRangePrice.value[0] + '-' + filterRangePrice.value[1]
        getItem()
    }

    function selectTag(val){
        ftag.value = val
        getItem()
    }

    function selectVariant(val){
        fvariant.value = val
        getItem()
    }

    function chageShort(val, name){
        sort.value = val
        sortName.value = name

        getItem()
    }

    function resetFilter(){
        fbrand.value = ''
        fdevice.value = ''
        fseries.value = ''
        fprice.value = ''
        fvariant.value = ''
        ftag.value = ''
        search.value = ''
        page.value = 1
        selectedFilter.value = []
        getItem()

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function getParam(){
        const params = {
            collection: props.collection,
            brand: fbrand.value,
            device: fdevice.value,
            series: fseries.value,
            price: fprice.value,
            variant: fvariant.value,
            tag: ftag.value,
            sort: sort.value,
            page: page.value,
            search: search.value,
        };

        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([key, value]) => value)
        );

        return new URLSearchParams(filteredParams).toString();
    }

    async function getItem(){
        loading.value = true
        await api.get('products/?' + getParam())
            .then((resp) => {
                loading.value = false    
                products.value = resp.data
                countProduct.value = resp.data.meta.total
            })
            .catch((err) => {
                loading.value = false    
            })

    }

    async function getFilter(){
        let params = '';
        if(props.collection){
            params = 'collection=' + props.collection
        } else if(props.search){
            params = 'search=' + props.search
        }

        await api.get('filters/product?' + params)
            .then((resp) => {
                const dataFilter = resp.data.data
                filters.value = dataFilter

                if(dataFilter.price){
                    if(dataFilter.price.min && dataFilter.price.max){
                        filterMinPrice.value = dataFilter.price.min
                        filterMaxPrice.value = dataFilter.price.max
                        
                        filterRangePrice.value = [dataFilter.price.min, dataFilter.price.max]
                    }

                }
            })
            .catch((err) => {

            })
    }

    function getColorCode(color){
        const colorVariant = config.value.color_variant

        const codeColor = colorVariant.filter(item => item.name == color)

        if(codeColor[0]){
            return codeColor[0].code
        }
    }

    onMounted(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })

    // async function getFetchDataScroll(){
    //     const resItem = await api.get('products/?' + getParam())

    //     loadingInfinite.value = loadingPage.value
    //     resItem.data.data.forEach(function(data){
    //         products.value.push(data)
    //     })

    //     page.value = resItem.data.current_page + 1
    //     maxPage.value = resItem.data.current_page > resItem.data.last_page ? true : false
    //     nextPage.value = resItem.data.current_page == resItem.data.last_page ? false : true
    // }

    // window.onscroll = () => {
    //     const scrollTop = document.documentElement.scrollTop;
    //     const scrollHeight = document.documentElement.scrollHeight;
    //     const clientHeight = document.documentElement.clientHeight;
    //     if (scrollTop + clientHeight === scrollHeight) {
    //         // console.log(nextPage.value)
    //         if(nextPage.value){
    //             if(!maxPage.value){
    //                 loadingInfinite.value = true
    //             }
    //             console.log(maxPage.value)
    //             setTimeout(() => {
    //                 if(!maxPage.value){
    //                     getFetchDataScroll()
    //                 }
    //             }, 1000)
    //         }
    //     }
    // };

    // watch(selectCollection, (val) => {
    //     selectedCollection.value = val ? val : ''
    //     page.value = 1
    //     getItem()
    // })
</script>