<template>
    <div>
        <v-navigation-drawer
            v-model="active"
            temporary
            location="right"
            width="480">
            <div 
                class="d-flex px-2 py-2">
                <div>
                    <v-icon @click="active = !active">mdi-chevron-right</v-icon>
                </div>
                <v-spacer/>
                <div class="text-center text-h6">
                    YOUR CART
                </div>
                <v-spacer/>
            </div>
            <v-divider />

            <div>
                <div 
                    v-for="cart in carts"
                    class="box-cart">
                    <div 
                        class="d-flex align-center list-cart my-5 mx-4">
                        <div class="box-image">
                            <div 
                                class="image">
                                <img 
                                    :src="cart.image.image"
                                    :alt="cart.variant"
                                    v-if="cart.image">
                            </div>
                        </div>
                        <div 
                            class="title ml-3 w-100">
                            <div class="d-flex">
                                <div>
                                    <div>
                                        <b>{{ cart.title }}</b>
                                    </div>
                                    <div v-if="cart.variant != 'Default Title'">
                                        {{ cart.variant }}
                                    </div>
                                </div>
                                <v-spacer/>
                                <div>
                                    <v-icon @click="deleteItem(cart.id)">mdi-close</v-icon>
                                </div>
                            </div>
                            <div class="d-flex mt-2">
                                <div>
                                    <div class="text-caption mb-1">
                                        Quantity
                                    </div>

                                    <div class="text-qty">
                                        <div class="icon">
                                            <v-icon 
                                                class="icon-minus"
                                                size="small"
                                                @click="updateQty(cart, 'min')">
                                                mdi-minus
                                            </v-icon>
                                        </div>
                                        <input 
                                            @change="handleQuantityChange(cart)"
                                            v-model="cart.quantity"
                                            type="number">
                                        <div class="icon">
                                            <v-icon 
                                                class="icon-plus"
                                                size="small"
                                                @click="updateQty(cart, 'plus')">
                                                mdi-plus
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                                <v-spacer/>
                                <div class="price">
                                    <div class=""
                                        v-if="cart.special_price">
                                        <div class="font-weight-bold text-red">
                                            Rp {{ formatPrice(cart.price) }}
                                        </div>
                                        <div class="text-caption text-decoration-line-through">
                                            Rp {{ formatPrice(cart.normal_price) }}
                                        </div>
                                    </div>
                                    <div 
                                        class=""
                                        v-else>
                                        <div class="font-weight-bold text-red">
                                            Rp {{ formatPrice(cart.price) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <v-divider />
                </div>
            </div>

            <div v-if="carts.length > 0">
                <div v-if="products.length > 0">
                    <div class="tw-text-xl tw-mb-1 tw-px-4 tw-pt-4">
                        <b>Bundle Deals</b>
                    </div>

                    <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-p-4">
                        <div 
                            v-for="product in products"
                            :key="product.id">
                            <zProductSm :data="product" />
                        </div>
                    </div>
                    <v-divider/>
                </div>
            </div>
            <div v-if="carts.length > 0">
                <div class="d-flex px-3 my-3 font-weight-bold">
                    <div>
                        Total
                    </div>
                    <v-spacer />
                    <div>
                        Rp. {{ formatPrice(carts.reduce((sum, item) => sum + item.sub_price, 0)) }}
                    </div>
                </div>
            </div>

            <template v-if="config.cart && config.cart.cart_empty">
                <div 
                    v-if="carts.length == 0"
                    class="tw-w-2/3 tw-py-4 tw-my-7 mx-auto tw-text-center">
                    <div class="tw-font-bold">
                        {{ config.cart.cart_empty.text }}
                    </div>

                    <div>
                        <v-btn
                            block
                            variant="outlined"
                            class="my-3"
                            v-for="(link, l) in config.cart.cart_empty.link"
                            :key="'link' + l"
                            :href="link.value">
                            {{ link.label }}
                        </v-btn>
                    </div>
                </div>
            </template>

            <template v-if="config.cart && config.cart.payment_icon">
                <div class=" tw-bg-grey-100">
                    <v-divider/>
                    <div class="tw-w-2/4 tw-py-4 mx-auto tw-text-center icon-payment-cart">
                        <div class="tw-text-xl tw-mb-1">
                            {{ config.cart.payment_icon.text }}
                        </div>
                        <div class="tw-grid tw-grid-cols-5 tw-gap-2">
                            <div
                                v-for="(icon, i) in config.cart.payment_icon.icon"
                                :key="'icon' + i">
                                <img 
                                    :src="icon.value"
                                    :alt="icon.alt">
                            </div>
                        </div>
                    </div>
                    <v-divider/>
                </div>
            </template>
            <template v-if="config.cart && config.cart.text">
                <div class="tw-bg-grey-100">
                    <div class="tw-text-center tw-w-3/4 tw-py-4 mx-auto">
                        <div class="tw-text-xl tw-mb-3">
                            {{ config.cart.text.title }}
                        </div>
                        <div 
                            class="tw-text-sm"
                            v-html="config.cart.text.description">
                        </div>
                    </div>
                    <v-divider/>
                </div>
            </template>

            <template 
                v-slot:append
                v-if="carts.length > 0">
                <div class="d-flex px-3 pt-2 font-weight-bold">
                    <div>
                        {{ carts.reduce((sum, item) => sum + item.quantity, 0) }} items
                    </div>
                    <v-spacer />
                    <div>
                        Rp. {{ formatPrice(carts.reduce((sum, item) => sum + item.sub_price, 0)) }}
                    </div>
                </div>

                <div class="pa-2">
                    <v-btn 
                        block
                        size="x-large"
                        href="/checkout">
                    Check Out
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script setup>
    import { ref, watch } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useDisplay } from 'vuetify'
    import { useCartStore } from '@/store/cart'
    import { useConfigStore } from '@/store/config'
    import api from '@/api'
    import zProductSm from '@/components/zProductSm'

    const useCart = useCartStore()
    const { carts, active, loading } = storeToRefs(useCart)

    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)

    const urlIcon = window.urlIcon

    const products = ref([])

    // function closeCart() {
    //     useCartStore().closeCart()
    // }
    // function openCart() {
    //     useCartStore().openCart()
    // }

    function updateQty(cart, type) {
        if(type == 'min'){
            if(cart.quantity - 1 == 0){
                useCartStore().delete({
                    id: cart.id
                })
            } else {
                useCartStore().update({
                    id: cart.id,
                    product_id: cart.product_id,
                    qty: cart.quantity - 1
                })
            } 
        }
        if(type == 'plus'){
            if(cart.max_quantity >= (cart.quantity + 1)){
                useCartStore().update({
                    id: cart.id,
                    product_id: cart.product_id,
                    qty: cart.quantity + 1
                })
            }
        }
    }

    function deleteItem(id) {
        useCartStore().delete({
            id: id
        })
    }

    function handleQuantityChange(cart) {
        if(cart.quantity < 0){
            useCartStore().delete({
                id: cart.id
            })
        } else {
            useCartStore().update({
                id: cart.id,
                product_id: cart.product_id,
                qty: cart.quantity
            })
        }
    }

    watch(active, async () => {
        if(active.value){
            setTimeout(async() => {
                const dataCart = carts.value

                let productId = ''
                if(dataCart.length > 0){
                    productId = dataCart.map((d) => {
                        return d.product_id
                    })

                    await api.get('product-relations/cart?productid=' + productId.toString())
                    .then((resp) => {
                        products.value = resp.data.data
                    })
                    .catch((err) => {

                    })
                }
            }, 100)
        }
    })

    function formatPrice(value) {
        const val = (value/1);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
</script>