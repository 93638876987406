<script setup>
    import { useCurrencyInput } from 'vue-currency-input'
    import { watch } from 'vue'

    const props = defineProps(['modelValue', 'label', 'placeholder', 'hidetail']);

    const { inputRef, formattedValue, numberValue, setValue } = useCurrencyInput({
        currency: 'IDR',
        locale: 'id-ID',
        hideCurrencySymbolOnFocus: false,
        hideGroupingSeparatorOnFocus: false,
        precision: 0,
        valueRange: { min: 0 },
        groupingSeparator: '.',
    })

    const emit = defineEmits()

    watch(
        () => props.modelValue,
        (value) => {
            setValue(value);
        }
    )
</script>

<template>
    <div>
        <VTextField
            v-model="formattedValue"
            density="compact"
            variant="outlined"
            :label="props.label"
            :placeholder="props.placeholder"
            ref="inputRef"
            :hide-details="props.hidetail ? true : false">
        </VTextField>
    </div>
</template>
