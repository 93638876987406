<template>
    <div>
        <div 
            v-for="(component, c) in data.components"
            :key="'component' + c">

            <div 
                class="tw-gap-8 tw-items-center tw-py-8 tw-px-4 tw-mx-auto tw-max-w-screen-xl xl:tw-gap-16 md:tw-grid md:tw-grid-cols-2 sm:tw-py-16 lg:tw-px-6">
                <div class="tw-mt-4 md:tw-mt-0">
                    <template
                        v-for="(content, c) in component.contents"
                        :key="'content1' + c">
                        <template 
                            v-if="content.type == 'singleLineText'"
                            class="z-title-card">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmsingleLineText' + c"
                                :class="item.class_css + ' tw-mb-4 tw-text-4xl tw-tracking-tight tw-font-bold tw-font-heading'">
                                {{ item.value }}
                            </div>
                        </template>
                        <template 
                            v-if="content.type == 'multiLineText'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmmultiLineText' + c"
                                :class="item.class_css + ' tw-mb-4 tw-text-2xl tw-tracking-tight tw-font-bold tw-font-heading'">
                                {{ item.value }}
                            </div>
                        </template>
                        <template 
                            v-if="content.type == 'richText'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmmultiLineText' + c"
                                :class="item.class_css + ' tw-mb-6 tw-font-light md:tw-text-lg tw-font-body'">
                                <div v-html="item.value"></div>
                            </div>
                        </template>
                    </template>

                    <template
                        v-for="(content, c) in component.contents"
                        :key="'content2' + c">
                        <template 
                            v-if="content.type == 'link'"
                            class="w-100">
                            <div
                                class="d-flex"
                                v-for="(link, l) in content.items"
                                :key="'link' + l">
                                <v-btn 
                                    v-if="link.value"
                                    class="mt-7 mr-2"
                                    :href="link.value"
                                    variant="outlined">
                                    {{ link.label }}
                                </v-btn>
                            </div>
                        </template>
                    </template>
                </div>
                <template
                    v-for="(content, c) in component.contents"
                    :key="'contentc' + c">
                    <template 
                        v-if="content.type == 'image'">
                        <template   
                            v-for="(item, c) in content.items"
                            :key="'image' + c">
                            <template v-if="item.value">
                                <img 
                                    class="tw-w-full " 
                                    :src="urlImage + item.value" 
                                    :alt="item.heading">
                            </template>
                        </template>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    const urlImage = window.urlImage
    const { data } = defineProps(['data'])
</script>