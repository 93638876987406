// store/index.js
import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert', {
    state: () => ({
        active: false,
        message: "",
        type: ""
    }),
    actions: {
        action(active, type, message) {
            this.active = active
            this.message = message
            this.type = type
        },
    },
});
