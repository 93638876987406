import { createVuetify } from 'vuetify'
import { md3 } from 'vuetify/blueprints'

import * as labs from 'vuetify/labs/components'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const light = {
  dark: false,
  colors: {
    surface: '#FFFFFF',
    primary: '#2559A5',
    'primary-darken-1': '#090d66',
    secondary: '#ffcb05',
    'secondary-darken-1': '#9d7c00',
    tersier: '#ed1c24',
    error: '#B00020',
    info: '#2196F3',
    success: '#2559A5',
    warning: '#FB8C00',
    link: '#000',
  },
}

const dark = {
  dark: true,
  colors: {
    primary: '#000',
    'primary-darken-1': '#090d66',
    secondary: '#ffcb05',
    'secondary-darken-1': '#9d7c00',
    tersier: '#ed1c24',
    error: '#B00020',
    info: '#2196F3',
    success: '#3e418d',
    warning: '#FB8C00',
    link: '#fff',
  },
}

const vuetify = createVuetify({
  defaults: {
    VBtn: {
      style: 'text-transform: none;padding-left: 25px;padding-right: 25px; letter-spacing: normal;',
      rounded: 0,
    },
    VCard: {
      rounded: 0,
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light,
      dark,
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components: {
    ...labs
  },
  blueprint: md3,
})

export default vuetify