<template>
    <div>
        <div class="tw-mt-10">
            <div class="container-max-1600">
                <div class="tw-pb-5 tw-text-center">
                    <div class="tw-text-3xl tw-font-bold tw-mb-2">
                        <h1>Search</h1>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div v-if="search"> -->
            <FilterProduct :search="search" />
        <!-- </div> -->

    </div>
    
</template>

<script setup>
    import { ref, watch, onMounted } from 'vue'
    import { useRoute } from 'vue-router'
    import FilterProduct from '@/components/FilterProduct'

    const route = useRoute()
    
    const loading = ref(false)
    const search = ref(route.query.s)
    
    watch(() => route.query, (val) => {

        page.value = 1
        search.value = val.s
        brand.value = val.brand
        device.value = val.device
        series.value = val.series
        collection.value = val.collection

	})


    onMounted(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
</script>