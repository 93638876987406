<template>
    <v-card
        flat
        rounded="0"
        :href="'/news/' + data.slug"
        :hover="true"
        :ripple="false" 
        class="no-hover-overlay bg-transparent">
        <div 
            :class="'label ' + label"
            v-if="label">
            {{ label }}
        </div>
        <v-img
            v-if="data.image"
            :src="urlImage + data.image"
            aspect-ratio="1.5"
            cover
            :alt="data.title">
        </v-img>
        <v-card-item>
            <v-card-subtitle 
                class="mt-1 !tw-text-wrap"  
                v-if="data.created_at">
                {{ formatDate(data.created_at) }}
            </v-card-subtitle>
            <v-card-title 
                v-if="data.title">
                <div class="tw-font-heading tw-font-bold tw-text-xl tw-text-wrap">
                    {{ data.title }}
                </div>

            </v-card-title>
            <v-card-text 
                class="px-0 pt-1"
                v-if="data.short_description">
                <div class="tw-font-body">
                    {{ data.short_description }}
                </div>
            </v-card-text>
        </v-card-item>
    </v-card>
</template>

<script setup>
    import dayjs from 'dayjs'
    import relativeTime from 'dayjs/plugin/relativeTime'

    dayjs.extend(relativeTime)
    
    const urlImage = window.urlImage
    const { data, label } = defineProps(['data', 'label'])

    function formatDate(date){
        const timeAgo = dayjs(date).fromNow();
        return timeAgo;
    }
</script>
