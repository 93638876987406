import { defineStore } from 'pinia'
import api from '../api'

export const useNavigationStore = defineStore('navigation', {
    state: () => ({
        navbar: [],
        navbar2: [],
        sidebar: [],
        footer: [],
    }),

    actions: {
        async getNavbar(){
            try{
                const response = await api.get('navigation/navbar')
                this.navbar = response.data.data
            } catch(err){
                //
            }
        },
        async getNavbar2(){
            try{
                const response = await api.get('navigation/navbar2')
                this.navbar2 = response.data.data
            } catch(err){
                //
            }
        },
        async getSidebar(){
            try {
                const response = await api.get('navigation/sidebar')
                this.sidebar = response.data.data
            } catch (err) {
                //
            }
        },
        async getFooter(){
            try {
                const response = await api.get('navigation/footer')
                this.footer = response.data.data
            } catch (err) {
                //
            }
        }
    },
})