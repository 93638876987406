<template>
    <div class="my-auto d-flex h-100">
        <v-container class="my-auto">
            <div class="text-center">
                <h1 class="text-h1 font-weight-bold">404</h1>
                <div>Sorry, Page Not Found</div>
                <v-btn 
                    class="mt-5"
                    :to="'/'">
                    Go Home
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script setup>
    document.title = "404"
</script>