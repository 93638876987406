<template>
    <v-footer class="pa-0">
        <v-card
            color="primary"
            flat
            tile
            class="w-100 "
            :style="{
                backgroundImage: 'url(' + baseUrlImage + 'background/footer-corner.png)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right bottom'
            }">
            <div class="py-10 container-max-1600">
                <v-row>
                    <v-col 
                        md="5"
                        lg="4"
                        cols="12">
                        <div>
                            <img 
                                class="logo-footer"
                                :src="urlImage + config.image">
                        </div>
                        <div class="mt-7 tw-font-body">
                            <table>
                                <tr 
                                    v-for="(contact, c) in config.contact"
                                    :key="'contact' + c"
                                    valign="top">
                                    <td>
                                        <v-icon>{{ contact.icon }}</v-icon>
                                    </td>
                                    <td class="pl-2 pb-2">
                                        <a 
                                            target="blank"
                                            :href="contact.url">
                                            <div v-html="contact.text"></div>
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div 
                            class="mt-5"
                            v-if="!$vuetify.display.mdAndDown">
                            <div 
                                v-if="config.link"
                                class="d-flex">
                                <div 
                                    v-for="(sosmed, s) in config.link"
                                    :key="'sosmed' + s"
                                    class="mr-3">
                                    <a 
                                        :href="sosmed.url"
                                        target="_blank">
                                        <img 
                                            :src="urlIcon + sosmed.type + '.png'"
                                            :alt="sosmed.type"
                                            class="z-icon-sosmed">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col 
                        md="7"
                        lg="8"
                        cols="12">
                        <v-row>
                            <v-col 
                                md="4"
                                lg="4"
                                cols="6" 
                                v-for="(nav, m) in footer" 
                                :key="m">
                                <div :class="'tw-font-heading tw-font-bold tw-uppercase ' + ($vuetify.display.smAndDown ? 'mb-2' : 'mb-7')">
                                    {{ nav.name }}
                                </div>
                                <ul class="tw-list-none tw-text-sm">
                                    <li 
                                        v-for="(child, c) in nav.children"
                                        :key="c"
                                        class="tw-my-2">
                                        <a 
                                            class="hover:tw-text-yellow"
                                            :href="child.url">
                                            {{ child.name }}
                                        </a>
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row v-if="$vuetify.display.mdAndDown">
                    <v-col 
                        cols="12"
                        class="d-flex justify-center">
                        <div 
                            v-if="config.link"
                            class="d-flex">
                            <div 
                                v-for="(sosmed, s) in config.link"
                                :key="'sosmed' + s"
                                class="mx-3">
                                <a 
                                    :href="sosmed.url"
                                    target="_blank">
                                    <img 
                                        :src="urlIcon + sosmed.type + '.png'"
                                        :alt="sosmed.type"
                                        class="z-icon-sosmed">
                                </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider></v-divider>
            
            <v-card-text class="text-white text-center">
                {{ new Date().getFullYear() }} — © {{ config.title }}. Design by <a class="text-white" href="https://alkemistri.com">Alkemistri.com</a>
            </v-card-text>
        </v-card>
    </v-footer>

</template>

<script setup>
    import { ref } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useNavigationStore } from '../store/navigation'
    import { useConfigStore } from '../store/config'
    import { useDisplay } from 'vuetify'

    const useNavigation = useNavigationStore()
    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)
    const { footer } = storeToRefs(useNavigation)
    const urlImage = window.urlImage
    const baseUrlImage = window.baseUrlImage
    const urlIcon = window.urlIcon

    function checkLik(link){
        return link.startsWith("http://") || link.startsWith("https://");
    }
</script>

<style scoped>
.v-list-item--link:before {
    background-color: transparent;
}
.v-list-group .v-list-item--active {
    color: #fff;
}
</style>