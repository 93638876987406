<template>
    <div v-if="!loadingProductRecomendation">
        <v-dialog
            v-model="dialog"
            width="1000">
            <v-card>
                <div class="tw-p-3 tw-flex">
                    <div class="tw-text-md">
                        <v-icon size="small" color="green">mdi-check</v-icon>
                        Successfully Added to Cart!
                    </div>
                    <v-spacer />
                    <div>
                        <v-icon @click="close">mdi-close</v-icon>
                    </div>
                </div>
                <div class="tw-bg-grey-100 tw-p-4">
                    <div class="tw-flex tw-flex-col md:tw-flex-row">
                        <div class="tw-flex">
                           <div 
                                class="tw-w-16 tw-mr-3"
                                v-if="data.image">
                                <img
                                    class="tw-w-full" 
                                    :src="data.image"
                                    :alt="data.variant">
                            </div>
                            <div>
                                <div class="tw-text-sm md:tw-text-md">
                                    <b>{{ data.title }}</b>
                                </div>
                                <div 
                                    class="tw-text-sm" 
                                    v-if="data.variant && (data.variant != 'Default Title')">
                                    {{ data.variant }}
                                </div>
                            </div>
                        </div>
                        <v-spacer />
                        <div class="">
                            <div>
                                <v-btn
                                    block
                                    flat
                                    @click="close" 
                                    class="text-secondary">
                                    CONTINUE SHOPPING
                                </v-btn>
                            </div>
                            <div class="tw-ml-auto tw-mt-2">
                                <v-btn 
                                    block 
                                    variant="outlined"
                                    @click="openCart">
                                    View Cart
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="tw-mt-7 tw-mx-5" 
                    v-if="productRecomendation">
                    <div class="tw-text-center tw-font-bold tw-text-2xl tw-mb-3">
                        {{ productRecomendation.title }}
                    </div>
                    <div>
                        <zCarouselProductSm2 :data="productRecomendation.products">

                        </zCarouselProductSm2>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
    import { watch } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useCartStore } from '@/store/cart'
    import zCarouselProductSm2 from '@/components/zCarouselProductSm2.vue'

    const useCart = useCartStore()
    const { data, dialog, productRecomendation, loadingProductRecomendation } = storeToRefs(useCart)

    watch(dialog, () => {
        if(dialog.value){
            useCart.getProductRecomendation(data.value.productId)
        }
    })


    function close() {
        useCartStore().closeDialogCart()
    }

    function openCart() {
        useCartStore().openCart()
    }

</script>