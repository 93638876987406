<template>
    <div class="pt-md-5 pt-lg-10 pb-md-5 pb-lg-10">
        <div 
            class="v-container"
            v-if="order">

            <div :class="order.resi ? 'tw-grid tw-grid-cols-1 lg:tw-grid-cols-4 tw-gap-0 lg:tw-gap-4' : 'tw-grid tw-grid-cols-1 lg:tw-grid-cols-1 tw-gap-0 lg:tw-gap-1'">
                <div class="tw-col-span-3 ">
                    <div 
                        class="tw-border-2 tw-border-grey-800 tw-my-4 tw-w-full">
                        <div class="tw-flex tw-w-full tw-py-1 tw-px-3 tw-bg-grey-100">
                            <div class="">
                                <b>{{ order.invoice }}</b>
                            </div>
                            <v-spacer/>
                            <div class="tw-text-right">
                                <div>
                                    <b>{{ order.status }}</b>
                                </div>
                                <div>
                                    <b>ORDER DATE {{ dayjs(order.created_at).format('DD/MM/YYYY') }}</b> 
                                </div>
                            </div>
                        </div>
                        <div 
                            class="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-py-3 tw-px-3"
                            v-for="product in order.products"
                            :key="product.id">
                            <div class="tw-flex tw-col-span-2">
                                <div class="tw-w-[120px] tw-mr-2">
                                    <v-img 
                                        :src="urlImage + product.image"
                                        height="100">
                                    </v-img>
                                </div>
                                <div class="w-100">
                                    <div>
                                        <b>{{ product.title }}</b>
                                    </div>
                                    <div 
                                        v-if="product.variant"
                                        class="tw-text-sm">
                                        Product Option: {{ product.variant }}
                                    </div>
                                    <div 
                                        v-if="product.sku"
                                        class="tw-text-sm">
                                        SKU: {{ product.sku }}
                                    </div>
                                </div>
                            </div>
                            <div class="tw-text-right">
                                <div>
                                    <b>{{ product.quantity }}x</b>
                                </div>
                                <div>
                                    <b>Rp {{ formatCurrency(product.price) }}</b>
                                </div>
                                <div
                                    v-if="product.normal_price" 
                                    class="tw-line-through">
                                    Rp {{ formatCurrency(product.normal_price) }}
                                </div>
                            </div>
                            <div class="tw-text-right">
                                <b>Rp {{ formatCurrency(product.total) }}</b>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                            <div>
                                <div class="tw-border-2 tw-border-grey-800 tw-mb-4 tw-mt-2">
                                    <!-- <div class="tw-py-2 tw-px-3 tw-bg-grey-100">
                                        <b>Estimated Arrival</b>
                                    </div>
                                    <div>
                                        s
                                    </div> -->
                                    <div class="tw-py-2 tw-px-3 tw-bg-grey-100">
                                        <b>Deliver To</b>
                                    </div>
                                    <div
                                        v-if="order.shipping_address" 
                                        class="tw-p-4">
                                        <div 
                                            class="tw-mb-2"
                                            v-if="order.shipping_address.name">
                                            {{ order.shipping_address.name }}
                                        </div>
                                        <div>
                                            <div>
                                                {{ order.shipping_address.address1 }}
                                            </div>
                                            <div v-if="order.shipping_address.address2">
                                                {{ order.shipping_address.address2 }}
                                            </div>
                                        </div>
                                        <div>
                                            {{ order.shipping_address.district }} <br>
                                            {{ order.shipping_address.city }} <br>
                                            {{ order.shipping_address.province }} {{ order.shipping_address.zip }}<br>
                                            {{ order.shipping_address.country }} <br>
                                        </div>
                                        <div 
                                            class="tw-pt-2"
                                            v-if="order.shipping_address.phone">
                                            {{ order.shipping_address.phone }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="tw-flex tw-grow tw-w-full tw-px-3 tw-py-1">
                                    <div>
                                        Subtotal
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        Rp {{ formatCurrency(order.products.reduce((total, product) => {
                                                return total + parseInt(product.price);
                                            }, 0)) }}
                                    </div>
                                </div>
                                <div class="tw-flex tw-grow tw-w-full tw-px-3 tw-py-1">
                                    <div>
                                        Shipping {{ order.shipping_method }} 
                                        <span v-if="order.shipping_code">( {{ order.shipping_code }} )</span>
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        Rp {{ formatCurrency(order.shipping_cost) }}
                                    </div>
                                </div>
                                <div
                                    v-if="order.shipping_insurance > 0" 
                                    class="tw-flex tw-grow tw-w-full tw-px-3 tw-py-1">
                                    <div>
                                        Shipping insurance: 
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        Rp {{ formatCurrency(order.shipping_insurance) }}
                                    </div>
                                </div>
                                <div 
                                    class="tw-flex tw-grow tw-w-full tw-px-3 tw-py-1"
                                    v-if="order.coupon">
                                    <div>
                                        Voucher {{ order.coupon }}
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        Rp {{ formatCurrency(order.coupon_amount) }}
                                    </div>
                                </div>
                                <div class="tw-flex tw-grow tw-w-full tw-px-3">
                                    <div>
                                        Total
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        Rp {{ formatCurrency(order.total) }}
                                    </div>
                                </div>
                                <div class="tw-flex tw-grow tw-w-full tw-px-3 tw-py-1">
                                    <div>
                                        Payment Method
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        class="tw-text-lg tw-font-bold">
                                        {{ order.payment_method }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                
                <div
                    v-if="order.resi" 
                    class="tw-w-">
                    <div class="tw-border-2 tw-border-grey-800 tw-mb-4 tw-mt-4">
                        <div class="tw-py-2 tw-px-3 tw-bg-grey-100 tw-text-center">
                            <div>
                                <b>Tracking Number:</b>
                                <div>
                                    {{ order.resi }}
                                </div>
                            </div>
                            <div 
                                class="mt-3"
                                v-if="order.live_tracking_url">
                                <b>Live Tracking:</b>
                                <div>
                                    <a
                                        class="text-blue"
                                        :href="order.live_tracking_url" 
                                        target="_blank">
                                        {{ order.live_tracking_url }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div 
                                class="text-center my-4"
                                v-if="loadingTrack">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate>
                                </v-progress-circular>
                            </div>
                            <div v-else>
                                <div 
                                    v-if="track"
                                    class="tw-max-h-[32rem] tw-overflow-auto">
                                    <v-timeline 
                                        align="start" 
                                        side="end"
                                        v-if="track.manifest.length > 0">
                                        <v-timeline-item
                                            dot-color="secondary"
                                            size="small"
                                            v-for="(track, t) in track.manifest"
                                            :key="'track' + t">
                                            <div>
                                                <strong>
                                                    {{ track.manifest_description }}
                                                </strong>
                                                <div>
                                                    <div>{{ track.manifest_time }}</div>
                                                    <div>{{ track.manifest_date }}</div>
                                                </div>
                                            </div>
                                        </v-timeline-item>
                                    </v-timeline>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import api from '@/api'
    import dayjs from 'dayjs'
    import { useRoute } from 'vue-router'

    const urlImage = window.urlImage

    const order = ref('')
    const loading = ref(false)
    const loadingTrack = ref(false)

    const track = ref('')
    
    const route = useRoute()
    const id = route.params.id

    getOrders()
    async function getOrders(){
        await api.get('user/orders/' + id)
        .then((resp) => {
            order.value = resp.data.data

            if(resp.data.data.resi){
                getTrack(resp.data.data.id)
            }
        })
        .then(() => {
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }

    async function getTrack(orderid){
        loadingTrack.value = true
        await api.get('shipping/track/' + orderid)
        .then((resp) => {
            track.value = resp.data

            loadingTrack.value = false
        })
        .catch((resp) => {
            loadingTrack.value = false
        })
    }

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>