<template>
    <div 
        v-if="data"
        class="tw-mb-20">
        <div v-if="!data.submit_review">
            <div>
                <div 
                    class="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 tw-py-3 tw-px-3 tw-bg-grey-100">
                    <div class="tw-flex">
                        <div class="tw-w-[120px] tw-mr-2">
                            <v-img 
                                :src="urlImage + data.image"
                                height="100">
                            </v-img>
                        </div>
                        <div>
                            <div>
                                <b>{{ data.title }}</b>
                            </div>
                            <div 
                                v-if="data.variant"
                                class="tw-text-sm ">
                                {{ data.variant }}
                            </div>
                        </div>
                    </div>
                    <div class="tw-text-center tw-col-span-2">
                        <div>
                            <b>Overall Rating</b>
                        </div>
                        <v-rating
                            v-model="rating">
                        </v-rating>
                        <div
                            v-if="getValidateError('rating')" 
                            class="text-caption text-red">
                            {{ getValidateError('rating') }}
                        </div>
                    </div>
                </div>

                <div class="tw-mt-5">
                    <div>
                        <div>
                            <b>What do you think about this product?</b>
                        </div>
                        <div class="tw-mt-2 tw-flex ">
                            <div class="tw-w-20">
                                Material
                            </div>
                            <v-rating
                                v-model="ratingMaterial"
                                :size="30">
                            </v-rating>
                        </div>
                        
                        <div class="tw-mt-2 tw-flex">
                            <div class="tw-w-20">
                                Quality
                            </div>
                            <v-rating
                                v-model="ratingQuality"
                                :size="30">
                            </v-rating>
                        </div>
                        
                        <div class="tw-mt-2 tw-flex">
                            <div class="tw-w-20">
                                Design
                            </div>
                            <v-rating
                                v-model="ratingDesign"
                                :size="30">
                            </v-rating>
                        </div>
                        
                        <div class="tw-mt-2 tw-flex">
                            <div class="tw-w-20">
                                Value
                            </div>
                            <v-rating
                                v-model="ratingValue"
                                :size="30">
                            </v-rating>
                        </div>
                    </div>

                    <div>
                        <v-checkbox
                            v-model="recommend" 
                            label="I recommended this product"
                            hide-details>
                        </v-checkbox>
                    </div>

                    <div>
                        <v-img
                            v-if="imagePriview"
                            :src="imagePriview"
                            width="100">
                            <v-icon
                                class="btn-delete-image !tw-absolute !tw-bottom-3 !tw-right-3" 
                                color="red"
                                @click="deleteImage">
                                mdi-delete
                            </v-icon>
                        </v-img>
                        <div v-if="!imagePriview">
                            <v-btn 
                                outlined 
                                small 
                                @click="addImage"
                                variant="outlined">
                                Add Photo
                            </v-btn>
                            <input 
                                ref="fileInputImage" 
                                type="file" 
                                style="display: none" 
                                @input="pickFileImage"
                                accept=".jpeg,.jpg,.png">
                        </div>

                        <div
                            v-if="getValidateError('image')" 
                            class="text-caption text-red">
                            {{ getValidateError('image') }}
                        </div>
                    </div>
                    
                    <div class="my-4">
                        <div
                            v-if="videoPriview" 
                            class="tw-relative tw-w-[260px]">
                            <video
                                width="260"
                                controls
                                class="rounded-lg">
                                <source 
                                    :src="videoPriview" 
                                    type="video/mp4">
                            </video>
                            <v-icon
                                class="btn-delete-image !tw-absolute !tw-bottom-3 !tw-right-3" 
                                color="red"
                                @click="deleteVideo">
                                mdi-delete
                            </v-icon>
                        </div>
                        <div v-if="!videoPriview">
                            <v-btn 
                                outlined 
                                small 
                                @click="addVideo"
                                variant="outlined">
                                Add Video
                            </v-btn>
                            <input 
                                ref="fileInputVideo" 
                                type="file" 
                                style="display: none" 
                                @input="pickFileVideo"
                                accept=".mp4">
                        </div>
                        <div
                            v-if="getValidateError('video')" 
                            class="text-caption text-red">
                            {{ getValidateError('video') }}
                        </div>
                    </div>

                    <div class="tw-mt-4 tw-w-80">
                        <v-text-field 
                            density="compact"
                            variant="outlined"
                            label="Add a headline"
                            placeholder="e.g: This product is awesome!"
                            v-model="title"
                            :class="getValidateError('title') ? 'mb-2':''"
                            :error-messages="getValidateError('title')">
                        </v-text-field>
                        <v-textarea 
                            density="compact"
                            variant="outlined"
                            label="Tell us more"
                            placeholder="e.g: Really help me through the day!"
                            v-model="message"
                            :class="getValidateError('message') ? 'mb-2':''"
                            :error-messages="getValidateError('message')">
                        </v-textarea>

                        <v-btn
                            :loading="loading"
                            @click="save">
                            SUBMIT REVIEW
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useAlertStore } from '@/store/alert'
    
    const urlImage = window.urlImage
    
    const alertStore = useAlertStore()
    
    const loading = ref(false)

    const { data } = defineProps(['data'])

    const emit = defineEmits(['reload'])

    const title = ref('')
    const message = ref('')
    const image = ref('')
    const video = ref('')
    const rating = ref(0)
    const ratingMaterial = ref(0)
    const ratingQuality = ref(0)
    const ratingDesign = ref(0)
    const ratingValue = ref(0)
    const recommend = ref('')

    const msgValidate = ref('')

    const fileInputImage = ref('')
    const imagePriview = ref('')

    const fileInputVideo = ref('')
    const videoPriview = ref('')

    const success = ref('')

    function addImage(){
        fileInputImage.value.click()
    }

    function addVideo(){
        fileInputVideo.value.click()
    }

    function deleteImage(){
        fileInputImage.value = ''
        imagePriview.value = ''
        image.value = ''
    }

    function deleteVideo(){
        fileInputVideo.value = ''
        videoPriview.value = ''
        video.value = ''
    }
    
    function pickFileImage(){
        const file = fileInputImage.value.files[0]

        const fileSize = file.size / 1024 / 1024;
        if(fileSize > 1){
          return alert('File size exceeds 1 MB')
        }

        const reg = /(.*?)\.(jpg|jpeg|png)$/;
        if(!file.name.match(reg)){
            return alert('Invalid file')
        }

        imagePriview.value = URL.createObjectURL(file)

        image.value = file
    }

    function pickFileVideo(){
        const file = fileInputVideo.value.files[0]

        const fileSize = file.size / 10240 / 10240;
        if(fileSize > 1){
          return alert('File size exceeds 10 MB')
        }

        const reg = /(.*?)\.(mp4)$/;
        if(!file.name.match(reg)){
            return alert('Invalid file')
        }

        videoPriview.value = URL.createObjectURL(file)

        video.value = file
    }

    async function save(){
        msgValidate.value = ''
        success.value = ''
        loading.value = true

        const formData = new FormData()
        formData.append('product_id', data.product_id)
        formData.append('order_product_id', data.id)
        formData.append('title', title.value)
        formData.append('message', message.value)
        formData.append('rating', rating.value)
        formData.append('rating_material', ratingMaterial.value)
        formData.append('rating_quality', ratingQuality.value)
        formData.append('rating_design', ratingDesign.value)
        formData.append('rating_value', ratingValue.value)
        formData.append('recommend', recommend.value)
        formData.append('image', image.value)
        formData.append('video', video.value)

        await api.post('reviews', formData, {
            headers: {
                'Content-Type': 'multipart/form-data', 
            }
        })
        .then((resp) => {
            loading.value = false
            alertStore.action(true, 'success', 'Success')
            success.value = 'success'
            window.scrollTo({ top: 0, behavior: 'smooth' })
            emit('reload')
        })
        .catch((err) => {
            loading.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            }

            alertStore.action(true, 'error', err.response.data.message)
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }
</script>