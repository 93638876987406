<template>
    <div class="xl:tw-px-[5em] 2xl:tw-px-[13em]">
        <div 
            v-for="(component, c) in data"
            :key="'component' + c">
            <div class="tw-grid md:tw-grid-cols-2">
                <div class="tw-text-center md:tw-border-r-2 md:tw-border-grey-800 md:tw-px-7 lg:tw-px-14 tw-py-5">
                    <div class="tw-mb-7">
                        <template
                            v-for="(content, c) in component.contents"
                            :key="'contentc' + c">
                            <div 
                                v-if="content.type == 'singleLineText'"
                                class="tw-font-heading tw-font-bold tw-text-2xl">
                                <div   
                                    v-for="(item, c) in content.items"
                                    :key="'itemmsingleLineText' + c"
                                    :class="item.class_css">
                                    {{ item.value }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <template
                        v-for="(content, c) in component.contents"
                        :key="'contentcc' + c">
                        <template
                            v-if="content.type == 'image'">
                            <div :class="'tw-grid tw-gap-4 tw-grid-cols-5 md:tw-px-5'">
                                <template
                                    v-for="(item, c) in content.items"
                                    :key="'image' + c">
                                    <div
                                        class="tw-border tw-border-grey-800" 
                                        v-if="item.value">
                                        <img 
                                            :src="urlImage + item.value"
                                            :alt="item.heading"
                                            :class="item.class_css + ' tw-w-full'">
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="md:tw-px-7 lg:tw-px-14 tw-py-5">
                    <template
                        v-for="(content, c) in component.contents"
                        :key="'contentt' + c">
                        <div 
                            v-if="content.type == 'multiLineText'"
                            class="tw-font-heading tw-font-bold tw-text-2xl tw-mb-2">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmmultiLineText' + c"
                                :class="item.class_css">
                                {{ item.value }}
                            </div>
                        </div>
                        <div 
                            v-if="content.type == 'richText'"
                            class="tw-font-body">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'itemmrichText' + c"
                                :class="item.class_css">
                                <div
                                    class="z-text" 
                                    v-html="item.value">
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const urlImage = window.urlImage
    const { data } = defineProps(['data'])
</script>