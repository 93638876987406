<template>
    <div class="pt-md-5 pt-lg-10 pb-md-5 pb-lg-10">
        
        <div
            v-if="loading" 
            class="v-container text-center">
            <v-progress-circular
                color="primary"
                indeterminate>
            </v-progress-circular>
        </div>

        <div
            v-else 
            class="v-container">
            <div  
                class="title text-center mb-5">
                <h1 class="z-title">
                    WISHLIST
                </h1>
            </div>

            <div>
                <div
                    v-if="wishlists.data.length > 0">
                    <v-card 
                        variant="outlined" 
                        class="mb-5 px-4 py-4"
                        v-for="product in wishlists.data"
                        :key="product.id">
                        <Product :data="product" />
                    </v-card>

                    <v-pagination
                        v-if="wishlists.meta"
                        v-model="page"
                        :length="wishlists.meta.last_page"
                        :total-visible="5"
                        class="my-4">
                    </v-pagination>
                </div>
                <div 
                    v-else
                    class="text-center">
                    There are no items in your wishlist
                </div>
            </div>

            <div class="tw-text-center tw-mt-10">
                <v-btn
                    to="/user/account" 
                    variant="outlined">
                    Back to My Account
                </v-btn>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { ref, watch } from 'vue'
    import api from '@/api'
    import Product from './Product'
   
    const wishlists = ref([])
    const loading = ref(false)

    const page = ref(1)

    watch(page, (val) => {
        if(val){
            getWishlist()
        }
    })

    getWishlist()
    async function getWishlist(){
        loading.value = true
        await api.get('wishlists?limit=10&page=' + page.value)
        .then((resp) => {
            wishlists.value = resp.data
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }
</script>