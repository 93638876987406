import { defineStore } from 'pinia'
import api from '../api'

export const useConfigStore = defineStore('config', {
    state: () => ({
        config: {},
        loading: false
    }),

    actions: {
        async getConfig(){
            this.loading = true
            await api.get('config')
            .then((resp) => {
                this.config = resp.data.data
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
            })
        }
    },
})