<template>
    <div 
        v-if="!loadingPage">
        <section class="my-4">
            <v-container>
                <v-row>
                    <v-col 
                        cols="12"
                        md="8">
                        <div>
                            <ZimageGallery 
                                :images="product.gallery">
                            </ZimageGallery>
                        </div>
                        
                    </v-col>
                    <v-col 
                        cols="12"
                        md="4">

                        <div :style="'min-height: ' + heightImageShow">
                            <div>
                                <div 
                                    v-if="promotion_price"
                                    class="tw-font-heading tw-flex tw-justify-center tw-mb-5 tw-bg-red tw-text-white tw-text-lg tw-w-full tw-px-7 tw-py-2 tw-text-center tw-font-bold tw-mx-auto">
                                    {{ promotion_type == 'flashsale' ? 'FLASH SALE' : promotion_type.toUpperCase() }} END IN 
                                    <zCountdown
                                        class="tw-ml-4" 
                                        :data="{
                                        start_date: start_date,
                                        end_date: end_date,
                                    }" />
                                </div>
                                <div class="tw-flex">
                                    <div class="w-100">
                                        <h1 class="tw-text-2xl tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-mb-4">
                                            {{ product.title }}
                                        </h1>
                                    </div>
                                    <v-spacer/>
                                    <div 
                                        v-if="special_price"
                                        class="tw-font-heading tw-w-[100px] tw-ml-2 tw-bg-yellow tw-leading-3 tw-flex tw-flex-col tw-size-16 tw-justify-center tw-items-center tw-font-bold">
                                        <div class="tw-text-2xl tw-flex">
                                            <span>{{ price_percent }}</span><span class="tw-text-sm">%</span>
                                        </div>
                                        <div class="tw-text-sm -tw-mt-2">OFF</div>
                                    </div>
                                </div>

                                <div class="tw-font-heading">
                                    <div
                                        v-if="special_price" 
                                        class="tw-flex tw-justify-start">
                                        <div class="text-red tw-flex tw-mr-2">
                                            <span class="tw-text-xs">Rp</span> <span class="tw-font-bold tw-text-2xl">{{ formatCurrency(special_price) }}</span>
                                        </div>
                                        <div class="tw-flex">
                                            <span class="tw-text-xs">Rp</span> <span class="tw-font-bold tw-text-2xl tw-line-through">{{ formatCurrency(price) }}</span>
                                        </div>
                                    </div>
                                    <div
                                        v-else 
                                        class="tw-flex tw-justify-start">
                                        <div class="tw-flex">
                                            <span class="tw-text-xs">Rp</span> <span class="tw-font-bold tw-text-2xl">{{ formatCurrency(price) }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="product.custom_field" class="my-4 tw-font-heading">
                                    <div v-if="product.custom_field.length > 0">
                                        <div 
                                            class=""
                                            v-for="(custom_field, m) in product.custom_field"
                                            :key="'custom_field' + m">
                                            <template v-if="custom_field.name != 'Specification'">
                                                <div 
                                                    v-for="(field, f) in custom_field.field"
                                                    :key="'field' + f">
                                                    <div 
                                                        v-if="field.value">
                                                        <v-divider></v-divider>
                                                        <div class="py-1">
                                                            <b>{{ field.label }}</b>: {{ field.value }}
                                                        </div>
                                                        <v-divider v-if="m+1 == product.custom_field.length"></v-divider>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {{ product.short_description }}
                                </div>

                                <div>
                                    {{ sku }}
                                </div>

                                <div v-if="product.options && (product.options.length > 0)">
                                    <div 
                                        v-for="option in product.options"
                                        :key="option.id">
                                        <div class="mb-2">
                                            <b>{{ option.name }}</b>
                                        </div>
                                        <div v-if="option.name == 'color'">
                                            <template
                                                v-for="(val, v) in option.values"
                                                :key="'val' + v">
                                                <v-btn
                                                    v-if="getColorCode(val.name)"
                                                    @click="changeVariant(option.position, val.name)"
                                                    class="mr-3 mb-3 btn-variant-color"
                                                    variant="flat"
                                                    rounded
                                                    :color="getColorCode(val.name)">
                                                    <v-icon 
                                                        v-if="varianOption1 === val.name || varianOption2 === val.name || varianOption3 === val.name">
                                                        mdi-check
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    v-else
                                                    @click="changeVariant(option.position, val.name)"
                                                    variant="outlined"
                                                    :active="varianOption1 === val.name || varianOption2 === val.name || varianOption3 === val.name"
                                                    class="mr-3 mb-3">
                                                    {{ val.name }}
                                                </v-btn>
                                            </template>
                                        </div>
                                        <div v-else>
                                            <template
                                                v-for="(val, v) in option.values"
                                                :key="'val' + v">
                                                <v-btn
                                                    @click="changeVariant(option.position, val.name)"
                                                    variant="outlined"
                                                    :active="varianOption1 === val.name || varianOption2 === val.name || varianOption3 === val.name"
                                                    class="mr-3 mb-3">
                                                    <v-icon 
                                                        class="mr-1"
                                                        v-if="varianOption1 === val.name || varianOption2 === val.name || varianOption3 === val.name">
                                                        mdi-check
                                                    </v-icon>
                                                    {{ val.name }}
                                                </v-btn>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-qty mt-5">
                                    <div class="icon">
                                        <v-icon 
                                            class="icon-minus"
                                            size="small"
                                            @click="updateQty('min')">
                                            mdi-minus
                                        </v-icon>
                                    </div>
                                    <input 
                                        @change="handleQuantityChange"
                                        :value="carts.filter(item => item.product_id == productId)[0] ? carts.filter(item => item.product_id == productId)[0].quantity : 0"
                                        type="number">
                                    <div class="icon">
                                        <v-icon 
                                            class="icon-plus"
                                            size="small"
                                            @click="updateQty('plus')">
                                            mdi-plus
                                        </v-icon>
                                    </div>
                                </div>

                                <div class="mt-5">
                                    <b>Availability:</b> {{ quantity > 0 ? 'In Stock':'Out of Stock' }}
                                </div>

                                <div 
                                    class="my-5 d-flex align-center">
                                    <div class="w-100 mr-10">
                                        <v-btn 
                                            @click="addToCart"
                                            size="x-large"
                                            rounded="0"
                                            block
                                            class="text-secondary text-uppercase">
                                            Add to cart
                                        </v-btn>
                                    </div>
                                    <div>
                                        <zWishlist 
                                            :productid="productId"
                                            sizeicon="x-large"
                                            />
                                    </div>
                                </div>

                                <div
                                    class="mt-7" 
                                    v-if="product.share">
                                    <div 
                                        class="d-flex my-1">
                                        <div><b>SHARE THIS PRODUCT</b></div>
                                        <v-spacer/>
                                        <div>
                                            <v-icon @click="socialWindow(product.share.facebook)">
                                                mdi-facebook
                                            </v-icon>
                                            <v-icon
                                                class="mx-3" 
                                                @click="socialWindow(product.share.twitter)">
                                                mdi-twitter
                                            </v-icon>
                                            <v-icon @click="socialWindow(product.share.whatsapp)">
                                                mdi-whatsapp
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </v-col>
                </v-row>

                <div class="mt-10">
                    <v-tabs
                        class="px-0"
                        v-model="tab"
                        align-tabs="start"
                        color="primary">
                        <v-tab
                            value="DESCRIPTION">
                            <div class="tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                <div class="d-flex align-center">
                                    DESCRIPTION
                                </div>
                            </div>
                        </v-tab>
                        <template
                            v-if="product.custom_field">
                            <template
                                v-if="product.custom_field.length > 0">
                                <template
                                    v-for="(custom_field, m) in product.custom_field"
                                    :key="'custom_field' + m">
                                    <template
                                        v-if="custom_field.name == 'Specification'">
                                        <template
                                            v-if="custom_field.field[0]">
                                            <v-tab
                                                value="SPECIFICATION">
                                                <div class="tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                                    <div class="d-flex align-center">
                                                        SPECIFICATION
                                                    </div>
                                                </div>
                                            </v-tab>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        <v-tab
                            value="REVIEW">
                            <div class="tw-font-heading tw-font-bold tw-tracking-[0.3em]">
                                <div class="d-flex align-center">
                                    REVIEW
                                    <div v-if="product.rating > 0">
                                        <div 
                                            v-if="tab == 'REVIEW'"
                                            class="ml-3">
                                            <v-rating
                                                readonly
                                                :model-value="product.rating"
                                                class="btn-rating"
                                                color="yellow-darken-1"
                                                half-increments
                                                :size="26"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-window 
                        v-model="tab">
                        <v-tabs-window-item
                            class="mt-2"
                            value="DESCRIPTION">
                            <div 
                                class="tw-font-body" 
                                v-html="product.description"></div>
                        </v-tabs-window-item>
                        <template
                            v-if="product.custom_field">
                            <template
                                v-if="product.custom_field.length > 0">
                                <template
                                    v-for="(custom_field, m) in product.custom_field"
                                    :key="'custom_field' + m">
                                    <template
                                        v-if="custom_field.name == 'Specification'">
                                        <template
                                            v-if="custom_field.field[0]">
                                            <v-tabs-window-item
                                                value="SPECIFICATION">
                                                <div 
                                                    class="mt-2">
                                                    <div 
                                                        class="my-1 tw-font-body">
                                                        <div v-html="custom_field.field[0].value.replace(/\n/g, '<br>')"></div>
                                                    </div>
                                                </div>
                                            </v-tabs-window-item>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                        <v-tabs-window-item
                            value="REVIEW">
                            <Review :productid="product.id" />
                        </v-tabs-window-item>
                    </v-tabs-window>
                </div>
                
            </v-container>
        </section>

        <div class="tw-mt-10">
            <div
                class="tw-bg-grey-100 tw-py-16"
                v-for="relation in productRelations"
                :key="relation.id">
                <v-container>
                    <div class="tw-pb-10 tw-text-center">
                        <div class="tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-text-3xl tw-mb-2">
                            {{ relation.title }}
                        </div>
                        <div class="tw-w-1/2 mx-auto tw-font-body">
                            {{ relation.description }}
                        </div>
                    </div>
                    
                    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-3">
                        <div
                            v-for="product in relation.products"
                            :key="product.id">
                            <zProduct :data="product">
                            </zProduct>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
    </div>
    
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue'
    import ZimageGallery from '../components/zImageGallery'
    import { storeToRefs } from 'pinia'
    import { useConfigStore } from '../store/config'
    import { useRoute } from 'vue-router'
    import { useDisplay } from 'vuetify'
    import api from '@/api'
    import { useCartStore } from '@/store/cart'
    import zProduct from '../components/zProduct'
    import zCountdown from '@/components/zCountdown'
    import zWishlist from '@/components/zWishlist'
    import Review from '@/components/review/Index'

    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)

    const useCart = useCartStore()
    const { carts } = storeToRefs(useCart)

    const display = useDisplay()
    const heightImageShow = ref('67vh')

    function setHeightImageShow(){
        if(display.xs.value){
            heightImageShow.value = '30vh'
        } else if(display.sm.value){
            heightImageShow.value = '46vh'
        } else if(display.md.value){
            heightImageShow.value = '30vh'
        } else if(display.lg.value){
            heightImageShow.value = '67vh'
        } else if(display.xlAndUp.value){
            heightImageShow.value = '76vh'
        }
    }

    onMounted(() => {
        setHeightImageShow()
        window.addEventListener('resize', setHeightImageShow)

        window.scrollTo({ top: 0, behavior: 'smooth' });
    })

    const tab = ref('DESCRIPTION')
    const tabs = ref([
        'DESCRIPTION',
        'SPECIFICATION',
        'REVIEW'
    ])

    const loadingPage = ref(false)
    const product = ref({})

    const productId = ref('')
    const variantTitle = ref('')
    const price = ref('')
    const special_price = ref('')
    const price_percent = ref('')
    const sku = ref('')
    const image = ref('')
    const quantity = ref(0)
    const promotion_price = ref('')
    const promotion_type = ref('')
    const start_date = ref('')
    const end_date = ref('')
    const imageVariant = ref({})

    const newQuantity = ref(1)

    const varianOption1 = ref('')
    const varianOption2 = ref('')
    const varianOption3 = ref('')

    const cart = ref({})

    const productRelations = ref([])

    const route = useRoute()
    let slug = route.params.slug

    watch(() => route.params, (val) => {
        slug = route.params.slug
        getProduct()
    },
        {
            deep: true, 
            immediate: true,
        }
    )

    // getProduct()
    async function getProduct(){
        loadingPage.value = true
        const resProduct = await api.get('products/' + slug)
        const dataProduct = resProduct.data.data
        product.value = dataProduct
        loadingPage.value = false

        getProductRelation(dataProduct.id)


        let dataVariants = []

        if(dataProduct.variants){
            if(dataProduct.variants.length > 0){
                // dataVariants = data.variants.filter(variant => variant.quantity > 0)
                // dataVariants = data.variants.sort((a, b) => b.quantity - a.quantity)
                dataVariants = dataProduct.variants.sort((a, b) => {
                    if (a.quantity === 0 && b.quantity !== 0) {
                        return 1;
                    }
                    if (a.quantity !== 0 && b.quantity === 0) {
                        return -1;
                    }
                    return b.quantity - a.quantity;
                })
            }
        }

        if(route.query.variant){
            dataVariants = dataProduct.variants.filter(filter => filter.id == route.query.variant)
        }

        if(dataVariants.length > 0){
            const variant = dataVariants[0]
            let dataImage = ''
            if(variant.image){
                dataImage = variant.image.image
            } else if (dataProduct.gallery) {
                if(dataProduct.gallery.length > 0) {
                    dataImage = dataProduct.gallery[0].image
                } else {
                    dataImage = ''
                }
            } else {
                dataImage = ''
            }

            productId.value = variant.id
            variantTitle.value = variant.title
            image.value = dataImage
            price.value = variant.price
            special_price.value = variant.special_price
            price_percent.value = variant.price_percent
            quantity.value = variant.quantity   
            sku.value = variant.sku
            promotion_price.value = variant.promotion_price
            promotion_type.value = variant.promotion_type
            start_date.value = variant.start_date
            end_date.value = variant.end_date

            getCart(variant.id)
        }

        document.title = dataProduct.title
        const metaTagTitle = document.querySelector('meta[name="title"]');
        if (metaTagTitle) {
            metaTagTitle.setAttribute('content', dataProduct.title);
        }
        const metaTagDesc = document.querySelector('meta[name="description"]');
        if (metaTagDesc) {
            if(dataProduct.description){
                metaTagDesc.setAttribute('content', dataProduct.description.substring(0,150));
            }
        }
    }

    onMounted(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    })

    function changeVariant(position, val){
        if(position == 1){
            varianOption1.value = val
        } else if(position == 2){
            varianOption2.value = val
        } else if(position == 3){
            varianOption3.value = val
        }

        let selectVariant = {}
        if(product.value.options.length == 1){
            selectVariant = product.value.variants.filter(varpro => 
                varpro.option1 === varianOption1.value
            );
        }
        if(product.value.options.length == 2){
            selectVariant = product.value.variants.filter(varpro => 
                varpro.option1 === varianOption1.value &&
                varpro.option2 === varianOption2.value
            );
        }
        if(product.value.options.length == 3){
            selectVariant = product.value.variants.filter(varpro => 
                varpro.option1 === varianOption1.value &&
                varpro.option2 === varianOption2.value &&
                varpro.option3 === varianOption3.value
            );
        }

        const dataSelectVariant = selectVariant[0]
        const dataProduct = product.value

        if(dataSelectVariant){
            let dataImage = ''

            if(dataSelectVariant.image){
                dataImage = dataSelectVariant.image.image
                imageVariant.value = dataSelectVariant.image
                dataProduct.gallery[0] = dataSelectVariant.image
            } else if (dataProduct.gallery) {
                if(dataProduct.gallery.length > 0) {
                    dataImage = dataProduct.gallery[0].image
                } else {
                    dataImage = ''
                }
            } else {
                dataImage = ''
            }

            productId.value = dataSelectVariant.id
            variantTitle.value = dataSelectVariant.title
            image.value = dataImage
            price.value = dataSelectVariant.price
            special_price.value = dataSelectVariant.special_price
            price_percent.value = dataSelectVariant.price_percent
            quantity.value = dataSelectVariant.quantity   
            sku.value = dataSelectVariant.sku
            promotion_price.value = dataSelectVariant.promotion_price
            promotion_type.value = dataSelectVariant.promotion_type
            start_date.value = dataSelectVariant.start_date
            end_date.value = dataSelectVariant.end_date

            getCart(dataSelectVariant.id)
        }
    }

    function getCart(productId) {
        cart.value = carts.value.filter(item => item.product_id == productId)[0]
    }

    function addToCart(){
        useCartStore().addToCart({
            productId: productId.value,
            qty: 1,
            image: image.value,
            title: product.value.title,
            variant: variantTitle.value
        })

        getCart(productId.value)
    }

    function updateQty(type) {
        const cart = carts.value.filter(item => item.product_id == productId.value)[0]

        if(cart){
            if(type == 'min'){
                if(cart.quantity - 1 == 0){
                    useCartStore().delete({
                        id: cart.id
                    })
                } else {
                    useCartStore().update({
                        id: cart.id,
                        product_id: cart.product_id,
                        qty: cart.quantity - 1
                    })
                } 
            }
            if(type == 'plus'){
                if(cart.max_quantity >= (cart.quantity + 1)){
                    useCartStore().update({
                        id: cart.id,
                        product_id: cart.product_id,
                        qty: cart.quantity + 1
                    })
                }
            }
        } else {
            useCartStore().addToCart({
                productId: productId.value,
                qty: 1,
                image: image.value,
                title: product.value.title,
                variant: variantTitle.value
            })
        }
    }

    function handleQuantityChange(event) {
        const val = event.target.value
        const cart = carts.value.filter(item => item.product_id == productId.value)[0]

        if(cart){
            useCartStore().update({
                id: cart.id,
                product_id: cart.product_id,
                qty: val
            })
        } else {
            useCartStore().addToCart({
                productId: productId.value,
                qty: val,
                image: image.value,
                title: product.value.title,
                variant: variantTitle.value
            })
        }

        getCart(productId.value)
    }

    async function getProductRelation(id){
        const resp = await api.get('product-relations/' + id + '?type=product')

        productRelations.value = resp.data.data
    }

    function socialWindow(url) {
        var left = (screen.width - 570) / 2;
        var top = (screen.height - 450) / 2;
        var params = 'menubar=no,toolbar=no,status=no,width=' + 570 + ',height=' + 450 + ',top=' + top + ',left=' + left;
        window.open(url ,'', params);
    }

    function getColorCode(color){
        const colorVariant = config.value.color_variant

        const codeColor = colorVariant.filter(item => item.name == color)

        if(codeColor[0]){
            return codeColor[0].code
        }
    }
</script>