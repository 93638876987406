<template>
    <div>

        <v-row>
            <v-col 
                cols="12"
                md="3">
                <div class="tw-capitalize"><b>{{ data.user }}</b></div>
                <div class="tw-text-xs">
                    {{ format(new Date(data.created_at), 'dd/MM/yy') }}
                </div>
            </v-col>
            <v-col 
                cols="12"
                md="9">
                <div>
                    <v-rating
                        readonly
                        :model-value="data.rating"
                        color="yellow-darken-1"
                        half-increments
                        :size="26"
                        class="btn-rating">
                    </v-rating>
                </div>
                <div class="tw-font-body">
                    <b>{{ data.title }}</b>
                </div>
                <div
                    class="tw-font-body tw-mt-2 tw-mb-4" 
                    style="white-space: pre-line;">
                    {{ data.message }}
                </div>
                <div class="tw-font-body tw-my-2">
                    <template v-if="data.recommend">
                        <v-icon>mdi-check-circle</v-icon> I recommended this product
                    </template>
                    <template v-else>
                        <v-icon>mdi-check-circle</v-icon> I do NOT recommended this product
                    </template>
                </div>
                <div>
                    <div class="d-flex mb-4">
                        <div class="mr-4">Helpful?</div>
                        <div class="mr-4">
                            <v-icon 
                                size="small"
                                @click="helpfulUp">
                                mdi-thumb-up-outline
                            </v-icon> 
                            {{ data.helpful_up }}
                        </div>
                        <div>
                            <v-icon 
                                size="small"
                                @click="helpfulDown">
                                mdi-thumb-down-outline
                            </v-icon>
                            {{ data.helpful_down }}
                        </div>
                    </div>
                </div>
                <div>
                    <v-img
                        v-if="data.image"
                        :src="data.image"
                        :alt="data.title"
                        width="260"
                        class="my-2">
                    </v-img>
                    <div>
                        <video
                            v-if="data.video" 
                            width="260"
                            controls>
                            <source 
                                :src="data.video" 
                                type="video/mp4">
                        </video>
                    </div>
                </div>
                <div 
                    v-if="data.replies.length > 0">
                    <div
                        v-for="reply in data.replies"
                        :key="reply.id"
                        class="tw-border tw-border-grey-800 tw-my-5">
                        <div class="tw-flex tw-bg-grey-800 tw-px-2 py-1">
                            <div>Response form Iga:</div>
                            <div class="tw-ml-auto tw-text-xs">
                                {{ format(new Date(reply.created_at), 'yyyy-MM-dd HH:mm') }}
                            </div>
                        </div>
                        <div class="tw-px-2 tw-py-1">
                            <div>
                                <b>{{ reply.user }}</b>
                            </div>
                            <div style="white-space: pre-line;">
                                {{ reply.message }}
                            </div>
                        </div>                    
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    
</template>

<script setup>
    import { format } from 'date-fns'
    import api from '@/api'

    const { data } = defineProps(['data'])

    const emit = defineEmits(['reload'])

    async function helpfulUp(){
        await api.post('reviews/helpful-up', {
            'id': data.id,
            'helpful_up': 1
        })
        .then((resp) => {
            data.helpful_up = resp.data.data.count
            emit('reload')
        })
        .catch((err) => {

        })
    }

    async function helpfulDown(){
        await api.post('reviews/helpful-down', {
            'id': data.id,
            'helpful_down': 1
        })
        .then((resp) => {
            data.helpful_down = resp.data.data.count
            emit('reload')
        })
        .catch((err) => {

        })
    }

</script>