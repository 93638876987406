<template>
    <div>
        <v-app-bar
            height="32"
            color="white" 
            outlined 
            flat 
            scroll-behavior="hide"
            v-if="config.announcement">
            <div 
                class="text-center w-100 py-1 tw-bg-blue tw-text-white"
                v-html="config.announcement">
            </div>
        </v-app-bar>

        <v-app-bar
            height="100"
            color="white" 
            outlined 
            flat 
            v-if="!$vuetify.display.mdAndDown"
            scroll-behavior="hide">
            <div 
                v-if="activeSearch"
                class="d-flex v-container w-100">
                <div class="z-form-search w-100">

                    <div 
                        class="z-box-search tw-relative">
                        <div class="form">
                            <div>
                                <input 
                                    size="1" 
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Search"
                                    focus
                                    v-model="search"
                                    @keyup.enter="enterSearch"/>
                            </div>
                            <div
                                style="cursor: pointer;" 
                                @click="enterSearch">
                                <v-icon color="grey">mdi-magnify</v-icon>
                            </div>
                        </div>

                        <div
                            v-if="dataSearch.length > 0" 
                            class="tw-absolute tw-z-10 tw-w-full tw-bg-white tw-shadow-lg tw-mt-2 tw-p-2 tw-border tw-border-grey-800">
                            <ul>
                                <li
                                    v-for="product in dataSearch"
                                    :key="product.id"
                                    class="hover:tw-bg-blue/25">
                                    <a 
                                        class="tw-block tw-px-4 tw-py-2"
                                        :href="product.link">
                                        <div class="tw-flex">
                                            <div
                                                v-if="product.image" 
                                                class="tw-w-[54px] tw-mr-3">
                                                <v-img
                                                    :src="product.image"
                                                    :alt="product.title"
                                                    width="54">
                                                </v-img>
                                            </div>
                                            <div>
                                                <div class="tw-font-semibold tw-font-heading tw-mb-1">
                                                    {{ product.title }}
                                                </div>
                                                <div v-if="product.price">
                                                    <div
                                                        v-if="product.special_price" 
                                                        class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-text-sm">
                                                        <div class="text-red tw-flex tw-mr-2">
                                                            Rp <span class="tw-font-heading tw-font-bold">{{ formatCurrency(product.special_price) }}</span>
                                                        </div>
                                                        <div class="tw-flex">
                                                            Rp <span class="tw-font-heading tw-font-bold tw-line-through">{{ formatCurrency(product.price) }}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else 
                                                        class="tw-flex tw-text-sm">
                                                        <div class="tw-flex">
                                                            Rp <span class="tw-font-heading tw-font-bold">{{ formatCurrency(product.price) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <v-icon 
                    class="mt-1 mr-2 ml-2"
                    @click="activeSearch = false">
                    mdi-close
                </v-icon>
            </div>
            <div
                v-else 
                class="container-max-1600 w-100 d-flex align-center py-0">
                <div 
                    class="navbar-logo mr-5">
                    <a href="/">
                        <v-img
                            :width="150"
                            :src="urlImage + config.image"
                            :alt="config.title"/>
                    </a>
                </div>
                
                <v-spacer></v-spacer>
                
                <div class="d-flex align-center">
                    <div 
                        v-for="(nav, m) in navbar"
                        :key="'nav' + m">
                        <v-menu
                            class="mega-menu"
                            v-if="nav.children.length > 0" 
                            open-on-hover
                            transition="slide-y-transition"
                            width="100vw">
                            <template v-slot:activator="{ props }">
                                <a 
                                    :href="nav.url"
                                    v-bind="props"
                                    :class="'tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-text-lg tw-uppercase menu-navbar dropdown ' + (props['aria-expanded'] == 'true' ? 'expanded ':' ' ) + checkUrlActive(nav.url)">
                                    <template v-if="nav.config">
                                        <template v-if="nav.config.color">
                                            <span :style="{color: nav.config.color}"> 
                                                <span v-if="nav.config.text">
                                                    {{ nav.config.text }}
                                                </span>
                                                {{ nav.name }}
                                            </span>
                                        </template>
                                        <template v-else>
                                            {{ nav.name }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{ nav.name }}
                                    </template>
                                </a>
                            </template>
                            <v-sheet
                                :elevation="0"
                                width="100vw">
                                <div class="tw-font-heading v-container w-100 py-3">
                                    <v-row class="justify-center">
                                        <v-col 
                                            md="2"
                                            v-for="(child, c) in nav.children"
                                            :key="'child' + c">
                                            <div class="mb-2">
                                                <b class="tw-font-heading">
                                                    <template v-if="child.config">
                                                        <template v-if="child.config.color">
                                                            <span :style="{color: child.config.color}"> 
                                                                <span v-if="child.config.text">
                                                                    {{ child.config.text }}
                                                                </span>
                                                                {{ child.name }}
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            {{ child.name }}
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ child.name }}
                                                    </template>
                                                </b>
                                            </div>
                                            <div
                                                v-for="(cl, l) in child.children"
                                                :key="'cl' + l"
                                                class="">
                                                <a 
                                                    :href="cl.url"
                                                    class="tw-font-heading"
                                                    style="text-decoration: none; font-size: 13px;">
                                                    <template v-if="cl.config">
                                                        <template v-if="cl.config.color">
                                                            <span :style="{color: cl.config.color}"> 
                                                                <span v-if="cl.config.text">
                                                                    {{ cl.config.text }}
                                                                </span>
                                                                {{ cl.name }}
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            {{ cl.name }}
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ cl.name }}
                                                    </template>
                                                </a>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-sheet>
                        </v-menu>
                        <a 
                            v-else
                            :href="nav.url"
                            :class="'text-subtitle-1 font-weight-bold text-primary menu-navbar ' + checkUrlActive(nav.url)">
                            {{ nav.name }}
                        </a>
                    </div>
                </div>
                
                <v-spacer></v-spacer>

                <gtranslate 
                    :languages="config.lang"
                    class="mx-5">
                </gtranslate>
                
                <div class="mr-5">
                    <v-menu
                        offset-y
                        v-if="user">
                        <template 
                            v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props">
                                mdi-account-outline
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item to="/user/account">
                                <v-list-item-title>
                                    My Account
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/user/order">
                                <v-list-item-title>
                                    Order History
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/user/wishlists">
                                <v-list-item-title>
                                    Wishlist
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="logout">
                                <v-list-item-title>
                                    Sign Out
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <a
                        v-else
                        href="/login">
                        <v-icon>
                            mdi-account-outline
                        </v-icon>
                    </a>
                </div>

                <v-badge 
                    @click="openCart"
                    color="error" 
                    :content="carts.reduce((sum, item) => sum + item.quantity, 0)"
                    class="cursor-pointer">
                    <v-icon>mdi-cart-outline</v-icon>
                </v-badge>

                <div class="ml-5">
                    <v-icon 
                        @click="activeSearch = true"
                        class="mr-5">
                        mdi-magnify
                    </v-icon>
                </div>
            </div>
        </v-app-bar>

        <v-app-bar 
            color="white"
            v-if="$vuetify.display.mdAndDown"
            scroll-behavior="hide">
            <div 
                v-if="activeSearch"
                class="d-flex w-100">
                <div class="z-form-search w-100">
                    <div class="z-box-search tw-relative">
                        <div class="form">
                            <div>
                                <input 
                                    size="1" 
                                    type="text"
                                    autocomplete="off"
                                    placeholder="Search"
                                    focus
                                    v-model="search"
                                    @keyup.enter="enterSearch"/>
                            </div>
                            <div
                                style="cursor: pointer;" 
                                @click="enterSearch">
                                <v-icon color="grey">mdi-magnify</v-icon>
                            </div>
                        </div>

                        <div
                            v-if="dataSearch.length > 0" 
                            class="tw-absolute tw-z-10 tw-w-full tw-bg-white tw-shadow-lg tw-mt-2 tw-p-2 tw-border tw-border-grey-800">
                            <ul>
                                <li
                                    v-for="product in dataSearch"
                                    :key="product.id"
                                    class="hover:tw-bg-blue/25">
                                    <a 
                                        class="tw-block tw-px-4 tw-py-2"
                                        :href="product.link">
                                        <div class="tw-flex">
                                            <div
                                                v-if="product.image" 
                                                class="tw-w-[54px] tw-mr-3">
                                                <v-img
                                                    :src="product.image"
                                                    :alt="product.title"
                                                    width="54">
                                                </v-img>
                                            </div>
                                            <div>
                                                <div class="tw-font-semibold tw-font-heading tw-mb-1">
                                                    {{ product.title }}
                                                </div>
                                                <div v-if="product.price">
                                                    <div
                                                        v-if="product.special_price" 
                                                        class="tw-flex tw-text-sm">
                                                        <div class="text-red tw-flex tw-mr-2">
                                                            Rp <span class="tw-font-heading tw-font-bold">{{ formatCurrency(product.special_price) }}</span>
                                                        </div>
                                                        <div class="tw-flex">
                                                            Rp <span class="tw-font-heading tw-font-bold tw-line-through">{{ formatCurrency(product.price) }}</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else 
                                                        class="tw-flex tw-text-sm">
                                                        <div class="tw-flex">
                                                            Rp <span class="tw-font-heading tw-font-bold">{{ formatCurrency(product.price) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <v-icon 
                    class="mt-1 mr-2 ml-2"
                    @click="activeSearch = false">
                    mdi-close
                </v-icon>
            </div>
            <div 
                v-else
                class="d-flex align-center w-100">
                <v-app-bar-nav-icon 
                    class="white--text"
                    @click.stop="drawer = !drawer">
                </v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <router-link :to="{path: '/'}">
                    <v-img
                        :width="100" 
                        :src="urlImage + config.image"
                        alt="webtrons"/>
                </router-link>
                <v-spacer></v-spacer>
                <gtranslate class="mr-5"/>
                <div class="mr-5">
                    <v-menu
                        offset-y
                        v-if="user">
                        <template 
                            v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props">
                                mdi-account-outline
                            </v-icon>
                        </template>
                        <v-list>
                            <v-list-item to="/user/account">
                                <v-list-item-title>
                                    My Account
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/user/order">
                                <v-list-item-title>
                                    Order History
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item to="/user/wishlists">
                                <v-list-item-title>
                                    Wishlist
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="logout">
                                <v-list-item-title>
                                    Sign Out
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <a
                        v-else
                        href="/login">
                        <v-icon>
                            mdi-account-outline
                        </v-icon>
                    </a>
                </div>
                <v-badge 
                    @click="openCart"
                    color="error" 
                    :content="carts.reduce((sum, item) => sum + item.quantity, 0)"
                    class="cursor-pointer">
                    <v-icon>mdi-cart-outline</v-icon>
                </v-badge>
                <v-icon 
                    @click="activeSearch = true"
                    class="mr-5 ml-5">
                    mdi-magnify
                </v-icon>
            </div>
        </v-app-bar>

        <v-navigation-drawer
            v-if="$vuetify.display.mdAndDown"
            v-model="drawer"
            temporary
            style="height: 100vh;">
            <!-- <div class="py-4 px-5">
                <router-link :to="{path: '/'}">
                    <v-img 
                        max-width="200" 
                        :src="urlImage + config.image"
                        alt="webtrons"/>
                </router-link>
            </div> -->

            <v-divider class=""></v-divider>
            <div class="py-2">
                <zAccordionMenu :data="sidebar"/>
            </div>
        </v-navigation-drawer>

        <Cart />
    </div>
</template>

<script setup>
    import { ref, watch } from 'vue'
    import Gtranslate from '../components/Gtranslate'
    import Cart from '../components/Cart'
    import { storeToRefs } from 'pinia'
    import { useNavigationStore } from '../store/navigation'
    import { useConfigStore } from '../store/config'
    import { useRoute, useRouter } from 'vue-router'
    import { useCartStore } from '@/store/cart'
    import { useUserStore } from '@/store/user'
    import api from '@/api'
    import { removeToken } from '@/auth'
    import zAccordionMenu from '@/components/zAccordionMenu'

    const route = useRoute()

    const drawer = ref(false)
    const useNavigation = useNavigationStore()
    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)
    const { navbar } = storeToRefs(useNavigation)
    const { sidebar } = storeToRefs(useNavigation)

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const useCart = useCartStore()
    const { carts } = storeToRefs(useCart)

    const urlImage = window.urlImage

    const search = ref('')
    const dataSearch = ref([])
    const activeSearch = ref(false)

    function enterSearch(){
        window.location = '/search?s=' + search.value
        // router.push('/search?s=' + search.value)
    }

    watch(search, (val) => {
        if(val){
            if(val.length > 1) {
                getSearch(val)
            }
        }
    })
    
    async function getSearch(val){
        await api.get('search/autocomplete?search=' + val)
            .then((resp) => {
                dataSearch.value = resp.data
            })
    }

    function checkUrlActive(url){
        let patch = url

        if (url.includes("#")) {
            const parts = url.split("#");
            
            const modifiedPart = parts[1].replace("-", "");
            
            patch = parts[0];
        }

        if (patch == route.path){
            return 'active'
        } else {
            return ''
        }
    }

    // function checkLik(link){
    //     return link.startsWith("http://") || link.startsWith("https://");
    // }

    function openCart() {
        useCartStore().openCart()
    }

    async function logout(){
		await api.post('/logout')
		.then((resp) => {
			removeToken()
			window.location = '/'
		})
		.catch((err) => {
			//
		})
	}
</script>

<style>
    .mega-menu > .v-overlay__content {
        border-radius: 0!important;
        border-bottom: 1px solid;
        left: 0!important;
        right: 0!important;
        max-width: 100% !important;
    }
</style>

<style scoped lang="scss">

    .mega-menu {
        box-shadow: none!important;
    }
    a.menu-navbar{
        border-bottom: 3px solid transparent;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 36px;
        padding-top: 36px;
        line-height: normal;
        cursor: pointer;
        &.dropdown{
            &.expanded{
                border-bottom: 3px solid #2559A5;
            }
        }
        &:hover{
            border-bottom: 3px solid #2559A5;
        }
        text-decoration: none;
        &.active{
            border-bottom: 3px solid #2559A5;
        }
    }
    a.menu-navbar1{
        border-bottom: 3px solid transparent;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-top: 10px;
        line-height: normal;
        cursor: pointer;
        &.dropdown{
            &.expanded{
                border-bottom: 3px solid #2559A5;
            }
        }
        &:hover{
            border-bottom: 3px solid #2559A5;
        }
        text-decoration: none;
        &.active{
            border-bottom: 3px solid #2559A5;
        }
    }
</style>