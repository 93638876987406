<template>
    <div class="z-carousel-3d">
        <Carousel 
            :itemsToShow="3" 
            :wrapAround="true" 
            :transition="500">
            <Slide 
                v-for="(slide, s) in getImage(data)" 
                :key="'slide' + s">
                <div 
                    class="carousel__item">
                    <img 
                        :src="urlImage + slide" 
                        :alt="slide" 
                        class="w-100"/>
                </div>
            </Slide>

            <template #addons>
                <Navigation />
            </template>
        </Carousel>
     </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'

    const display = useDisplay()

    const { data } = defineProps(['data'])
    const urlImage = window.urlImage

    function setHeightSlide(){
        if(display.xs.value){
            heightSlide.value = 220
        } else if(display.sm.value){
            heightSlide.value = 400
        } else if(display.md.value){
            heightSlide.value = 600
        } else {
            heightSlide.value = 600
        }
    }

    function getImage(data){
        let dataImages = []
        data.forEach(component => {
            component.contents.forEach(content => {
                content.items.forEach(item => {
                    dataImages.push(item.value)
                })
            })
        })

        return dataImages
    }

</script>