<template>
    <div class="">

        <div 
            class="tw-border-2 tw-border-grey-800 tw-my-4"
            v-for="order in orders.data"
            :key="order.id">
            <div class="tw-flex tw-w-full tw-py-1 tw-px-3 tw-bg-grey-100">
                <div class="">
                    <b>{{ order.invoice }}</b> 
                    <a
                        class="text-blue" 
                        :href="'/user/order/invoice/' + order.id">
                        Show
                    </a>
                </div>
                <v-spacer/>
                <div class="tw-text-right">
                    <div>
                        <b>{{ order.status }}</b>
                    </div>
                    <div>
                        <b>ORDER DATE {{ dayjs(order.created_at).format('DD/MM/YYYY') }}</b> 
                    </div>
                </div>
            </div>
            <div 
                class="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-py-3 tw-px-3"
                v-if="order.products[0]">
                <div class="tw-flex tw-col-span-2">
                    <div class="tw-w-[120px] tw-mr-2">
                        <v-img 
                            :src="urlImage + order.products[0].image"
                            height="100">
                        </v-img>
                    </div>
                    <div class="w-100">
                        <div>
                            <b>{{ order.products[0].title }}</b>
                        </div>
                        <div 
                            v-if="order.products[0].variant"
                            class="tw-text-sm">
                            <div v-if="order.products[0].variant != 'Default Title'">
                                {{ order.products[0].variant }}
                            </div>
                        </div>
                        <div 
                            v-if="order.products[0].sku"
                            class="tw-text-sm">
                            SKU: {{ order.products[0].sku }}
                        </div>
                    </div>
                </div>
                <div class="tw-text-right">
                    <div>
                        <b>{{ order.products[0].quantity }}x</b>
                    </div>
                    <div>
                        <b>Rp {{ formatCurrency(order.products[0].price) }}</b>
                    </div>
                    <div
                        v-if="order.products[0].normal_price" 
                        class="tw-line-through">
                        Rp {{ formatCurrency(order.products[0].normal_price) }}
                    </div>
                </div>
                <div class="tw-text-right">
                    <b>Rp {{ formatCurrency(order.products[0].total) }}</b>
                </div>
            </div>
            <div class="tw-flex tw-grow tw-w-full tw-p-3 tw-items-center">
                <div>
                    <template v-if="order.products.length > 1">
                        And {{ order.products.length - 1 }} more Products
                    </template>
                </div>
                <v-spacer/>
                <div class="tw-text-right">
                    <v-btn 
                        :href="'/user/order/' + order.id"
                        variant="outlined">
                        View Detail
                    </v-btn>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="tw-flex tw-grow tw-w-full tw-p-3 tw-items-center">
                <div>
                    <template v-if="order.status == 'cancel'">
                        {{ order.reason }}
                    </template>
                    <template v-else-if="(order.status || 'paid') && (order.status || 'processing') && (order.status || 'shipped') && (order.status || 'complete')">
                        Payment Confirmed using {{ order.payment_method }}
                    </template>
                    <template v-else>

                    </template>
                </div>
                <v-spacer/>
                <div class="tw-flex tw-items-center tw-text-right">
                    <div class="tw-text-lg tw-font-bold">
                        TOTAL Rp {{ formatCurrency(order.total) }}
                    </div>
                </div>
            </div>
        </div>

        <v-pagination
            v-if="orders"
            v-model="page"
            :length="orders.last_page"
            :total-visible="5"
            class="my-4">
        </v-pagination>
    </div>
</template>


<script setup>
    import { ref, watch, onMounted } from 'vue'
    import api from '@/api'
    import dayjs from 'dayjs'

    const urlImage = window.urlImage

    const orders = ref([])
    const loading = ref(false)

    const page = ref(1)

    watch(page, (val) => {
        if(val){
            getOrders()
        }
    })

    getOrders()
    async function getOrders(){
        await api.get('user/orders?status=cancel,paid,processing,shipped,complete&limit=10&page=' + page.value)
        .then((resp) => {
            orders.value = resp.data
        })
        .then(() => {
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })
</script>