<template>
    <div 
        class="vue-gallery">
        <div v-if="props.images">
            <div v-if="props.images[activeImage]">
                <div
                    id="slider"
                    class="active-image">
                    <div 
                        @click="openShowImage(activeImage)"
                        class="cursor-pointer">
                        <img
                            v-if="props.images[activeImage].type == 'image'" 
                            :src="props.images[activeImage].image"
                            class="box-active"/>
                        <iframe 
                            v-if="props.images[activeImage].type == 'video'"
                            :src="'https://www.youtube.com/embed/' + props.images[activeImage].youtube_id + '?controls=0'"
                            frameborder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen
                            class="box-active">
                        </iframe>
                    </div>
                    <div
                        class="navigation previous" 
                        @click="previousImage()">
                        <v-icon 
                            class="icon"
                            size="x-large">
                            mdi-chevron-left
                        </v-icon>
                    </div>
                    <div  
                        class="navigation next" 
                        @click="nextImage()">
                        <v-icon 
                            class="icon"
                            size="x-large">
                            mdi-chevron-right
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="thumbnails">
            <div 
                v-for="(image, index) in props.images" 
                :src="image.image" 
                :key="index" 
                @click="activeImage = index"
                :class="{'active': activeImage == index}" 
                :style="'background-image: url('+ image.image +')'">
            </div>
        </div>

        <ZshowImage
            v-if="dialogShowImage" 
            :show="dialogShowImage"
            :data="props.images"
            :index="dialogShowImageIndex"
            @closeShow="closeShowImage">
        </ZshowImage>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue'
    import ZshowImage from './zShowImage'

    const activeImage = ref(0)
    const props = defineProps(['images'])
    const dialogShowImage = ref(false)
    const dialogShowImageIndex = ref(0)

    function openShowImage(index){
        dialogShowImageIndex.value = index
        dialogShowImage.value = true
    }
    function closeShowImage(){
        dialogShowImage.value = false
    }

    watch(() => props.images, () => {
        activeImage.value = 0
    }, { deep: true })

    onMounted(() => {
        activeImage.value = 0
        const slider = document.getElementById('slider')
        
        document.addEventListener("keydown", (event) => {
            if (event.key == 'ArrowLeft')
                previousImage()
            if (event.key == 'ArrowRight')
                nextImage()
        })
        
        let startX = 0;
        let endX = 0;

        if(slider){
            slider.addEventListener('touchstart', function(e) {
                startX = e.touches[0].clientX;
            }, false);

            slider.addEventListener('touchmove', function(e) {
                endX = e.changedTouches[0].clientX;
            }, false);

            slider.addEventListener('touchend', function(e) {
                if (endX - startX > 0) {
                    nextImage()
                } else {
                    previousImage()
                }
            }, false);
        }
    })

    function nextImage() {
        activeImage.value = (activeImage.value + 1 < props.images.length ? activeImage.value + 1 : 0)
    }
    function previousImage() {
        activeImage.value = (activeImage.value - 1 >= 0 ? activeImage.value - 1 : props.images.length - 1)
    }
</script>

<style lang="scss" scoped>
.vue-gallery {
    .active-image {
        width: 100%;
        margin-bottom: 5px;
        padding-bottom: 65%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 2px solid #fff;
        position: relative;
        background-color: #f7f7f7;

        &:hover .navigation {
            opacity: 1;
        }

        .box-active{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .navigation {
            position: absolute;
            height: 100%;
            display: flex;
            justify-items: center;
            align-items: center;
            opacity: 0;

            .icon{
                background: hsla(0,0%,100%,.82);
                border-radius: 50%;
                cursor: pointer;
            }

            &.previous {
                padding: 0 1em 0 0.7em;
                left: 0;
            }

            &.next {
                padding: 0 0.7em 0 1em;
                right: 0;
            }
        }
    }

    .thumbnails {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        grid-gap: 5px;

        @media (max-width: 1024px){
            grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        }

        div {
            width: 100%;
            border: 2px solid #fff;
            outline: 2px solid #fff;
            cursor: pointer;
            padding-bottom: 65%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 1;

            &:hover {
                opacity: 0.6;
            }

            &.active {
                outline-color: #5c4084;
                opacity: 1;
            }
        }
    }
}
</style>