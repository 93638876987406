import { defineStore } from 'pinia'
import api from '../api'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: '',
        loading: false
    }),
    actions: {
        async getUser() {
            this.loading = true;
            await api.get('user')
                .then(res => {
                    this.user = res.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                })
        },
    },
});
