<template>
    <div class="px-4">
        <v-divider 
            :thickness="2"
            class="mb-5"/>
            
            <div class="tw-relative">
                <swiper
                    :slidesPerView="slidesPerView"
                    :spaceBetween="0"
                    :modules="[Navigation]"
                    :pagination="{
                        clickable: true,
                    }"
                    :navigation="{
                        prevEl: '.swiper-brand-button-prev',
                        nextEl: '.swiper-brand-button-next',
                    }">
                    <swiper-slide 
                        v-for="(item, i) in getData"
                        :key="'item' + i">
                        <div class="tw-px-7 tw-border-r-2 tw-border-grey-800">
                            <a :href="item.link">
                                <v-img 
                                    :src="urlImage + item.image">
                                </v-img>
                            </a>
                        </div>
                    </swiper-slide>
                </swiper>
                <div 
                    class="swiper-button-prev swiper-brand-button-prev !tw-left-[-23px]" 
                    slot="button-prev">
                </div>
                <div 
                    class="swiper-button-next swiper-brand-button-next !tw-right-[-23px]" 
                    slot="button-next">
                </div>
            </div>
        
        <v-divider 
            :thickness="2"    
            class="mt-5"/>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation } from 'swiper/modules'

    const display = useDisplay()
    const urlImage = window.urlImage
    const slidesPerView = ref(4)

    const { data } = defineProps(['data'])

    let getData = []
    data.forEach(val => {
        
        const contents = val.contents
        let dataContent = {
            image: ''
        }
        contents.forEach(content => {
            if(content.type === 'image'){
                dataContent.image = content.items[0].value
            } else if(content.type === 'singleLineText'){
                dataContent.title = content.items[0].value
            } else if(content.type === 'richText'){
                dataContent.description = content.items[0].value
            }
        })

        getData.push({
            link: val.link,
            id_css: val.id_css,
            class_css: val.class_css,
            image: dataContent.image,
        })

    })

    function setSlidesPerView() {
        if (display.xs.value) {
            return slidesPerView.value = 2
        }
        if (display.sm.value) {
            return slidesPerView.value = 4
        }
        return slidesPerView.value = 5
    }

    function prev() {
        this.$refs.swiper.$swiper.slidePrev();
    }
	function next() {
        this.$refs.swiper.$swiper.slideNext();
    }

    onMounted(() => {
        setSlidesPerView()
        window.addEventListener('resize', () => {
            setSlidesPerView()
        })
    })
</script>