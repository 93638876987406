<template>
    <div>
        <v-img
            v-if="collection.image" 
            :src="collection.image"
            :alt="collection.title">
        </v-img>
        <div class="tw-mt-10">
            <div class="container-max-1600">
                <div class="tw-pb-5 tw-text-center">
                    <div class="tw-text-3xl tw-font-bold tw-mb-2">
                        <h1>{{ collection.title }}</h1>
                    </div>
                    <div class="tw-w-1/2 mx-auto">
                       <div v-html="collection.description">
                       </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loadingPage">
            <FilterProduct :collection="collection.slug" />
        </div>

        <v-container>
            <v-row 
                v-if="loadingPage"
                class="my-10">
                <v-col 
                    md="4"
                    cols="6"
                    v-for="(item, p) in 9"
                    :key="'skeleton' + p">
                    <v-skeleton-loader
                        class="mx-auto border"
                        type="image, article">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import api from '@/api'
    import { useRoute } from 'vue-router'
    import FilterProduct from '@/components/FilterProduct'
    
    const route = useRoute()

    const collection = ref('')

    const loadingPage = ref(false)

    const slug = route.params.slug

    
    getCollection()
    async function getCollection(){
        loadingPage.value = true
        const resp = await api.get('collections/product/' + slug)
        collection.value = resp.data.data

        document.title = resp.data.data.title
        const metaTagTitle = document.querySelector('meta[name="title"]');
        if (metaTagTitle) {
            metaTagTitle.setAttribute('content', resp.data.data.title);
        }

        loadingPage.value = false
    }

    onMounted(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
</script>