<template>
    <v-app id="inspire" class="tw-font-heading">
        <navbar-component></navbar-component>
        <v-main 
            style="min-height: 85vh">
            <router-view></router-view>
        </v-main>
        <footer-component></footer-component>

        <v-snackbar 
            v-model="alertStore.active" 
            :color="alertStore.type">
            <div class="d-flex">
                {{ alertStore.message }}

                <v-spacer/>
                <v-icon 
                    @click="closeAlert">mdi-close
                </v-icon>
            </div>
        </v-snackbar>

        <a 
            v-if="config.webchat_wa"
            :href="generateLinkWa(config.webchat_wa)" 
            class="webchat-wa" 
            target="_blank"
            :style="'bottom: ' + (config.webchat_embed ? '125px;':'15px;')">
            <v-icon 
                size="small"
                color="white">
                mdi-whatsapp
            </v-icon>
        </a>

        <div
            class="tw-fixed tw-z-10 tw-bottom-5 tw-left-5"
            @click="scrollToUp">
            <v-btn
                rounded="50" 
                icon="mdi-arrow-up"
                color="white"
                v-if="isVisible">
            </v-btn>
        </div>

        <zPopup />
        <zPopupCart />
    </v-app>
</template>

<script setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    import NavbarComponent from './layouts/navbar'
    import FooterComponent from './layouts/footer'
    import zPopup from '@/components/zPopup'
    import zPopupCart from '@/components/zPopupCart'
    import { storeToRefs } from 'pinia'
    import { useNavigationStore } from './store/navigation'
    import { useConfigStore } from './store/config'
    import { useUserStore } from './store/user'
    import { useCartStore } from './store/cart'
    import { useAlertStore } from './store/alert'

    useNavigationStore().getNavbar()
    useNavigationStore().getSidebar()
    useNavigationStore().getFooter()
    useConfigStore().getConfig()
    useUserStore().getUser()
    useCartStore().getCart()
    const alertStore = useAlertStore()

    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)

    const fab = ref(false)

    const isVisible = ref(false)

    const handleScroll = () => {
        isVisible.value = window.scrollY > 700;
    };

    onMounted(() => {
        window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
    });

    function generateLinkWa(number){
        const phoneNumber = number.replace(/\+| /g, '');

        return 'https://api.whatsapp.com/send?phone=' + phoneNumber;
    }

    function closeAlert(){
		alertStore.action(false, '', '')
	}

    function scrollToUp() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
</script>

<style scoped>
    .webchat-wa{
        position: fixed;
        width: 60px;
        height: 60px;
        right: 15px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
    }

    .btn-scroll-up{
        position: fixed;
        z-index: 100;
        bottom: 150px;
        left: 60px;
    }
</style>