<template>
    <div>
        <div 
            v-for="(component, c) in data"
            :key="'component' + c"
            :class="component.class_css">
            <template 
                v-for="(content, c) in component.contents"
                :key="'content' + c">
                <template 
                    v-if="content.type == 'image'">
                    <template   
                        v-for="(item, c) in content.items"
                        :key="'image' + c">
                        <template v-if="item.value">
                            <template  v-if="component.link">
                                <a 
                                    :href="component.link">
                                    <div class="tw-relative">
                                        <img 
                                            :src="urlImage + item.value" 
                                            :alt="item.heading" 
                                            class="w-100 tw-object-cover tw-object-center tw-min-h-[178px]"
                                            :class="item.class_css">

                                        <div class="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-z-10 tw-flex tw-items-center">
                                            <div class="tw-w-[33rem] tw-pl-2 md:tw-pl-20 tw-text-left">
                                                <template 
                                                    v-for="(content, c) in component.contents"
                                                    :key="'content1' + c">
                                                        <template 
                                                            v-if="content.type == 'singleLineText'">
                                                            <div 
                                                                v-for="(item, c) in content.items"
                                                                :key="'singleLineText' + c"
                                                                :class="item.class_css"
                                                                v-html="item.value">
                                                            </div>
                                                        </template>
                                                        <template 
                                                            v-if="content.type == 'multiLineText'">
                                                            <div 
                                                                v-for="(item, c) in content.items"
                                                                :key="'itemmmultiLineText' + c"
                                                                :class="'z-title type-font-body tw-my-1 lg:tw-my-5 ' + item.class_css"
                                                                v-html="item.value">
                                                            </div>
                                                        </template>
                                                        <template 
                                                            v-if="content.type == 'richText'">
                                                            <div 
                                                                v-for="(item, c) in content.items"
                                                                :key="'itemmrichText' + c"
                                                                :class="'z-text ' + item.class_css"
                                                                v-html="item.value">
                                                            </div>
                                                        </template>
                                                        <template 
                                                            v-if="content.type == 'link'">
                                                            <div 
                                                                class="mt-5"
                                                                v-if="content.items && (content.items.length > 0)">
                                                                <v-btn
                                                                    v-for="(link, l) in content.items"
                                                                    :href="link.value"
                                                                    :key="'link' + l"
                                                                    :class="'mr-3 text-secondary ' + link.class_css">
                                                                    {{ link.label }}    
                                                                </v-btn>
                                                            </div>
                                                        </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                            <template v-else>
                                <div class="tw-relative">
                                    <img 
                                        :src="urlImage + item.value" 
                                        :alt="item.heading" 
                                        class="w-100 tw-object-cover tw-object-center tw-min-h-[178px]"
                                        :class="item.class_css">

                                    <div class="tw-absolute tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-z-10 tw-flex tw-items-center">
                                        <div class="tw-w-[33rem] tw-pl-2 md:tw-pl-20 tw-text-left">
                                            <template 
                                                v-for="(content, c) in component.contents"
                                                :key="'content1' + c">
                                                    <template 
                                                        v-if="content.type == 'singleLineText'">
                                                        <div 
                                                            v-for="(item, c) in content.items"
                                                            :key="'singleLineText' + c"
                                                            :class="item.class_css"
                                                            v-html="item.value">
                                                        </div>
                                                    </template>
                                                    <template 
                                                        v-if="content.type == 'multiLineText'">
                                                        <div 
                                                            v-for="(item, c) in content.items"
                                                            :key="'itemmmultiLineText' + c"
                                                            :class="'z-title type-font-body tw-my-1 lg:tw-my-5 ' + item.class_css"
                                                            v-html="item.value">
                                                        </div>
                                                    </template>
                                                    <template 
                                                        v-if="content.type == 'richText'">
                                                        <div 
                                                            v-for="(item, c) in content.items"
                                                            :key="'itemmrichText' + c"
                                                            :class="'z-text ' + item.class_css"
                                                            v-html="item.value">
                                                        </div>
                                                    </template>
                                                    <template 
                                                        v-if="content.type == 'link'">
                                                        <div 
                                                            class="mt-5"
                                                            v-if="content.items && (content.items.length > 0)">
                                                            <v-btn
                                                                v-for="(link, l) in content.items"
                                                                :href="link.value"
                                                                :key="'link' + l"
                                                                :class="'mr-3 text-secondary ' + link.class_css">
                                                                {{ link.label }}    
                                                            </v-btn>
                                                        </div>
                                                    </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
    const urlImage = window.urlImage
    const { data } = defineProps(['data'])
</script>