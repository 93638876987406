<template>
    <div>
    
        <swiper
            :modules="[Thumbs, Navigation, FreeMode]" 
            :thumbs="{ swiper: thumbsSwiper }"
            :navigation="true"
            :spaceBetween="10">
            <swiper-slide
                v-for="(component, i) in data"
                :key="'slide' + i">
                <div 
                    v-for="(content, c) in component.contents"
                    :key="'contentc' + c">
                    <div 
                        v-if="content.type == 'image'">
                        <div   
                            v-for="(item, c) in content.items"
                            :key="'image' + c">
                            <div v-if="item.value">
                                <img 
                                    :src="urlImage + item.value"
                                    class="w-100"
                                    :height="heightSlide">
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="mt-5">
            <swiper
                :modules="[Thumbs, Navigation, FreeMode]" 
                :spaceBetween="10"
                watch-slides-progress
                :slidesPerView="countThumb"
                :freeMode="true"
                @swiper="setThumbsSwiper">
                <swiper-slide
                    v-for="(component, i) in data"
                    :key="'slide' + i"
                    class="slide-thumbnail">
                    <div 
                        v-for="(content, c) in component.contents"
                        :key="'contentc' + c">
                        <div 
                            v-if="content.type == 'image'"
                            class="box-image">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'image' + c">
                                <div v-if="item.value">
                                    <v-img 
                                        :src="urlImage + item.value"
                                        cover
                                        position="center"
                                        :height="100">
                                    </v-img>
                                </div>
                            </div>
                            <div class="box-label">
                                <div class="label">
                                    <div 
                                        v-for="(content, c) in component.contents"
                                        :key="'contentc' + c"
                                        class="text-white">
                                        <div v-if="content.type == 'singleLineText'">
                                            <div 
                                                v-for="(item, c) in content.items"
                                                :key="'singleLineText' + c"
                                                class="z-title-card type-font-body text-center"
                                                :style="'font-size: ' + fontSizeHeading"
                                                v-html="item.value">
                                            </div>
                                        </div>
                                        <div v-if="content.type == 'multiLineText'">
                                            <div 
                                                v-for="(item, c) in content.items"
                                                :key="'itemmmultiLineText' + c"
                                                class="z-subtitle-card type-font-body text-center"
                                                v-html="item.value">
                                            </div>
                                        </div>
                                        <div 
                                            v-if="content.type == 'richText'">
                                            <div   
                                                v-for="(item, c) in content.items"
                                                :key="'itemmrichText' + c">
                                                <p class="z-text pt-1" v-html="item.value">
                                                </p> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
     </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
    import { Swiper, SwiperSlide } from 'swiper/vue';

    const display = useDisplay()

    const { data } = defineProps(['data'])
    const urlImage = window.urlImage
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
    }
    const countThumb = ref(6)
     const heightSlide = ref(600)

    function setCountTumb() {
        if (display.xs.value) {
            return countThumb.value = 3
        }
        if (display.sm.value) {
            return countThumb.value = 4
        }
        return countThumb.value = 6
    }

    function setHeightSlide(){
        if(display.xs.value){
            heightSlide.value = 220
        } else if(display.sm.value){
            heightSlide.value = 400
        } else if(display.md.value){
            heightSlide.value = 600
        } else {
            heightSlide.value = 600
        }
    }


    onMounted(() => {
        setCountTumb()
        setHeightSlide()
        window.addEventListener('resize', () => {
            setCountTumb()
            setHeightSlide()
        })
    })

</script>