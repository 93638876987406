<template>
    <div class="tw-relative">
        <swiper
            :slidesPerView="slidesPerView"
            :spaceBetween="0"
            :modules="[Navigation, Pagination]"
            :pagination="{
                dynamicBullets: true,
                clickable: true,
            }"
            :navigation="{
                prevEl: '.swiper-banner-button-prev',
                nextEl: '.swiper-banner-button-next',
            }">
            <swiper-slide 
                v-for="(component, i) in data"
                :key="'slide' + i">
                <div class="box-image">
                    <div 
                        v-for="(content, c) in component.contents"
                        :key="'contentc' + c">
                        <div 
                            v-if="content.type == 'image'">
                            <div   
                                v-for="(item, c) in content.items"
                                :key="'image' + c">
                                <div 
                                    v-if="item.value"
                                    :class="item.class_css">
                                    <template v-if="component.link">
                                        <a :href="component.link">
                                            <img 
                                                :src="urlImage + item.value"
                                                class="w-100">
                                        </a>
                                    </template>
                                    <template v-else>
                                        <img 
                                            :src="urlImage + item.value"
                                            class="w-100">
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="checkText(component.contents)" 
                        class="box-label tw-w-[33rem] !tw-pl-0 md:!tw-pl-20">
                        <div class="label">
                            <div class="tw-p-5 tw-bg-white/70 tw-text-black">
                                <div 
                                    v-for="(content, c) in component.contents"
                                    :key="'contentc' + c">
                                    <div v-if="content.type == 'singleLineText'">
                                        <div 
                                            v-for="(item, c) in content.items"
                                            :key="'singleLineText' + c"
                                            class=""
                                            v-html="item.value">
                                        </div>
                                    </div>
                                    <div v-if="content.type == 'multiLineText'">
                                        <div 
                                            v-for="(item, c) in content.items"
                                            :key="'itemmmultiLineText' + c"
                                            class="z-title type-font-body my-5"
                                            v-html="item.value">
                                        </div>
                                    </div>
                                    <div 
                                        v-if="content.type == 'richText'">
                                        <div   
                                            v-for="(item, c) in content.items"
                                            :key="'itemmrichText' + c">
                                            <p class="z-text" v-html="item.value">
                                            </p> 
                                        </div>
                                    </div>
                                    <div 
                                        v-if="content.type == 'link'">
                                        <div 
                                            class="mt-5"
                                            v-if="content.items && (content.items.length > 0)">
                                            <v-btn
                                                v-for="(link, l) in content.items"
                                                :href="link.value"
                                                :key="'link' + l"
                                                :class="'mr-2 ' + link.class_css">
                                                {{ link.label }}    
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <template
            v-if="!$vuetify.display.smAndDown">
            <div 
                class="swiper-button-prev swiper-banner-button-prev !tw-left-[-23px]" 
                slot="button-prev">
            </div>
            <div 
                class="swiper-button-next swiper-banner-button-next !tw-right-[-23px]" 
                slot="button-next">
            </div>
        </template>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { Navigation, Pagination } from 'swiper/modules'

    const display = useDisplay()

    const { data } = defineProps(['data'])
    const urlImage = window.urlImage
    const slidesPerView = ref(1)


    const heightSlide = ref(600)
    const fontSizeHeading = ref('41.88854382px')

    const hasText = ref(false)

    function setHeightSlide(){
        if(display.xs.value){
            heightSlide.value = '200px'
        } else if(display.sm.value){
            heightSlide.value = '300px'
        } else if(display.md.value){
            heightSlide.value = '400px'
        } else {
            heightSlide.value = '600px'
        }
    }

    function setFontSizeHeading(){
        if(display.xs.value){
            fontSizeHeading.value = '30px'
        } else if(display.sm.value){
            fontSizeHeading.value = '41.88854382px'
        } else if(display.md.value){
            fontSizeHeading.value = '41.88854382px'
        }
    }

    onMounted(() => {
        setHeightSlide()
        window.addEventListener('resize', setHeightSlide)
        setFontSizeHeading()
        window.addEventListener('resize', setFontSizeHeading)
    })

    function checkText(contents){
        let contVal = 0;
        contents.forEach(element => {
            if(element.type != 'image'){
                    contVal += 1
                }
        })

        return contVal ? true : false
    }
</script>

<style scoped>
    .swiper-button-prev{
        margin-left: 50px;
    }
    .swiper-button-next{
        margin-right: 50px;
    }
</style>