<template>
    <v-card variant="outlined" class="mb-5">
        <v-layout>
            <v-app-bar density="compact">
                <v-app-bar-title>WISHLIST</v-app-bar-title>
                <template v-slot:append>
                    <v-btn
                        class="mr-2"
                        size="small"
                        variant="outlined"
                        href="/user/wishlists">
                        View All
                    </v-btn>
                </template>
            </v-app-bar>
            <v-main class="px-4 pb-2">
                <div
                    v-if="loading" 
                    class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate>
                    </v-progress-circular>
                </div>
                <div v-else>
                    <div v-if="wishlists.length > 0">
                        <div 
                            class="tw-flex tw-items-center tw-py-3 tw-px-3"
                            v-for="product in wishlists"
                            :key="product.id">
                            <div class="tw-flex tw-flex-col md:tw-flex-row">
                                <div
                                    v-if="product.image" 
                                    class="tw-w-[120px] tw-mr-2">
                                    <a :href="'/products/' + product.slug">
                                        <v-img 
                                            :src="product.image.image"
                                            height="100">
                                        </v-img>
                                    </a>
                                </div>
                                <div>
                                    <a :href="'/products/' + product.slug">
                                        <div>
                                            <b>{{ product.title_product }}</b>
                                        </div>
                                        <div 
                                            v-if="product.title != 'Default Title'"
                                            class="tw-text-sm">
                                            Product Option: {{ product.title }}
                                        </div>
                                        <div>
                                            <div>
                                                <span :class="product.special_price ? 'tw-line-through':''">Rp {{ formatCurrency(product.price) }}</span>
                                                <span v-if="product.special_price">Rp {{ formatCurrency(product.special_price) }}</span>
                                            </div>
                                        </div>
                                        <div v-if="product.quantity > 0">
                                            in Stock
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <v-spacer/>
                            <div class="tw-text-right">
                                <v-icon @click="deleteWishlist(product.id)">mdi-close</v-icon>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-else
                        class="text-center">
                        There are no items in your wishlist
                    </div>
                </div>
            </v-main>
        </v-layout>
    </v-card>
</template>


<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useAlertStore } from '@/store/alert'
   
    const alertStore = useAlertStore()

    const wishlists = ref([])
    const loading = ref(false)

    getWishlist()
    async function getWishlist(){
        loading.value = true
        await api.get('wishlists?limit=5')
        .then((resp) => {
            wishlists.value = resp.data.data
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }

    async function deleteWishlist(id){
        await api.post('wishlists', {
            product_id: id
        })
        .then((resp) => {
            getWishlist()
            alertStore.action(true, 'success', 'Success')
        })
        .catch((err) => {
            alertStore.action(true, 'success', 'Error')
        })
    }
</script>