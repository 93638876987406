<template>
    <div>
        <v-card variant="outlined" class="mb-5">
            <v-layout>
                <v-app-bar density="compact">
                    <v-app-bar-title>PASSWORD</v-app-bar-title>
                    <template v-slot:append>
                        <v-btn
                            class="mr-2"
                            size="small"
                            variant="outlined"
                            @click="openEdit = !openEdit">
                            {{ openEdit ? 'Close':'Edit' }}
                        </v-btn>
                    </template>
                </v-app-bar>
                <v-main class="px-4 pb-2">
                    <div 
                        class="pt-2"
                        v-if="openEdit">
                        <v-text-field
                            density="compact"
                            variant="outlined"
                            label="Password"
                            placeholder="Password"
                            v-model="password"
                            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'"
                            @click:append-inner="visible = !visible"
                            :error-messages="getValidateError('password')"
                            :class="getValidateError('password') ? 'mb-3':''">
                        </v-text-field>

                        <div class="d-flex">
                            <v-spacer />
                            <v-btn
                                class="mr-2"
                                size="small"
                                variant="outlined"
                                @click="openEdit = !openEdit">
                                Close
                            </v-btn>
                            <v-btn
                                class="mr-2"
                                size="small"
                                @click="update"
                                :loading="loading">
                                Save
                            </v-btn>
                        </div>
                    </div>
                </v-main>
            </v-layout>
        </v-card>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/store/user'
    import { useAlertStore } from "@/store/alert"
    import api from '@/api'

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const alertStore = useAlertStore()

    const password = ref('')
    const visible = ref(false)

    const openEdit = ref(false)
    const loading = ref(false)
    const msgValidate = ref('')

    async function update(){
        msgValidate.value = ''
        loading.value = true

        await api.post(window.baseUrl + 'user/update-password', {
            password: password.value
        })
        .then((resp) => {
            openEdit.value = false
            loading.value = false
            alertStore.action(true, "success", "Success")
        })
        .catch((err) => {
            loading.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 423){
                alertStore.action(true, "error", "Error")
            } else {
                alertStore.action(true, "error", "Internal server error")
            }
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>