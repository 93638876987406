<template>
    <div v-if="props">
        <div 
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-4">
            <div class="tw-flex md:tw-flex-row">
                <div
                    v-if="props.data.image" 
                    class="tw-w-[120px] tw-mr-2">
                    <a :href="'/products/' + props.data.slug">
                        <v-img 
                            :src="props.data.image.image"
                            height="100">
                        </v-img>
                    </a>
                </div>
                <div>
                    <a :href="'/products/' + props.data.slug">
                        <div>
                            <b>{{ props.data.title_product }}</b>
                        </div>
                        <div 
                            v-if="props.data.title != 'Default Title'"
                            class="tw-text-sm">
                            Product Option: {{ props.data.title }}
                        </div>
                        <div v-if="props.data.quantity > 0">
                            in Stock
                        </div>
                    </a>
                </div>
            </div>
            <div class="tw-text-center md:tw-text-right tw-mb-4">
                <div v-if="props.data.special_price">
                    <span class="tw-font-bold">
                        Rp {{ formatCurrency(props.data.special_price) }}
                    </span>
                </div>
                <div :class="props.data.special_price ? 'tw-line-through':''">
                    <span :class="!props.data.special_price ? 'tw-font-bold':''"> 
                        Rp {{ formatCurrency(props.data.price) }}
                    </span>
                </div>
            </div>
            <div class="tw-mb-5">
                <div class="text-qty tw-w-[100px] tw-mx-auto">
                    <div class="icon">
                        <v-icon
                            v-if="qty < 2" 
                            class="icon-minus"
                            size="small">
                            mdi-minus
                        </v-icon>
                        <v-icon 
                            v-else
                            class="icon-minus"
                            size="small"
                            @click="qty -= 1">
                            mdi-minus
                        </v-icon>
                    </div>
                    <input
                        v-model="qty"
                        type="number"
                        min="0"
                        :max="data.quantity">
                    <div class="icon">
                        <v-icon 
                            v-if="qty < data.quantity"
                            class="icon-plus"
                            size="small"
                            @click="qty += 1">
                            mdi-plus
                        </v-icon>
                        <v-icon
                            v-else 
                            class="icon-plus"
                            size="small">
                            mdi-plus
                        </v-icon>
                    </div>
                </div>
            </div>
            <div class="tw-text-right">
                <v-btn 
                    block 
                    class="text-secondary mb-4"
                    @click="addToCart(props.data.id)">
                    Add To Cart
                </v-btn>
                <v-btn 
                    block 
                    variant="outlined"
                    @click="deleteWishlist(props.data.id)">
                    Delete
                </v-btn>
            </div>
        </div>
    </div>
</template>


<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import { useAlertStore } from '@/store/alert'
    import { useCartStore } from '@/store/cart'
   
    const alertStore = useAlertStore()

    const qty = ref(1)

    const props = defineProps(['data'])

    async function deleteWishlist(id){
        await api.post('wishlists', {
            product_id: id
        })
        .then((resp) => {
            getWishlist()
            alertStore.action(true, 'success', 'Success')
        })
        .catch((err) => {
            alertStore.action(true, 'success', 'Error')
        })
    }

    function addToCart(id){
        useCartStore().addToCart({
            productId: id,
            qty: qty.value
        })
    }
</script>