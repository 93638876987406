<template>
    <div>
        <div class="d-flex">
            <v-text-field 
                density="compact"
                variant="outlined"
                label="Discount code"
                placeholder="Discount code"
                v-model="discountCode"
                :error-messages="msgError">
            </v-text-field>
            <v-btn 
                class="ml-5"
                @click="applyDiscount">
                Apply
            </v-btn>
        </div>
    </div>
    
</template>

<script setup>
    import { ref, defineEmits } from 'vue'
    import api from '@/api'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/store/user'

    const emit = defineEmits(['updateDataDiscount'])
    const props = defineProps(['shippingcost'])

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    
    const discountCode = ref('')
    const loading = ref(false)

    const msgError = ref('')

    async function applyDiscount(){
        msgError.value = ''
        loading.value = true

        let url = 'discount/check'
        if(user.value){
            url = 'discount/l/check'
        }

        await api.post(url, {
            code: discountCode.value,
            cost_shipping: props.shippingcost.price
        })
        .then((resp) => {
            loading.value = false
            if(resp.data){
                if(resp.data.data.status){
                    emit('updateDataDiscount', resp.data.data)
                } else {
                    msgError.value = resp.data.data.message
                    emit('updateDataDiscount', {})
                }
            } else {
                msgError.value = resp.data.data.message
                emit('updateDataDiscount', {})
            }
        })
        .catch((err) => {
            loading.value = false
            if(err.response && err.response.status){
                if(err.response.status == 422){
                    msgError.value = 'The code field is required.' 
                } else {
                    msgError.value = err.response.data.message
                }
            } else {
                msgError.value = 'Internal server error' 
            }

        })

    }
</script>

<style scoped>
    
</style>