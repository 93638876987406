<template>
    <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-w-full xl:tw-px-[5em] 2xl:tw-px-[13em] tw-bg-blue tw-py-9 tw-px-5">
        <div class="tw-mb-4 lg:tw-mb-0 lg:tw-mr-7 tw-font-heading tw-font-semibold tw-w-[12em] tw-text-white">
            Shop by Device
        </div>
        <div class="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4">
            <template 
                v-for="(filter, f) in filters"
                :key="'filter' + f">
                <template
                    v-if="filter.title == 'Brand'">

                    <select
                        v-model="brand"
                        class="bg-white border border-white text-sm focus:ring-white focus:border-white block w-full px-3 py-2">
                        <option selected value="">Select Brand</option>
                        <option
                            v-for="(item, i) in filter.items"
                            :key="'item' + i"
                            :value="item">
                            {{ item }}    
                        </option>
                    </select>
                    
                    <!-- <v-select
                        size="small"
                        placeholder="Select Brand"
                        label="Select Brand"
                        variant="solo"
                        v-model="brand"
                        hide-details
                        :items="filter.items"
                        class="mr-2">
                    </v-select> -->
                </template>
            </template>
            <template 
                v-for="(filter, f) in filters"
                :key="'filter' + f">
                <template
                    v-if="filter.title == 'Device'">
                    <select
                        v-model="device"
                        class="bg-white border border-white text-sm focus:ring-white focus:border-white block w-full px-3 py-2">
                        <option selected value="">Select Device</option>
                        <option
                            v-for="(item, i) in filter.items"
                            :key="'item' + i"
                            :value="item">
                            {{ item }}    
                        </option>
                    </select>
                    <!-- <v-select
                        placeholder="Select Device"
                        label="Select Device"
                        variant="solo"
                        v-model="device"
                        hide-details
                        :items="filter.items"
                        class="ml-2">
                    </v-select> -->
                </template>
            </template>
        </div>
        <v-spacer/>
        <div class="tw-mt-5 lg:tw-mt-0 lg:tw-ml-7">
            <v-btn
                class="text-primary"
                size="large"
                color="white"
                @click="search">
                Search
            </v-btn>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    const { data } = defineProps(['data'])

    const device = ref('')
    const brand = ref('')
    const filters = ref('')

    getFilter()
    async function getFilter(){
        await api.get('filters')
        .then((resp) => {
            filters.value = resp.data
        })
        .catch((err) => {
            //
        })
    }

    function search(){
        return window.location = '/search?device=' + device.value + '&brand=' + brand.value
    }

</script>