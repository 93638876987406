<script setup>
    import { ref, onMounted, defineProps, defineEmits } from "vue";
    import L from "leaflet";

    // Props untuk default lat & lng
    const props = defineProps({
        modelValue: Object, // { lat: ..., lng: ... }
    });

    // Emit event untuk mengirim koordinat ke parent
    const emit = defineEmits(["update:modelValue"]);

    const map = ref(null);
    const marker = ref(null);
    const coordinates = ref(props.modelValue || { lat: 0.0, lng: 0.0 }); // Default Jakarta

    onMounted(() => {
        map.value = L.map("map").setView([coordinates.value.lat, coordinates.value.lng], 13);

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "&copy; OpenStreetMap contributors",
        }).addTo(map.value);

        marker.value = L.marker([coordinates.value.lat, coordinates.value.lng], { draggable: true }).addTo(map.value);

        // Update koordinat saat marker dipindahkan
        marker.value.on("dragend", (e) => {
            const { lat, lng } = e.target.getLatLng();
            coordinates.value = { lat, lng };
            emit("update:modelValue", coordinates.value); // Kirim ke parent
        });
    });
</script>


<template>
    <div>
        <div id="map" style="height: 300px;"></div>
    </div>
</template>