<template>
    <div>
        <v-card variant="outlined" class="mb-5">
            <v-layout>
                <v-app-bar density="compact">
                    <v-app-bar-title>PROFILE</v-app-bar-title>
                    <template v-slot:append>
                        <v-btn
                            class="mr-2"
                            size="small"
                            variant="outlined"
                            @click="openEdit = !openEdit">
                            {{ openEdit ? 'Close':'Edit' }}
                        </v-btn>
                    </template>
                </v-app-bar>
                <v-main class="px-4 pb-2">
                    <div v-if="!openEdit">
                        <table>
                            <tr>
                                <td 
                                    class="py-1"
                                    width="70">
                                    Name
                                </td>
                                <td>
                                    {{ user.name }}
                                </td>
                            </tr>
                            <tr>
                                <td class="py-1">
                                    Email
                                </td>
                                <td>
                                    {{ user.email }}
                                </td>
                            </tr>
                            <tr>
                                <td class="py-1">
                                    Phone
                                </td>
                                <td>
                                    {{ user.phone }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div 
                        class="pt-2"
                        v-else>
                        <v-text-field 
                            density="compact"
                            variant="outlined"
                            label="Name"
                            placeholder="Name"
                            v-model="user.name"
                            :error-messages="getValidateError('name')"
                            :class="getValidateError('name') ? 'mb-3':''">
                        </v-text-field>
                        <v-text-field
                            type="email" 
                            density="compact"
                            variant="outlined"
                            label="Email"
                            placeholder="Email"
                            v-model="user.email"
                            :error-messages="getValidateError('email')"
                            :class="getValidateError('email') ? 'mb-3':''">
                        </v-text-field>
                        <v-text-field
                            type="email" 
                            density="compact"
                            variant="outlined"
                            label="Phone"
                            placeholder="Phone"
                            v-model="user.phone"
                            :error-messages="getValidateError('phone')"
                            :class="getValidateError('phone') ? 'mb-3':''">
                        </v-text-field>

                        <div class="d-flex">
                            <v-spacer />
                            <v-btn
                                class="mr-2"
                                size="small"
                                variant="outlined"
                                @click="openEdit = !openEdit">
                                Close
                            </v-btn>
                            <v-btn
                                class="mr-2"
                                size="small"
                                @click="update"
                                :loading="loading">
                                Save
                            </v-btn>
                        </div>
                    </div>
                </v-main>
            </v-layout>
        </v-card>
    </div>
</template>


<script setup>
    import { ref, onMounted } from 'vue'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/store/user'
    import { useAlertStore } from "@/store/alert"
    import api from '@/api'

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const alertStore = useAlertStore()

    const openEdit = ref(false)
    const loading = ref(false)
    const msgValidate = ref('')

    async function update(){
        msgValidate.value = ''
        loading.value = true

        await api.post(window.baseUrl + 'user/update-account', {
            name: user.value.name,
            email: user.value.email,
            phone: user.value.phone,
        })
        .then((resp) => {
            openEdit.value = false
            loading.value = false
            alertStore.action(true, "success", "Success")
        })
        .catch((err) => {
            loading.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 423){
                alertStore.action(true, "error", "Error")
            } else {
                alertStore.action(true, "error", "Internal server error")
            }
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>