<template>
    <div class="bg-grey-lighten-4 py-15">
        <v-container>
            <v-row class="justify-center">
                <v-col
                    md="5"
                    cols="12">
                    <v-card 
                        flat
                        rounded="0">
                        <div class="pa-10">
                            <h5 
                                class="z-subtitle text-primary">
                                Forgot Password
                            </h5>

                            <div class="mt-7">
                                <v-text-field
                                    v-model="email"
                                    prepend-inner-icon="mdi-email"
                                    label="Email"
                                    variant="outlined"
                                    :error-messages="getValidateError('email')"
                                    :class="getValidateError('email') ? 'mb-3':''">
                                </v-text-field>
                                <div 
                                    v-if="msgError"
                                    class="text-red mb-4">
                                    {{ msgError }}
                                </div>

                                <div 
                                    v-if="msgSuccess"
                                    class="text-green mb-4">
                                    {{ msgSuccess }}
                                </div>

                                <v-btn 
                                    class="mt-5"
                                    size="x-large"
                                    block
                                    @click="forgotPassword"
                                    :loading="loading">
                                    Forgot Password
                                </v-btn>

                                <div class="text-center mt-7">
                                    <router-link 
                                        to="/login"
                                        class="text-primary">
                                        Login
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'

    document.title = "Forgot Password"

    const email = ref('')
    
    const loading = ref(false)

    const msgValidate = ref('')
    const msgError = ref('')
    const msgSuccess = ref('')

    async function forgotPassword(){
        msgValidate.value = ''
        msgError.value = ''
        msgSuccess.value = ''
        loading.value = true
        await api.post('forgot-password', {
            email: email.value,
        })
        .then((resp) => {
            loading.value = false

            msgSuccess.value = resp.data.message
        })
        .catch((err) => {
            loading.value = false
            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } 
            if(err.response.status == 400){
                msgError.value = err.response.data.status_message
            } 
            if(err.response.status == 429){
                msgError.value = 'Please try again in 30 minutes'
            }
            if(err.response.status == 500){
                msgError.value = 'Internal server error'
            } 
        })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>