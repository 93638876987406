<template>
    <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">

        <template 
            v-for="(chunk, ck) in getData.chunk(4)"
            :key="'chunk' + ck">

                <div
                    v-for="(item, c) in chunk"
                    :key="'content' + c"
                    :class="c % 2 == ck ? 'tw-bg-grey-100 tw-p-4 tw-relative' : 'tw-bg-grey-800 tw-p-4 tw-relative'">
                    <a :href="item.link">
                        <v-hover v-slot="{ isHovering, props }">
                            <div 
                                v-bind="props" 
                                class="tw-text-center">
                                <v-img 
                                    :class="isHovering ? 'tw-transition tw-ease-in-out tw-scale-110 tw-duration-300':''"
                                    v-if="item.image"
                                    aspect-ratio="1"
                                    cover
                                    :src="urlImage + item.image">
                                </v-img>
                                <div class="tw-text-xl tw-font-heading tw-font-bold tw-tracking-[0.3em] tw-mb-2 mt-4">
                                    {{ item.title }}
                                </div>
                                <div class="tw-text-sm tw-font-body tw-mb-1" v-html="item.description"></div>

                                <div 
                                    v-if="isHovering"
                                    class="tw-flex tw-justify-center tw-items-center tw-absolute tw-z-3 tw-h-full tw-top-0 tw-left-0 tw-right-0 tw-bg-blue/60">
                                    <v-btn
                                        rounded="50"
                                        :href="item.link"
                                        color="secondary"
                                        icon="mdi-magnify">
                                    </v-btn>
                                </div>
                            </div>
                        </v-hover>
                    </a>
                </div>
        </template>
            
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useDisplay } from 'vuetify'
    
    const display = useDisplay()

    const urlImage = window.urlImage

    const { data } = defineProps(['data'])


    let getData = []

    data.forEach(val => {
        
        const contents = val.contents
        let dataContent = {
            title: '',
            description: '',
            image: ''
        }
        contents.forEach(content => {
            if(content.type === 'image'){
                dataContent.image = content.items[0].value
            } else if(content.type === 'singleLineText'){
                dataContent.title = content.items[0].value
            } else if(content.type === 'richText'){
                dataContent.description = content.items[0].value
            }
        })

        getData.push({
            link: val.link,
            id_css: val.id_css,
            class_css: val.class_css,
            title: dataContent.title,
            description: dataContent.description,
            image: dataContent.image,
        })

    })

    if (!Array.prototype.chunk) {
        Object.defineProperty(Array.prototype, 'chunk', {
            value: function(chunkSize) {
                let data = this;
                return [].concat.apply([],
                data.map(function(el, i) {
                    return i % chunkSize ? [] : [data.slice(i, i + chunkSize)];
                })
                );
            }
        })
    }
</script>