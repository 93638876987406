<template>
    <div>
        <div
            v-for="promotion in promotions"
            :key="promotion.id">
            <div class="mb-10">
                <zCountdown 
                    class="tw-bg-red tw-text-white tw-text-5xl tw-w-96 tw-px-7 tw-py-3 tw-text-center tw-font-bold tw-mx-auto"
                    :data="{
                    start_date: promotion.start_date,
                    end_date: promotion.end_date,
                }" />
            </div>
            <div class="px-4">
                <v-row
                    class="my-10">
                    <v-col 
                        md="3"
                        cols="6"
                        v-for="product in promotion.products"
                        :key="product.id">
                        <zProduct :data="product">
                        </zProduct>
                    </v-col>
                </v-row>
            </div>

        </div>
        
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import zProduct from './zProduct'
    import api from '../api'
    import zCountdown from './zCountdown'

    const { data } = defineProps(['data'])

    const loading = ref(false)
    const promotions = ref([])

    getPromotion()
    async function getPromotion(){
        const dataSection = data[0]
        loading.value = true
        await api.get('products/flash-sale?limit=' + dataSection.limit + '&promotion=' + dataSection.promotion)
            .then((resp) => {
                loading.value = false
                promotions.value = resp.data.data
            })
            .catch((err) => {
                loading.value = false
            })
    }
</script>