<template>
    <div>
        <v-icon
            :size="props.sizeicon"
            @click="wishlist">
            mdi-cards-{{ isWishlist ? 'heart':'heart-outline' }}
        </v-icon>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useAlertStore } from '@/store/alert'
    import api from '../api'
    
    const props = defineProps(['productid', 'sizeicon'])
    
    const alertStore = useAlertStore()

    const isWishlist = ref(false)

    async function wishlist(){
        await api.post('wishlists', {
            product_id: props.productid
        })
        .then((resp) => {
            isWishlist.value = !isWishlist.value
            alertStore.action(true, 'success', 'Success')
        })
        .catch((err) => {
            alertStore.action(true, 'success', 'Error')
        })
    }
</script>