<template>
    <div>
        <div>
            <v-row 
                :class="'heading ' + (data.length > 0 ? '' : 'justify-center')">
                <v-col
                    cols="12"
                    :md="data.length > 0 ? 5 : 12">
                    <div 
                        v-for="(component, c) in data"
                        :key="'component' + c">
                        <div 
                            v-for="(content, c) in component.contents"
                            :key="'contentc' + c"
                            class="text-primary font-weight-medium">
                            <div 
                                v-if="content.type == 'singleLineText'"
                                class="z-subtitle">
                                <div   
                                    v-for="(item, c) in content.items"
                                    :key="'itemmsingleLineText' + c">
                                    {{ item.value }}
                                </div>
                            </div>
                            <div 
                                v-if="content.type == 'multiLineText'">
                                <div   
                                    v-for="(item, c) in content.items"
                                    :key="'itemmmultiLineText' + c">
                                    <h1 class="z-title">{{ item.value }}</h1>
                                </div>
                            </div>

                            <div 
                                v-if="content.type == 'richText'"
                                class="short-description">
                                <div   
                                    v-for="(item, c) in content.items"
                                    :key="'itemmrichText' + c">
                                    <div
                                        class="z-text" 
                                        v-html="item.value">
                                    </div>
                                </div>
                            </div>
                            <div 
                                v-if="content.type == 'link'"
                                class="w-100">
                                <div
                                    class="d-flex justify-center"
                                    v-for="(link, l) in content.items"
                                    :key="'link' + l">
                                    <v-btn 
                                        v-if="link.value"
                                        class="mt-7 mr-2"
                                        :href="link.value"
                                        variant="outlined">
                                        {{ link.label }}
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    :md="7">
                    <div>
                        <v-alert
                            v-if="msg"
                            class="mb-7"
                            border="start"
                            variant="tonal"
                            :type="msg.type">
                            {{ msg.msg }}
                        </v-alert>

                        <v-form ref="form">
                            <v-row>
                                <v-col 
                                    cols="12" 
                                    md="12"
                                    class="py-1">
                                    <div class="text-subtitle-1 text-medium-emphasis">Full Name</div>
                                    <v-text-field
                                        density="compact"
                                        variant="outlined"
                                        placeholder="Full Name"
                                        v-model="name"
                                        :rules="[
                                            v => !!v || 'Name is required',
                                            v => (v && v.length > 2 ) || 'Name  must be between 2 and 50 characters',
                                            v => (v && v.length < 50 ) || 'Name  must be between 2 and 50 characters',
                                        ]">
                                    </v-text-field>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['name']">
                                        {{ msgValidate['name'][0] }}
                                    </small>
                                </v-col>
                                <v-col 
                                    cols="12" 
                                    md="6"
                                    class="py-1">
                                    <div class="text-subtitle-1 text-medium-emphasis">Email</div>
                                    <v-text-field
                                        density="compact"
                                        variant="outlined"
                                        v-model="email"
                                        placeholder="Email"
                                        :rules="[
                                            v => !!v || 'Email is required',
                                            v => (v && v.length > 2 ) || 'Email  must be between 2 and 50 characters',
                                            v => (v && v.length < 50 ) || 'Email  must be between 2 and 50 characters',
                                            v => /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v) || 'Email invalid'
                                        ]">
                                    </v-text-field>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['email']">
                                        {{ msgValidate['email'][0] }}
                                    </small>
                                </v-col>
                                <v-col 
                                    cols="12" 
                                    md="6"
                                    class="py-1">
                                    <div class="text-subtitle-1 text-medium-emphasis">Phone number</div>
                                    <v-text-field
                                        density="compact"
                                        variant="outlined"
                                        v-model="phone"
                                        placeholder="Phone number"
                                        :rules="[
                                            v => !!v || 'Phone number is required',
                                            v => (v && v.length > 5 ) || 'Phone number  must be between 5 and 18 characters',
                                            v => (v && v.length < 18 ) || 'Phone number  must be between 5 and 18 characters',
                                        ]">
                                    </v-text-field>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['phone']">
                                        {{ msgValidate['phone'][0] }}
                                    </small>
                                </v-col>
                                <!-- <v-col 
                                    cols="12" 
                                    md="6"
                                    class="py-1">
                                    <v-text-field
                                        variant="outlined"
                                        v-model="subject"
                                        label="Subject"
                                        :rules="[
                                            v => !!v || 'Subject is required',
                                            v => (v && v.length > 5 ) || 'Subject  must be between 5 and 100 characters',
                                            v => (v && v.length < 100 ) || 'Subject  must be between 5 and 100 characters',
                                        ]">
                                    </v-text-field>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['subject']">
                                        {{ msgValidate['subject'][0] }}
                                    </small>
                                </v-col> -->
                                <v-col 
                                    cols="12" 
                                    md="12"
                                    class="py-1">
                                    <div class="text-subtitle-1 text-medium-emphasis">Your message</div>
                                    <v-textarea
                                        density="compact"
                                        variant="outlined"
                                        v-model="message"
                                        placeholder="Your message"
                                        :rules="[
                                            v => !!v || 'Your message is required',
                                            v => (v && v.length > 5 ) || 'Your message  must be between 5 and 200 characters',
                                            v => (v && v.length < 200 ) || 'Your message  must be between 5 and 200 characters',
                                        ]">
                                    </v-textarea>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['message']">
                                        {{ msgValidate['message'][0] }}
                                    </small>
                                </v-col>
                                <!-- <v-col 
                                    cols="12" 
                                    md="12"
                                    class="py-1 pt-0">
                                    <div class="text-subtitle-1 text-medium-emphasis">Inquiries</div>
                                    <v-row>
                                        <v-col 
                                            cols="6"
                                            sm="4"
                                            md="3">
                                            <v-checkbox 
                                                label="Book a Visit"
                                                density
                                                v-model="inquiry"
                                                value="Book a Visit">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            sm="4"
                                            md="3">
                                            <v-checkbox 
                                                label="Information"
                                                density
                                                v-model="inquiry"
                                                value="Information">
                                            </v-checkbox>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            sm="4"
                                            md="3">
                                            <v-checkbox 
                                                label="Other"
                                                density
                                                v-model="inquiry"
                                                value="Other">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['inquiry']">
                                        {{ msgValidate['inquiry'][0] }}
                                    </small>
                                </v-col> -->
                                <v-col 
                                    cols="12" 
                                    md="12"
                                    class="py-1">
                                    <div id="recaptchaContainer"></div>
                                    <small 
                                        class="text-red" 
                                        v-if="msgValidate['g_recaptcha_response']">
                                        {{ msgValidate['g_recaptcha_response'][0] }}
                                    </small>
                                </v-col>
                            </v-row>

                            <div class="mt-10">
                                <v-btn 
                                    :loading="loading"
                                    @click="save"
                                    class="px-10 text-secondary font-weight-bold">
                                    Send
                                </v-btn>
                            </div>
                        </v-form>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import { useRecaptcha } from '../composables/useRecaptcha'
    import api from '../api'
    
    const { data } = defineProps(['data'])

    const name = ref('')
    const email = ref('')
    const phone = ref('')
    const subject = ref('')
    const message = ref('')
    const inquiry = ref([])
    const form = ref('')
    const loading = ref(false)
    const msgValidate = ref([])
    const msg = ref()
    const loadForm = ref(false)

    const siteKey = window.recaptchav2SiteKey;

    const { loadRecaptcha } = useRecaptcha(siteKey);

    onMounted(() => {
        setTimeout(() =>
        {
            loadForm.value = true
        }, 1500)

        setTimeout(() =>
        {
            loadRecaptcha()
        }, 1000)

        window.scrollTo({ top: 0, behavior: 'smooth' });
    })

    async function save(){
        msgValidate.value = []
        msg.value = {}
        // const { valid } = await form.value.validate()
        // console.log(valid)
        // if(valid){
            loading.value = true
            const responseToken = window.grecaptcha.getResponse();

            await api.post('inquiry/contact', {
                name: name.value,
                email: email.value,
                phone: phone.value,
                subject: subject.value,
                inquiry: inquiry.value.toString(),
                message: message.value,
                g_recaptcha_response: responseToken
            })
            .then((resp) => {
                loading.value = false
                msg.value = {
                    type: 'success',
                    msg: resp.data.msg
                }
            })
            .catch((err) => {
                loading.value = false

                if(err.response.status == 422){
                    msgValidate.value = err.response.data.errors
                } else {
                    msg.value = {
                        type: 'error',
                        msg: 'Internal server error'
                    }

                    console.log(err.response.data)
                }
                
            })
        // }
    }
</script>