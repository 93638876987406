import { defineStore } from 'pinia'
import api from '../api'

export const useDistrictStore = defineStore('district', {
    state: () => ({
        province: [],
        city: [],
        district: [],
        loading: false
    }),
    actions: {
        async getProvince(search) {
            this.loading = true;
            await api.get('districts/province?search=' + search)
                .then(res => {
                    this.province = res.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false;
                })
        },
        async getCity(search) {
            this.loading = true;
            await api.get('districts/city?province=' + search)
                .then(res => {
                    this.city = res.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false;
                })
        },
        async getDistrict(search) {
            this.loading = true;
            await api.get('districts/district?city=' + search)
                .then(res => {
                    this.district = res.data.data;
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err)
                    this.loading = false;
                })
        },
    },
});
