<template>
    <div 
        class="printable-content"
        :style="{ '--content-text': `'${getStatus(order.status).status}'`, '--content-color': getStatus(order.status).color }"
        v-if="!loading">
        <v-container>
            <div class="mb-4 d-flex">
                <div>
                    <img 
                        :src="urlImage + config.image"
                        width="100px">
                </div>
                <v-spacer/>
                <div 
                    v-if="order.created_at"
                    class="text-right">
                    <div class="text-h6 font-weight-bold">
                        INVOICE
                    </div>
                    <div>
                        {{ order.invoice }}
                    </div>
                </div>
            </div>

            <div class="mb-7">
                <v-row>
                    <v-col 
                        cols="5"
                        md="5"
                        class="text-body-2">
                        <tr>
                            <td
                                valign="top">Penjual</td>
                            <td 
                                class="pl-1 font-weight-bold"
                                valign="top">: IGA Tech Lifestyle</td>
                        </tr>
                    </v-col>
                    <v-col 
                        cols="7"
                        md="7">
                        <table class="text-body-2">
                            <tr>
                                <td>
                                    Status
                                </td>
                                <td class="pl-1">
                                    : <b>{{ order.status }}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Pembeli
                                </td>
                                <td class="pl-1">
                                    : {{ order.customer && order.customer.name }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Telp Pembeli
                                </td>
                                <td class="pl-1">
                                    : {{ order.phone_number }}
                                </td>
                            </tr>
                            <tr v-if="order.created_at">
                                <td width="130">
                                    Tanggal Pembelian
                                </td>
                                <td class="pl-1">
                                    : {{ formatDate(order.created_at) }}
                                </td>
                            </tr>
                            <tr>
                                <td 
                                    valign="top" 
                                    width="130">
                                    Alamat Pengiriman
                                </td>
                                <td 
                                     class="pl-1"
                                    valign="top">
                                    : 
                                    <template v-if="order.shipping_address">
                                        <span v-if="order.shipping_address.name">
                                           <b>{{ order.shipping_address.name }}</b> <br>
                                        </span>
                                        <div class="ml-2">
                                            <span v-if="order.shipping_address.address1">
                                                {{ order.shipping_address.address1 }},
                                            </span>
                                            <span v-if="order.shipping_address.address2">
                                                {{ order.shipping_address.address2 }},
                                            </span>
                                            <span v-if="order.shipping_address.city">
                                                {{ order.shipping_address.city }},
                                            </span>
                                            <span v-if="order.shipping_address.province">
                                                {{ order.shipping_address.province }}  {{ order.shipping_address.zip }},
                                            </span>
                                            <span v-if="order.shipping_address.country">
                                                {{ order.shipping_address.country }}
                                            </span>
                                        </div>
                                    </template>
                                </td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>
            </div>

            <div
                class="mb-5">
                <v-table 
                    class="bg-transparent"
                    density="compact">
                    <thead>
                        <tr>
                            <th class="text-left">
                                INFO PRODUK
                            </th>
                            <th class="text-right">
                                JUMLAH
                            </th>
                            <th class="text-right">
                                HARGA SATUAN
                            </th>
                            <th class="text-right">
                                TOTAL HARGA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="product in order.products"
                            :key="product.id">
                            <td>
                                <div>
                                    <b>{{ product.title }}</b>
                                </div>
                                <div v-if="product.variant && (product.variant != 'Default Title')">
                                    {{ product.variant }}
                                </div>
                                <div v-if="product.sku">
                                    SKU: {{ product.sku }}
                                </div>
                            </td>
                            <td class="text-right">
                                {{ product.quantity }}
                            </td>
                            <td class="text-right">
                                Rp {{ formatPrice(product.price) }}
                            </td>
                            <td class="text-right">
                                Rp {{ formatPrice(product.total) }}
                            </td>
                        </tr>
                    </tbody>
                </v-table>


                <v-row>
                    <v-col 
                        cols="5"
                        md="5">
                        <div
                            v-if="order.note" 
                            class="mt-5">
                            <div class="font-weight-bold">Catatan pesanan:</div>
                            <div>
                                {{ order.note }}
                            </div>
                        </div>
                        
                        <div
                            v-if="order.shipping_etd" 
                            class="mt-5">
                            <div class="font-weight-bold">Estimasi pesanan tiba:</div>
                            <div>
                                {{ order.shipping_etd }}
                            </div>
                        </div>

                    </v-col>
                    <v-col 
                        cols="7"
                        md="7">
                        <div>
                            <div class="mx-4 my-4">
                                <div class="d-flex my-1 font-weight-bold">
                                    <div>
                                        Subtotal
                                    </div>
                                    <v-spacer/>
                                    <div>
                                    Rp {{ formatCurrency(order.products.reduce((total, product) => {
                                                return total + parseInt(product.price);
                                            }, 0)) }}
                                    </div>
                                </div>
                                <div 
                                    class="d-flex my-1 text-body-2" 
                                    v-if="order.coupon">
                                    <div>
                                        Discount {{ order.coupon }}
                                    </div>
                                    <v-spacer/>
                                    <div>
                                    - Rp {{ formatPrice(order.coupon_amount) }}
                                    </div>
                                </div>
                                <div 
                                    class="d-flex my-1 text-body-2"
                                    v-if="order.shipping_method">
                                    <div>
                                        Shipping {{ order.shipping_method }} <span v-if="order.shipping_code">({{ order.shipping_code }})</span>
                                    </div>
                                    <v-spacer/>
                                    <div>
                                        Rp {{ formatPrice(order.shipping_cost) }}
                                    </div>
                                </div>
                                <div
                                    v-if="order.shipping_insurance > 0"
                                    class="d-flex my-1">
                                    <div>
                                        Shipping insurance: 
                                    </div>
                                    <v-spacer/>
                                    <div>
                                        Rp {{ formatPrice(order.shipping_insurance) }}
                                    </div>
                                </div>
                                <div class="d-flex my-1 font-weight-bold">
                                    <div>Total</div>
                                    <v-spacer/>
                                    <div>
                                        Rp {{ formatPrice(order.total) }}
                                    </div>
                                </div>
                                <!-- <div class="d-flex my-1">
                                    <div>
                                        <b>Payment method</b>
                                        {{ order.payment_method }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-15">
                <div class="text-body-2">
                    Invoice ini sah dan diproses oleh komputer<br>
                    Silahkan hubungi <b>IGA Tech Lifestyle</b> apabila kamu mebutuhkan bantuan.
                </div>
            </div>
        </v-container>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue' 
    import api from '@/api'
    import { storeToRefs } from 'pinia'
    import { useConfigStore } from '@/store/config'
    import dayjs from 'dayjs'
    import { useRoute } from 'vue-router'

    const urlImage = window.urlImage

    const useConfig = useConfigStore()
    const { config } = storeToRefs(useConfig)

    const route = useRoute()
    const id = route.params.id

    const order = ref('')
    const loading = ref(false)

    getOrders()
    async function getOrders(){
        loading.value = true
        await api.get('user/orders/' + id)
        .then((resp) => {
            order.value = resp.data.data
            loading.value = false
        })
        .catch((err) => {
            loading.value = false
        })
    }

    function formatDate(date){
        return dayjs(date).format('YYYY-MM-DD HH:mm')
    }

    function formatPrice(value) {
        const val = (value/1);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    onMounted(() => {
        setTimeout(function() {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    window.scrollTo({ top: element.offsetTop, behavior: 'smooth' });
                } else {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 500)
    })

    function getStatus(status){
        if(status == 'cancel') {
            return {
                status: 'CANCEL',
                color: '#a12424'
            }
        } else if(status == 'expire'){
            return {
                status: 'CANCEL',
                color: '#a12424'
            }
        } else if(status == 'pending'){
            return {
                status: 'PENDING',
                color: '#2455a1'
            }
        } else { 
            return {
                status: 'SETTLEMENT',
                color: '#2455a1'
            }
        }
    }
</script>

<style lang="css" scoped>
    .printable-content {
        position: relative;
        overflow: hidden;
    }
    .printable-content::before {
        content: var(--content-text);
        color: var(--content-color);
        font-weight: 800;
        font-size: 40px;
        letter-spacing: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        opacity: 0.1;
        white-space: nowrap;
        border: 5px solid var(--content-color);
        padding: 0 16px;
    }

    .v-container.v-locale--is-ltr {
        position: relative;
        z-index: 1;
        color: #000;
    }
</style>