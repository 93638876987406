import axios from 'axios'
import sha256 from 'crypto-js/sha256'
import encHex from 'crypto-js/enc-hex'
import { format } from 'date-fns'
import { getToken } from '@/auth'

const now = new Date()
const time = format(now, 'dd-MM-yyyy HH:mm')

const xTime = time.split(' ')
const date = xTime[0]
const hour = xTime[1]

const xDate = date.split('-')
const d = xDate[0]
const m = xDate[1]
const y = xDate[2]

const xHour = hour.split(':')
const mi = xHour[1]

const random = m + d + mi + y


// const token = localStorage.getItem('token')
axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true;

const token = getToken()
const api = axios.create({
    baseURL: window.baseUrl + '/',
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        'key': sha256(random).toString(encHex),
        'time': time,
    }
})

export default api
