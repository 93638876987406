<template>
    <div class="mt-10">
        <v-container>
            <v-card
                v-if="info.message && info.message.title"
                variant="outlined"
                class="mb-5 px-4 py-3">
                <div>
                    <b>{{ info.message.title }}</b>
                </div>
                <div v-html="info.message.text">

                </div>
            </v-card>

            <div v-if="messageErr">
                <v-alert
                    color="red"
                    variant="outlined">
                    {{ messageErr }}
                </v-alert>
            </div>
            <div 
                v-if="msgError && msgError.length > 0"
                class="mb-5">
                <v-alert
                    color="red"
                    variant="outlined">
                    <div 
                        v-for="(error, e) in msgError"
                        :key="'error' + e">
                        <div>
                            <b>
                                {{ error.title }}
                            </b>
                        </div>
                        {{ error.message }}
                    </div>
                </v-alert>
            </div>

            <v-row>
                <v-col 
                    md="6"
                    cols="12">
                    <div>
                        <div class="mb-4 tw-flex">
                            <h3>Contact Info</h3>
                            
                            <div 
                                class="ml-auto"
                                v-if="!user">
                                <a 
                                    class="text-blue tw-underline" 
                                    href="/login">
                                    Login
                                </a>
                            </div>
                        </div>
                        <v-row>
                            <v-col 
                                md="12"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Name"
                                    placeholder="Name"
                                    v-model="name"
                                    :error-messages="getValidateError('name')"
                                    :class="getValidateError('name') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col 
                                md="6"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Phone"
                                    placeholder="Phone"
                                    v-model="phone"
                                    :error-messages="getValidateError('phone')"
                                    :class="getValidateError('phone') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col 
                                md="6"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Email"
                                    placeholder="Email"
                                    type="email"
                                    v-model="email"
                                    :error-messages="getValidateError('email')"
                                    :class="getValidateError('email') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                    <div class="mt-7">
                        <div class="mb-4">
                            <h3>Delivery Address</h3>
                        </div>
                        <v-row>
                            <v-col 
                                md="12"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Name"
                                    placeholder="Name"
                                    v-model="shippingAddressName"
                                    :error-messages="getValidateError('shipping_address_name')"
                                    :class="getValidateError('shipping_address_name') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col
                                md="6"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Phone"
                                    placeholder="Phone"
                                    v-model="shippingAddressPhone"
                                    :error-messages="getValidateError('shipping_address_phone')"
                                    :class="getValidateError('shipping_address_phone') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col
                                md="12"
                                cols="12"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Country"
                                    v-model="shippingAddressCountry"
                                    :items="['Indonesia']"
                                    :error-messages="getValidateError('shipping_address_country')"
                                    :class="getValidateError('shipping_address_country') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="7"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="Province"
                                    placeholder="Province"
                                    v-model="shippingAddressProvince"
                                    :items="districtStore.province"
                                    item-value="province"
                                    item-title="province"
                                    :error-messages="getValidateError('shipping_address_province')"
                                    :class="getValidateError('shipping_address_province') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="5"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Postal code"
                                    placeholder="Postal code"
                                    v-model="shippingAddressZip"
                                    :error-messages="getValidateError('shipping_address_zip')"
                                    :class="getValidateError('shipping_address_zip') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="6"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="City"
                                    placeholder="City"
                                    v-model="shippingAddressCity"
                                    :items="districtStore.city"
                                    item-value="city"
                                    item-title="city"
                                    :error-messages="getValidateError('shipping_address_city')"
                                    :class="getValidateError('shipping_address_city') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="6"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="District"
                                    placeholder="District"
                                    v-model="shippingAddressDistrict"
                                    :items="districtStore.district"
                                    item-value="district"
                                    item-title="district"
                                    :error-messages="getValidateError('shipping_address_district')"
                                    :class="getValidateError('shipping_address_district') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Address"
                                    placeholder="Address"
                                    v-model="shippingAddressAddress1"
                                    :error-messages="getValidateError('shipping_address_address1')"
                                    :class="getValidateError('shipping_address_address1') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <!-- <v-col 
                                cols="12"
                                md="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Apartment, suite, etc."
                                    placeholder="Apartment, suite, etc."
                                    v-model="shippingAddressAddress2"
                                    :error-messages="getValidateError('shipping_address_address2')"
                                    :class="getValidateError('shipping_address_address2') ? 'mb-3':''">
                                </v-text-field>
                            </v-col> -->
                        </v-row>

                        <!-- <v-row>
                            <v-col 
                                cols="12"
                                md="6">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Latitude"
                                    placeholder="Latitude"
                                    v-model="shippingAddressLat"
                                    :error-messages="getValidateError('shipping_address_lat')"
                                    :class="getValidateError('shipping_address_lat') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="6">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Longitude"
                                    placeholder="Longitude"
                                    v-model="shippingAddressLon"
                                    :error-messages="getValidateError('shipping_address_lon')"
                                    :class="getValidateError('shipping_address_lon') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                        </v-row> -->
                    </div>
                    
                    <div class="mt-7">
                        <div class="mb-2">
                            <h3>Billing Address</h3>
                            <v-checkbox 
                                label="The billing address is the same as the shipping address"
                                density="compact"
                                v-model="sameAddressBilling">
                            </v-checkbox>
                        </div>
                        <v-row v-if="!sameAddressBilling">
                            <v-col 
                                md="12"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Name"
                                    placeholder="Name"
                                    v-model="billingAddressName"
                                    :error-messages="getValidateError('billing_address_name')"
                                    :class="getValidateError('billing_address_name') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col
                                md="6"
                                cols="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Phone"
                                    placeholder="Phone"
                                    v-model="billingAddressPhone"
                                    :error-messages="getValidateError('billing_address_phone')"
                                    :class="getValidateError('billing_address_phone') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col
                                md="12"
                                cols="12"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="Country"
                                    placeholder="Country"
                                    v-model="billingAddressCountry"
                                    :items="['Indonesia']"
                                    :error-messages="getValidateError('billing_address_country')"
                                    :class="getValidateError('billing_address_country') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="7"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="Province"
                                    placeholder="Province"
                                    v-model="billingAddressProvince"
                                    :items="districtStore.province"
                                    item-value="province"
                                    item-title="province"
                                    :error-messages="getValidateError('billing_address_province')"
                                    :class="getValidateError('billing_address_province') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="5"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Postal code"
                                    placeholder="Postal code"
                                    v-model="billingAddressZip"
                                    :error-messages="getValidateError('billing_address_zip')"
                                    :class="getValidateError('billing_address_zip') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="6"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="City"
                                    placeholder="City"
                                    v-model="billingAddressCity"
                                    :items="districtStore.city"
                                    item-value="city"
                                    item-title="city"
                                    :error-messages="getValidateError('billing_address_city')"
                                    :class="getValidateError('billing_address_city') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="6"
                                class="py-0">
                                <v-select
                                    density="compact"
                                    variant="outlined"
                                    label="District"
                                    placeholder="District"
                                    v-model="billingAddressDistrict"
                                    :items="districtStore.district"
                                    item-value="district"
                                    item-title="district"
                                    :error-messages="getValidateError('billing_address_district')"
                                    :class="getValidateError('billing_address_district') ? 'mb-3':''">
                                </v-select>
                            </v-col>
                            <v-col 
                                cols="12"
                                md="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Address"
                                    placeholder="Address"
                                    v-model="billingAddressAddress1"
                                    :error-messages="getValidateError('billing_address_address1')"
                                    :class="getValidateError('billing_address_address1') ? 'mb-3':''">
                                </v-text-field>
                            </v-col>
                            <!-- <v-col 
                                cols="12"
                                md="12"
                                class="py-0">
                                <v-text-field 
                                    density="compact"
                                    variant="outlined"
                                    label="Apartment, suite, etc."
                                    placeholder="Apartment, suite, etc."
                                    v-model="billingAddressAddress2"
                                    :error-messages="getValidateError('billing_address_address2')"
                                    :class="getValidateError('billing_address_address2') ? 'mb-3':''">
                                </v-text-field>
                            </v-col> -->
                        </v-row>
                    </div>

                    <div class="mt-7">
                        <div>
                            <div class="mb-4">
                                <h3>Shipping method</h3>
                            </div>
                            <div>
                                <v-autocomplete 
                                    density="compact"
                                    variant="outlined"
                                    label="Select Courier"
                                    placeholder="Select Courier"
                                    v-model="courier"
                                    :items="couriers"
                                    :error-messages="getValidateError('shipping_method')">
                                    <template 
                                        v-slot:item="{ props, item }">
                                        <v-list-item
                                            :prepend-avatar="item.raw.icon"
                                            :title="item.raw.title"
                                            v-bind="props">
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>            
                            </div>

                            <div>
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    v-if="loadingShipping">
                                </v-progress-circular>
                                <v-radio-group 
                                    v-else
                                    v-model="selectCourierService">
                                    <div v-if="costs != 'error'">
                                        <template v-if="costs && costs.length > 0">
                                            <v-radio
                                                v-for="(cost, c) in costs"
                                                :key="'cost' + c" 
                                                :label="cost.service + ' (' + cost.etd + ')'" 
                                                :value="cost">
                                            </v-radio>
                                        </template>
                                    </div>
                                </v-radio-group>
                            </div>

                            <div v-if="shippingCost.insurance">
                                Shipping insurance : Rp  {{ insurance }}
                                <!-- <v-checkbox 
                                    :label="'Shipping insurance : Rp ' + insurance"
                                    density="compact"
                                    :value="shippingCost.insurance"
                                    v-model="constInsurance">
                                </v-checkbox> -->
                            </div>
                        </div>
                    </div>

                    <div 
                        class="mt-7"
                        v-if="info">
                        <div>
                            <div class="mb-4">
                                <h3>Payment method</h3>
                            </div>
                            <div>
                                <v-select 
                                    density="compact"
                                    variant="outlined"
                                    label="Select Payment"
                                    placeholder="Select Payment"
                                    v-model="paymentMethod"
                                    item-title="name"
                                    item-value="value"
                                    :items="info.payment_method"
                                    :error-messages="getValidateError('payment_method')">
                                </v-select>            
                            </div>

                            <div v-if="paymentMethod == 'bank_transfer'">
                                <div v-if="info.payment_transfer && info.payment_transfer.title">
                                    <div>
                                        <b>{{ info.payment_transfer.title }}</b>
                                    </div>
                                    <div v-html="info.payment_transfer.text">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                     <div class="mt-7">
                        <div class="mb-2">
                            <h3>Note</h3>
                        </div>
                        <v-textarea
                            density="compact"
                            variant="outlined"
                            label="Note"
                            placeholder="Note"
                            v-model="note">
                        </v-textarea>
                    </div>

                    <div class="my-6">
                        <v-btn 
                            size="large" 
                            block
                            @click="payNow"
                            :loading="loading">
                            Pay now
                        </v-btn>
                    </div>
                </v-col>
                <v-col 
                    md="6"
                    cols="12">
                    <div
                        style="
                            position: sticky;
                            top: 90px;
                        ">
                        <div 
                            v-for="cart in carts"
                            class="box-cart">
                            <div 
                                class="d-flex align-start list-cart my-3">
                                <div 
                                    class="image"
                                    style="width: 101px;">
                                    <img 
                                        :src="cart.image.image"
                                        :alt="cart.variant"
                                        v-if="cart.image">
                                    <div class="qty">
                                        {{ cart.quantity }}
                                    </div>
                                </div>
                                <div 
                                    class="title ml-3"
                                    style="width: 100%;">
                                    <div>
                                        <b>{{ cart.title }}</b>
                                    </div>
                                    <div v-if="cart.variant != 'Default Title'">
                                        {{ cart.variant }}
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <div 
                                    class="price text-right"
                                    style="width: 200px;">
                                    Rp {{ formatPrice(cart.price) }}
                                </div>
                            </div>
                        </div>

                        <div class="my-6">
                            <Discount 
                                @updateDataDiscount="dataDiscount"
                                :shippingcost="shippingCost"/>
                        </div>

                        <div>
                            <div class="d-flex">
                                <div>
                                    Subtotal
                                </div>
                                <v-spacer/>
                                <div>
                                    Rp {{ formatPrice(subtotal) }}
                                </div>
                            </div>
                            <div class="d-flex my-2">
                                <div>
                                    Shipping: {{ shippingCost.courier }} - {{ shippingCost.service }}
                                </div>
                                <v-spacer/>
                                <div v-if="shippingCost.price">
                                    Rp {{ formatPrice(shippingCost.price) }}
                                </div>
                            </div>
                            <div
                                v-if="constInsurance" 
                                class="d-flex my-2">
                                <div>
                                    Shipping insurance:
                                </div>
                                <v-spacer/>
                                <div>
                                    Rp {{ formatPrice(insurance) }}
                                </div>
                            </div>
                            <div 
                                class="d-flex my-2"
                                v-if="discount.label">
                                <div>
                                    Discount: {{ discount.label }}
                                </div>
                                <v-spacer/>
                                <div>
                                    Rp -{{ formatPrice(priceDiscount) }}
                                </div>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <b>Total</b>
                                </div>
                                <v-spacer/>
                                <div v-if="total">
                                    Rp {{ formatPrice(total) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
           
        </v-container>

        <div  
            v-if="messageSuccess">
            <div v-if="messageSuccess.payment_method == 'bank_transfer'">
                <v-dialog
                    v-model="dialogNotifPaymentBankTransfer"
                    persistent
                    width="500">
                    <v-card>
                        <v-layout>
                            <v-app-bar
                                class="mt-2"
                                density="compact">
                                <v-app-bar-title>Notification</v-app-bar-title>
                                <template v-slot:append>
                                    <v-btn 
                                        color="red"
                                        icon="mdi-close"
                                        @click="closeNotifPayment">
                                    </v-btn>
                                </template>
                            </v-app-bar>

                            <v-main 
                                class="px-4 pb-2 mt-4">
                                You have chosen to pay using bank transfer. Please complete payment:
                                <div class="my-2">
                                    <div>
                                        <b>{{ info.payment_transfer.title }}</b>
                                    </div>
                                    <div v-html="info.payment_transfer.text">

                                    </div>
                                </div>
                            </v-main>
                        </v-layout>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <v-dialog
            v-model="dialogNotifPaymentMistrands"
            persistent
            width="500">
            <v-card>
                <v-layout>
                    <v-app-bar
                        class="mt-2"
                        density="compact">
                        <v-app-bar-title>Notification</v-app-bar-title>
                        <template v-slot:append>
                            <v-btn 
                                color="red"
                                icon="mdi-close"
                                @click="closeNotifPayment">
                            </v-btn>
                        </template>
                    </v-app-bar>

                    <v-main 
                        class="px-4 pb-2 mt-4"
                        v-if="messageMidrands.message">
                        <div v-if="messageMidrands.type == 'success'">
                            <h5>Your payment was successfully received</h5>
                            <p>Success, transaction is successful</p>
                        </div>
                        <div v-if="messageMidrands.type == 'error'">
                            <h5>Sorry! Your payment has failed!</h5>
                            <p>{{ messageMidrands.message.status_message }}</p>
                        </div>
                        
                        <div v-if="messageMidrands.type == 'pending'">
                            <div v-if="messageMidrands.message.payment_type == 'bank_transfer'">
                                <h5>Order is pending for payment</h5>
                                <p>We have received your order but it has not been paid <br>Please check your email for payment information</p>
                            </div>
                            <div v-if="messageMidrands.message.payment_type != 'bank_transfer'">
                                <h5>Order is pending for payment</h5>
                                <p>{{ messageMidrands.message.status_message }}</p>
                            </div>
                        </div>
                    </v-main>
                </v-layout>
            </v-card>
        </v-dialog>

        {{ getUser() }}
    </div>
    
</template>

<script setup>
    import { ref, onMounted, watch } from 'vue'
    import api from '@/api'
    import Discount from './Discount'
    import { useDistrictStore } from '@/store/district'
    import { storeToRefs } from 'pinia'
    import { useUserStore } from '@/store/user'
    import { useCartStore } from '@/store/cart'

    const districtStore = useDistrictStore()
    districtStore.getProvince('')

    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)

    const useCart = useCartStore()
    const { carts: dataCart } = storeToRefs(useCart)

    const carts = ref([])
    const discount = ref({})
    const shippingCost = ref({})
    const priceDiscount = ref(0)
    const subtotal = ref(0)
    const total = ref(0)

    const name = ref('')
    const email = ref('')
    const phone = ref('')
    const note = ref('')

    const insurance = ref()
    const constInsurance = ref()

    const info = ref('')

    const paymentMethod = ref('midtrans')

    const sameAddressBilling = ref(true)

    const loading = ref(false)

    const msgValidate = ref('')
    const msgError = ref([])
    const messageErr = ref('')

    const shippingAddressName = ref('')
    const shippingAddressCountry = ref('')
    const shippingAddressAddress1 = ref('')
    const shippingAddressAddress2 = ref('')
    const shippingAddressDistrict = ref('')
    const shippingAddressCity = ref('')
    const shippingAddressProvince = ref('')
    const shippingAddressZip = ref('')
    const shippingAddressPhone = ref('')
    const shippingAddressLat = ref('')
    const shippingAddressLon = ref('')

    const billingAddressName = ref('')
    const billingAddressCountry = ref('')
    const billingAddressAddress1 = ref('')
    const billingAddressAddress2 = ref('')
    const billingAddressDistrict = ref('')
    const billingAddressCity = ref('')
    const billingAddressProvince = ref('')
    const billingAddressZip = ref('')
    const billingAddressPhone = ref('')

    const dialogNotifPaymentMistrands = ref(false)
    const messageMidrands = ref('')
    
    const dialogNotifPaymentBankTransfer = ref(false)

    const couriers = ref([])
    const courier = ref('')
    const selectCourierService = ref('')
    const costs = ref([])
    const loadingShipping = ref(false)

    const messageSuccess = ref('')

    function getUser(){
        name.value = user.value.name
        email.value = user.value.email
        phone.value = user.value.phone

        if(user.value.addresses && user.value.addresses.length > 0){
            const userAddressShipping = user.value.addresses[0]

            shippingAddressName.value = userAddressShipping.name
            shippingAddressCountry.value = userAddressShipping.country
            shippingAddressAddress1.value = userAddressShipping.address1
            shippingAddressAddress2.value = userAddressShipping.address2
            shippingAddressDistrict.value = userAddressShipping.district
            shippingAddressCity.value = userAddressShipping.city
            shippingAddressProvince.value = userAddressShipping.province
            shippingAddressZip.value = userAddressShipping.zip
            shippingAddressPhone.value = userAddressShipping.phone
            shippingAddressLat.value = userAddressShipping.lat
            shippingAddressLon.value = userAddressShipping.lon
        }
    }

    watch(() => shippingAddressProvince.value, (newVal) => {
        districtStore.getCity(newVal)
    })
    watch(() => shippingAddressCity.value, (newVal) => {
        districtStore.getDistrict(newVal)
    })
    watch(() => billingAddressProvince.value, (newVal) => {
        districtStore.getCity(newVal)
    })
    watch(() => billingAddressCity.value, (newVal) => {
        districtStore.getDistrict(newVal)
    })

    watch(dataCart, (val) => {
        getCart()
    })

    // watch(constInsurance, (val) => {
    //     getTotal()
    // })

    getCart()
    async function getCart(){
        await api.get('carts')
        .then((resp) => {
            if(resp.data.data.length == 0){
                window.location = '/'
            }

            carts.value = resp.data.data

            subtotal.value = resp.data.data.reduce((total, product) => {
                                return total + parseInt(product.sub_price)
                            }, 0)

            total.value = resp.data.data.reduce((total, product) => {
                                return total + parseInt(product.sub_price)
                            }, 0)

            getTotal()
        })
        .catch((err) => {
            // console.log(err.response)
        })

    }

    function getTotal(){
        let dataTotal = subtotal.value
        
        if(shippingCost.value.price){
            dataTotal = subtotal.value + shippingCost.value.price
        }
        
        if(constInsurance.value){
            let insur = parseFloat(constInsurance.value) * subtotal.value

            dataTotal = subtotal.value + Math.round(insur) + shippingCost.value.price
            insurance.value = Math.round(insur)
        }
        
        if(discount.value.label){
            priceDiscount.value = discount.value.price
            dataTotal -= discount.value.price
        }

        return total.value = dataTotal
    }

    function dataDiscount(data) {
        discount.value = data
        
        getCart()

        getTotal()
    }

    getCourier()
    async function getCourier(){
        await api.get('shipping/couriers')
        .then((resp) => {
            couriers.value = resp.data.data
        }) 
        .catch((err) => {
            // console.log(err.response)
        })
    }
    
    getInfo()
    async function getInfo(){
        await api.get('checkout/info')
        .then((resp) => {
            info.value = resp.data
        }) 
        .catch((err) => {
            // console.log(err.response)
        })
    }

    watch(courier, (val) => {
        if(val){
            checkCost(val)
        }
    })

    watch(selectCourierService, (val) => {
        insurance.value = 0
        constInsurance.value = false
        if(val){
            shippingCost.value = val

            if(val.insurance){
                let insur = parseFloat(val.insurance) * subtotal.value
                insurance.value = Math.round(insur)
                constInsurance.value = val.insurance
            }

            getTotal()
        }
    })
    
    async function checkCost(courier){
        costs.value = []
        shippingCost.value = ''
        selectCourierService.value = ''
        insurance.value = 0
        constInsurance.value = false
        msgValidate.value = ''
        messageErr.value = ''
        msgError.value = []
        loadingShipping.value = true
        await api.post('shipping/cost', {
            country: shippingAddressCountry.value,
            address1: shippingAddressAddress1.value,
            address2: shippingAddressAddress2.value,
            district: shippingAddressDistrict.value,
            city: shippingAddressCity.value,
            province: shippingAddressProvince.value,
            zip: shippingAddressZip.value,
            lat: shippingAddressLat.value,
            lon: shippingAddressLon.value,
            courier: courier
        })
        .then((resp) => {
            costs.value = resp.data.data

            loadingShipping.value = false
        })
        .catch((err) => {
            loadingShipping.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 423){
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                msgError.value = err.response.data.errors
            } else {
                messageErr.value = 'Internal server error'
            }
            // console.log(err.response)
        })

    }

    async function payNow(){
        msgValidate.value = ''
        messageErr.value = ''
        msgError.value = []
        loading.value = true

        let BillingAddressName = shippingAddressName.value 
        let BillingAddressCountry = shippingAddressCountry.value
        let BillingAddressAddress1 = shippingAddressAddress1.value
        let BillingAddressAddress2 = shippingAddressAddress2.value
        let BillingAddressDistrict = shippingAddressDistrict.value
        let BillingAddressCity = shippingAddressCity.value
        let BillingAddressProvince = shippingAddressProvince.value
        let BillingAddressZip = shippingAddressZip.value
        let BillingAddressPhone = shippingAddressPhone.value
        if(sameAddressBilling.value){
            BillingAddressName = shippingAddressName.value 
            BillingAddressCountry = shippingAddressCountry.value
            BillingAddressAddress1 = shippingAddressAddress1.value
            BillingAddressAddress2 = shippingAddressAddress2.value
            BillingAddressDistrict = shippingAddressDistrict.value
            BillingAddressCity = shippingAddressCity.value
            BillingAddressProvince = shippingAddressProvince.value
            BillingAddressZip = shippingAddressZip.value
            BillingAddressPhone = shippingAddressPhone.value
        } else {
            BillingAddressName = billingAddressName.value 
            BillingAddressCountry = billingAddressCountry.value
            BillingAddressAddress1 = billingAddressAddress1.value
            BillingAddressAddress2 = billingAddressAddress2.value
            BillingAddressDistrict = billingAddressDistrict.value
            BillingAddressCity = billingAddressCity.value
            BillingAddressProvince = billingAddressProvince.value
            BillingAddressZip = billingAddressZip.value
            BillingAddressPhone = billingAddressPhone.value
        }

        let url = 'checkout'
        if(user.value){
            url = 'checkout/l'
        }

        await api.post(url, {
            name: name.value,
            email: email.value,
            phone: phone.value,
            
            shipping_address_name: shippingAddressName.value,
            shipping_address_country: shippingAddressCountry.value,
            shipping_address_address1: shippingAddressAddress1.value,
            shipping_address_address2: shippingAddressAddress2.value,
            shipping_address_district: shippingAddressDistrict.value,
            shipping_address_city: shippingAddressCity.value,
            shipping_address_province: shippingAddressProvince.value,
            shipping_address_zip: shippingAddressZip.value,
            shipping_address_phone: shippingAddressPhone.value,
            shipping_address_lat: shippingAddressLat.value,
            shipping_address_lon: shippingAddressLon.value,

            billing_address_name: BillingAddressName,
            billing_address_country: BillingAddressCountry,
            billing_address_address1: BillingAddressAddress1,
            billing_address_address2: BillingAddressAddress2,
            billing_address_district: BillingAddressDistrict,
            billing_address_city: BillingAddressCity,
            billing_address_province: BillingAddressProvince,
            billing_address_zip: BillingAddressZip,
            billing_address_phone: BillingAddressPhone,

            
            coupon: discount.value,
            
            note: note.value,
            
            courier: shippingCost.value,
            shipping_cost: shippingCost.value.price,
            shipping_insurance: constInsurance.value ? insurance.value : 0,
            shipping_method: shippingCost.value.courier,

            payment_method: paymentMethod.value,
        })
        .then((resp) => {
            loading.value = false
            // console.log(resp.data)

            messageSuccess.value = resp.data

            if(resp.data.payment_method == 'midtrans'){
                payMidtrans(resp.data.token)
            }
            if(resp.data.payment_method == 'bank_transfer'){
                dialogNotifPaymentBankTransfer.value = true
            }
            
        })
        .catch((err) => {
            loading.value = false

            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else if(err.response.status == 423){
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                msgError.value = err.response.data.errors
            } else {
                messageErr.value = 'Internal server error'
            }
            // console .log(err.response)
        })
    }

    async function sendNotif(data){
        await api.post('checkout/transaction-status', data)
        .then((resp) => {
            //
        })
        .catch((resp) => {
            //
        })
    }

    onMounted(() => {
        const script = document.createElement('script');
        script.src = window.snapurl;
        script.setAttribute('data-client-key', window.snapkey);
        document.body.appendChild(script);
    });

    function payMidtrans(snapToken) {
        // Ensure the snap object is available
        if (window.snap) {
            window.snap.pay(snapToken, {
                // Optional
                onSuccess(result){
                    messageMidrands.value = {
                        type: 'success',
                        message: result
                    }
                    dialogNotifPaymentMistrands.value = true
                    sendNotif(result)
                },
                // Optional
                onPending(result){
                    messageMidrands.value = {
                        type: 'pending',
                        message: result
                    }
                    dialogNotifPaymentMistrands.value = true
                    sendNotif(result)
                },
                // Optional
                onError(result){
                    messageMidrands.value = {
                        type: 'error',
                        message: result
                    }
                    dialogNotifPaymentMistrands.value = true
                    // sendNotif(result)
                    sendNotif({
                        status: 'error',
                        token: snapToken
                    })
                },
                onClose(result){
                    sendNotif({
                        status: 'close',
                        token: snapToken
                    })

                    window.location = '/';
                }
            });
        } else {
            console.error('Snap.js is not loaded yet.');
        }
    }

    function closeNotifPayment(){
        //redirect home
        dialogNotifPaymentMistrands.value = false
        dialogNotifPaymentBankTransfer.value = false
        window.location = '/';
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

    function formatPrice(value) {
        const val = (value/1);
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

</script>

<style scoped>
    .box-cart{

    }
    .box-cart .image{
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 5px;
        border: 1px solid #eee;
    }
    .box-cart .image .qty{
        position: absolute;
        width: 22px;
        height: 22px;
        background: #666;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        top: -7px;
        right: -7px;
    }
    .box-cart .image img{
        width: 64px;
        height: 64px;
        border-radius: 5px;
        object-fit: cover;
        object-position: center;
    }
</style>