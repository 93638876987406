<template>
    <div>
        <div v-if="post">
            <section class="mb-4">
                <v-container>
                    <div class="d-flex w-100 align-center">
                        <v-breadcrumbs 
                            class="px-0"
                            :items=" [
                            {
                                title: 'Home',
                                disabled: false,
                                href: '/',
                            },
                            {
                                title: 'News',
                                disabled: false,
                                href: '/news',
                            },
                            {
                                title: post.title,
                                disabled: true,
                            },
                        ]">
                        <template v-slot:divider>
                            <v-icon icon="mdi-chevron-right"></v-icon>
                        </template>
                    </v-breadcrumbs>
                        <v-spacer/>
                        <div 
                            class="d-flex align-bottom cursor-pointer"
                            @click="share = true">
                            <v-icon 
                                size="small"
                                class="mr-1">
                                mdi-export-variant
                            </v-icon>
                            Share
                        </div>
                    </div>

                    <v-row class="justify-center">
                        <v-col 
                            cols="12"
                            md="8">
                            <div>
                                <h1 class="z-title mb-3 type-font-body">
                                    {{ post.title }}
                                </h1>
                                {{ calculateTimeToRead(post.description) }} min read . {{ formatDate(post.created_at) }}
                            </div>
                            
                            <div class="my-4">
                                <div v-if="post.gallery && post.gallery.length > 0">
                                    <v-img 
                                        v-if="post.gallery[0].type == 'image'"
                                        :src="urlImage + post.gallery[0].file"
                                        :alt="post.title">
                                    </v-img>
                                    <iframe 
                                        v-if="post.gallery[0].type == 'youtube'"
                                        :src="'https://www.youtube.com/embed/' + post.gallery[0].youtube_id + '?controls=0'"
                                        frameborder="0" 
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                                        allowfullscreen
                                        class="box-active"
                                        width="100%"
                                        height="500">
                                    </iframe>
                                </div>
                            </div>

                            <div v-html="post.description"></div>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <zBaseComponent :data="post.sections"/>

            <v-dialog 
                width="500"
                v-model="share">
                <v-card>
                    <v-card-title>
                        Share
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col 
                                cols="6" 
                                md="6"
                                v-for="(sr, s) in post.share"
                                :key="'share' + s">
                                <v-btn 
                                    variant="outlined"
                                    @click="socialWindow(sr)"
                                    block>
                                    {{ s }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            text="Close"
                            @click="share = false">
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <v-container
            v-if="loading"
            class="text-center">
            <v-progress-circular
                color="primary"
                indeterminate>
            </v-progress-circular>
            <!-- <v-row 
                class="my-10">
                <v-col 
                    md="4"
                    cols="6"
                    v-for="(item, p) in 9"
                    :key="'skeleton' + p">
                    <v-skeleton-loader
                        class="mx-auto border"
                        type="image, article">
                    </v-skeleton-loader>
                </v-col>
            </v-row> -->
        </v-container>
    </div>
</template>

<script setup>
    import dayjs from 'dayjs'
    import relativeTime from 'dayjs/plugin/relativeTime'
    import { ref, onMounted } from 'vue'
    import api from '../api'
    import { useRoute, useRouter } from 'vue-router'
    import zBaseComponent from '../components/zBaseComponent'

    dayjs.extend(relativeTime)

    const share = ref(false)

    const post = ref({})

    const route = useRoute()
    const router = useRouter()
    const slug = route.params.slug
    const loading = ref(false)

    const urlImage = window.urlImage

    getPost()
    async function getPost(){
        try {
            loading.value = true
            const resPost = await api.get('posts/' + slug + '/show')
            post.value = resPost.data.data

            document.title = resPost.data.data.title
            const metaTagTitle = document.querySelector('meta[name="title"]');
            if (metaTagTitle) {
                metaTagTitle.setAttribute('content', resPost.data.data.title);
            }
            const metaTagDesc = document.querySelector('meta[name="description"]');
            if (metaTagDesc) {
                if(resPost.data.data.description){
                    metaTagDesc.setAttribute('content', resPost.data.data.description.substring(0,150));
                }
            }
            loading.value = false
        } catch (err) {
            if(err.response.status == 404){
                router.push({ path: '/404' })
            }
            loading.value = false
        }
    }

    function formatDate(date){
        const timeAgo = dayjs(date).fromNow();
        return timeAgo;
    }

    function calculateTimeToRead(text) {
        if(text){
            const wordCount = text.split(/\s+/).length
            const readingTime = Math.ceil(wordCount / 200)

            return readingTime;
        }
        
    }

    onMounted(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })

    function socialWindow(url) {
        var left = (screen.width - 570) / 2;
        var top = (screen.height - 450) / 2;
        var params = 'menubar=no,toolbar=no,status=no,width=' + 570 + ',height=' + 450 + ',top=' + top + ',left=' + left;
        window.open(url ,'', params);
    }

</script>