import Cookies from 'js-cookie'

// Set a secure cookie with the token
export function setToken(token) {
    Cookies.set('auth', token, {
        expires: 1,             // Token expires in 1 days
        secure: true,           // Ensures the cookie is sent only over HTTPS
        sameSite: 'Strict',     // Restricts sending the cookie in cross-site requests
        path: '/',              // Cookie is available throughout the entire site
        // httpOnly: true        // Use this flag if you're setting the cookie server-side, as it's not available on the client-side
    });
}

// Get the token from cookies
export function getToken() {
    return Cookies.get('auth')
}

// Remove the token from cookies
export function removeToken() {
    Cookies.remove('auth', { path: '/' })
}
