<template>
    <div class="bg-grey-lighten-4 py-15">
        <v-container>
            <v-row class="justify-center">
                <v-col
                    md="5"
                    cols="12">
                    <v-card 
                        flat
                        rounded="0">
                        <div class="pa-10">
                            <h5 
                                class="z-subtitle text-primary">
                                Login
                            </h5>

                            <div class="mt-7">
                                <v-text-field
                                    v-model="email"
                                    prepend-inner-icon="mdi-email"
                                    label="Email"
                                    variant="outlined"
                                    :error-messages="getValidateError('email')"
                                    :class="getValidateError('email') ? 'mb-3':''">
                                </v-text-field>
                                <v-text-field
                                    v-model="password"
                                    prepend-inner-icon="mdi-lock"
                                    label="Password"
                                    variant="outlined"
                                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                    :type="visible ? 'text' : 'password'"
                                    @click:append-inner="visible = !visible"
                                    :error-messages="getValidateError('password')"
                                    :class="getValidateError('password') ? 'mb-3':''">
                                </v-text-field>

                                <div 
                                    v-if="msgError"
                                    class="text-red mb-4">
                                    {{ msgError }}
                                </div>
                                <div 
                                    v-if="msgErrorNotVerified"
                                    class="text-red mb-4">
                                    {{ msgErrorNotVerified }}.
                                    <a 
                                        @click="sendVerify"
                                        style="text-decoration: underline;cursor: pointer;"> 
                                        Click to verify your email
                                    </a>
                                </div>

                                <div 
                                    v-if="msgSuccess"
                                    class="text-green mb-4">
                                    {{ msgSuccess }}
                                </div>

                                <v-row>
                                    <v-col
                                        md="6"
                                        cols="6"
                                        class="text-left">
                                        <router-link 
                                            to="/forgot-password"
                                            class="text-primary">
                                            Forgot password?
                                        </router-link>
                                    </v-col>
                                </v-row>

                                <v-btn 
                                    class="mt-5"
                                    size="x-large"
                                    block
                                    @click="login"
                                    :loading="loading">
                                    Login
                                </v-btn>

                                <Google class="mt-5" />

                                <div class="text-center mt-7">
                                    New here? 
                                    <router-link 
                                        to="/register"
                                        class="text-primary">
                                        Create an account
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
    
</template>

<script setup>
    import { ref } from 'vue'
    import api from '@/api'
    import Google from './Google'
    import { setToken } from '@/auth'

    document.title = "Login"

    const visible = ref(false)

    const email = ref('')
    const password = ref('')
    
    const loading = ref(false)

    const msgValidate = ref('')
    const msgError = ref('')
    const msgSuccess = ref('')
    const msgErrorNotVerified = ref('')

    async function login(){
        msgValidate.value = ''
        msgError.value = ''
        msgErrorNotVerified.value = ''
        msgSuccess.value = ''
        loading.value = true
        
        await api.post('login', {
            email: email.value,
            password: password.value
        })
        .then((resp) => {
            loading.value = false

            setToken(true)

            if(resp.data.status == 'not_verified'){
               msgErrorNotVerified.value = 'Email not verified'
            }
            if(resp.data.status == 'ok'){
               window.location = '/'
            }
        })
        .catch((err) => {
            loading.value = false
            if(err.response.status == 422){
                msgValidate.value = err.response.data.errors
            } else {
                msgError.value = err.response.data.message
            }
        })
    }

    async function sendVerify(){
        loading.value = true
        msgErrorNotVerified.value = ''
        msgSuccess.value = ''
        await api.get('send-verify')
            .then((resp) => {
                loading.value = false
                msgSuccess.value = resp.data.message
            })
            .catch((err) => {
                loading.value = false
                if(err.response.status == 429){
                    msgError.value = 'Please try again in 30 minutes'
                } else {
                    msgError.value = 'Internal server error'
                }
            })
    }

    function getValidateError(field) {
        if (msgValidate.value && msgValidate.value[field] && msgValidate.value[field][0]) {
            return msgValidate.value[field][0];
        } else {
            return '';
        }
    }

</script>