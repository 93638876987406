<template>
    <div>
        <div
            @click=show(data.id) 
            class="cursor-pointer tw-block">
            <v-img
                v-if="data.type == 'image'" 
                :src="data.file">
            </v-img>
            <video
                v-if="data.type == 'video'"
                controls>
                <source 
                    :src="data.file" 
                    type="video/mp4">
            </video>
        </div>

        <v-dialog
            v-model="dialog"
            persistent
            width="700">
            <v-card  v-if="!loading">
                <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-h-[510px]">
                    <div 
                        class=""
                        v-if="review">
                        <v-img
                            v-if="review.image" 
                            :src="review.image"
                            height="100%">
                        </v-img>
                        <video
                            v-if="review.video"
                            controls>
                            <source 
                                :src="review.video" 
                                type="video/mp4">
                        </video>
                    </div>
                    <div class="tw-py-4 tw-px-5">
                        <div class="tw-h-full">
                            <div class="d-flex mb-2">
                                <v-spacer/>
                                <div>
                                    <v-icon @click="closeDialog">mdi-close</v-icon>
                                </div>
                            </div>

                            <div class="tw-h-[445px]">
                                <v-rating
                                    readonly
                                    :model-value="review.rating"
                                    color="yellow-darken-1"
                                    half-increments
                                    :size="26"
                                    class="btn-rating">
                                </v-rating>

                                <div class="tw-font-body tw-text-xl tw-my-2">
                                    <b>{{ review.title }}</b>
                                </div>

                                <div class="tw-flex">
                                    <div>
                                        by <b>{{ review.user }}</b>
                                    </div>
                                    <v-spacer/>
                                    <div class="tw-text-sm">
                                        {{ format(new Date(review.created_at), 'dd/MM/yy') }}
                                    </div>
                                </div>

                                <div
                                    class="tw-font-body tw-mt-2 tw-mb-4" 
                                    style="white-space: pre-line;">
                                    {{ review.message }}
                                </div>

                                <div class="tw-font-body tw-my-2">
                                    <template v-if="review.recommend">
                                        <v-icon>mdi-check-circle</v-icon> I recommended this product
                                    </template>
                                    <template v-else>
                                        <v-icon>mdi-check-circle</v-icon> I do NOT recommended this product
                                    </template>
                                </div>

                                <div>
                                    <div class="d-flex mb-4">
                                        <div class="mr-4">Helpful?</div>
                                        <div class="mr-4">
                                            <v-icon 
                                                size="small"
                                                @click="helpfulUp">
                                                mdi-thumb-up-outline
                                            </v-icon> 
                                            {{ review.helpful_up }}
                                        </div>
                                        <div>
                                            <v-icon 
                                                size="small"
                                                @click="helpfulDown">
                                                mdi-thumb-down-outline
                                            </v-icon>
                                            {{ review.helpful_down }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
    import { ref, watch } from 'vue'
    import api from '@/api'
    import { format } from 'date-fns'

    const review = ref()
    const loading = ref(false)
    const dialog = ref(false)

    const { data } = defineProps(['data'])

    async function show(id){
        loading.value = true
        await api.get('reviews/show/' + id)
            .then((resp) => {
                review.value = resp.data.data
                loading.value = false
                dialog.value = true
            })
            .catch((err) => {
                loading.value = false
            })
    }

    function closeDialog(){
        dialog.value = false
    }

    async function helpfulUp(){
        await api.post('reviews/helpful-up', {
            'id': data.id,
            'helpful_up': 1
        })
        .then((resp) => {
            data.helpful_up = resp.data.data.count
            emit('reload')
        })
        .catch((err) => {

        })
    }

    async function helpfulDown(){
        await api.post('reviews/helpful-down', {
            'id': data.id,
            'helpful_down': 1
        })
        .then((resp) => {
            data.helpful_down = resp.data.data.count
            emit('reload')
        })
        .catch((err) => {

        })
    }
</script>